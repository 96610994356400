/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ActionMetadata } from '../types';
// @ts-ignore
import type { AnnotationAggregate } from '../types';
// @ts-ignore
import type { AnnotationAggregationTypeEnum } from '../types';
// @ts-ignore
import type { AnnotationData } from '../types';
// @ts-ignore
import type { ApprovalStatusChange } from '../types';
// @ts-ignore
import type { AttachTagRequestInner } from '../types';
// @ts-ignore
import type { AttributeValuesResponse } from '../types';
// @ts-ignore
import type { AuditEventsResponse } from '../types';
// @ts-ignore
import type { BrowserAgentAuthRequest } from '../types';
// @ts-ignore
import type { BrowserAgentAuthResponse } from '../types';
// @ts-ignore
import type { BrowserAgentBuildListResponseInner } from '../types';
// @ts-ignore
import type { BrowserAgentIngestResponse } from '../types';
// @ts-ignore
import type { BrowserAgentResponsePage } from '../types';
// @ts-ignore
import type { ContentData } from '../types';
// @ts-ignore
import type { ContentFacetTypeEnum } from '../types';
// @ts-ignore
import type { ContentTypeEnum } from '../types';
// @ts-ignore
import type { CreateCollector201Response } from '../types';
// @ts-ignore
import type { CreateCollectorK8sClusterRequest } from '../types';
// @ts-ignore
import type { CreateCollectorRequest } from '../types';
// @ts-ignore
import type { CreateNoteRequest } from '../types';
// @ts-ignore
import type { CreateTenantRequest } from '../types';
// @ts-ignore
import type { CsvExportRequest } from '../types';
// @ts-ignore
import type { CustomerSecretInput } from '../types';
// @ts-ignore
import type { DatasourceConfig } from '../types';
// @ts-ignore
import type { DatasourcesPost201Response } from '../types';
// @ts-ignore
import type { DeleteUserRequestRequest } from '../types';
// @ts-ignore
import type { DetachTag } from '../types';
// @ts-ignore
import type { EmailConfig } from '../types';
// @ts-ignore
import type { EnableBrowserAgentRequest } from '../types';
// @ts-ignore
import type { EnableBrowserAgentResponse } from '../types';
// @ts-ignore
import type { EntityActivityInfo } from '../types';
// @ts-ignore
import type { EntityAppliedPolicy } from '../types';
// @ts-ignore
import type { EntityData } from '../types';
// @ts-ignore
import type { EntityHeaderInfo } from '../types';
// @ts-ignore
import type { EntityMappingData } from '../types';
// @ts-ignore
import type { EntityRef } from '../types';
// @ts-ignore
import type { EntityReference } from '../types';
// @ts-ignore
import type { EntityRefs } from '../types';
// @ts-ignore
import type { EntityTrendRequest } from '../types';
// @ts-ignore
import type { EntityTypeSchema } from '../types';
// @ts-ignore
import type { EntityUserRequest } from '../types';
// @ts-ignore
import type { EnumTypeSchema } from '../types';
// @ts-ignore
import type { EventFacetTypeEnum } from '../types';
// @ts-ignore
import type { EventListResponse } from '../types';
// @ts-ignore
import type { EventReferenceFacets } from '../types';
// @ts-ignore
import type { EventSeverity } from '../types';
// @ts-ignore
import type { EventStatus } from '../types';
// @ts-ignore
import type { EventType } from '../types';
// @ts-ignore
import type { FacetData } from '../types';
// @ts-ignore
import type { FilterAndGroupRateCard } from '../types';
// @ts-ignore
import type { FilterAndGroupRateCardRequest } from '../types';
// @ts-ignore
import type { GetAiServiceMetaInfoResponse } from '../types';
// @ts-ignore
import type { GetAllComputeInstanceRateRecords200Response } from '../types';
// @ts-ignore
import type { GetAllCsvUploads200ResponseInner } from '../types';
// @ts-ignore
import type { GetAllFeatureFlags200Response } from '../types';
// @ts-ignore
import type { GetAllManagedLLMInferenceRecords200Response } from '../types';
// @ts-ignore
import type { GetAllRawEvents200Response } from '../types';
// @ts-ignore
import type { GetAllVectorDBRecords200Response } from '../types';
// @ts-ignore
import type { GetAnnotationList200Response } from '../types';
// @ts-ignore
import type { GetCollectors200ResponseInner } from '../types';
// @ts-ignore
import type { GetConfigResponse } from '../types';
// @ts-ignore
import type { GetContentFacets200Response } from '../types';
// @ts-ignore
import type { GetContentList200Response } from '../types';
// @ts-ignore
import type { GetDataSources200ResponseInner } from '../types';
// @ts-ignore
import type { GetEntityDataParam } from '../types';
// @ts-ignore
import type { GetFacetDataParam } from '../types';
// @ts-ignore
import type { GetMachineEventsList200Response } from '../types';
// @ts-ignore
import type { GetMachineEventsListTimeSeries200Response } from '../types';
// @ts-ignore
import type { GetRegions200ResponseInner } from '../types';
// @ts-ignore
import type { GetSupportedDatasources200ResponseInner } from '../types';
// @ts-ignore
import type { GetUnverifiedAiService400Response } from '../types';
// @ts-ignore
import type { GraphEntityTypeEntityIdGraphTypeGet400Response } from '../types';
// @ts-ignore
import type { GraphResponse } from '../types';
// @ts-ignore
import type { GroupUsageCostData } from '../types';
// @ts-ignore
import type { GroupUsageCostRequest } from '../types';
// @ts-ignore
import type { HTTPValidationError } from '../types';
// @ts-ignore
import type { InstanceConfig } from '../types';
// @ts-ignore
import type { JiraConfig } from '../types';
// @ts-ignore
import type { JiraDetails } from '../types';
// @ts-ignore
import type { JiraRequest } from '../types';
// @ts-ignore
import type { LLMLineageRequest } from '../types';
// @ts-ignore
import type { LLMLineageResponse } from '../types';
// @ts-ignore
import type { LibraryCveList } from '../types';
// @ts-ignore
import type { LifecycleEventsResponse } from '../types';
// @ts-ignore
import type { MachineEventRequest } from '../types';
// @ts-ignore
import type { MachineEventTimeSeriesRequest } from '../types';
// @ts-ignore
import type { MessagesKeyGet200Response } from '../types';
// @ts-ignore
import type { MessagesKeyGet404Response } from '../types';
// @ts-ignore
import type { MetricSchema } from '../types';
// @ts-ignore
import type { MetricSeriesQueryRequest } from '../types';
// @ts-ignore
import type { MetricSeriesResponse } from '../types';
// @ts-ignore
import type { MetricTagListInner } from '../types';
// @ts-ignore
import type { MetricTagListRequest } from '../types';
// @ts-ignore
import type { NavigationRequests } from '../types';
// @ts-ignore
import type { NavigationResponses } from '../types';
// @ts-ignore
import type { NewTag } from '../types';
// @ts-ignore
import type { Note } from '../types';
// @ts-ignore
import type { PiiConfig } from '../types';
// @ts-ignore
import type { PolicyContextData } from '../types';
// @ts-ignore
import type { PolicyData } from '../types';
// @ts-ignore
import type { PolicyPreviewResponse } from '../types';
// @ts-ignore
import type { PolicySummaryRequest } from '../types';
// @ts-ignore
import type { PolicySummaryResponse } from '../types';
// @ts-ignore
import type { PolicyTypePropertyList } from '../types';
// @ts-ignore
import type { Preference } from '../types';
// @ts-ignore
import type { PropertyValues } from '../types';
// @ts-ignore
import type { RecommendationData } from '../types';
// @ts-ignore
import type { RegisterUserRequest } from '../types';
// @ts-ignore
import type { RegisterUserResponse } from '../types';
// @ts-ignore
import type { RiskCategoryList } from '../types';
// @ts-ignore
import type { Role } from '../types';
// @ts-ignore
import type { RuntimeConfigResponse } from '../types';
// @ts-ignore
import type { SavingsData } from '../types';
// @ts-ignore
import type { SensitiveDataConfig } from '../types';
// @ts-ignore
import type { SensitiveDataConfigCreate } from '../types';
// @ts-ignore
import type { SensitiveDataConfigUpdate } from '../types';
// @ts-ignore
import type { SensitiveDataSettings } from '../types';
// @ts-ignore
import type { SettingsConfigObject } from '../types';
// @ts-ignore
import type { SettingsObject } from '../types';
// @ts-ignore
import type { StateTransition } from '../types';
// @ts-ignore
import type { SupportedPreferenceItem } from '../types';
// @ts-ignore
import type { TagList } from '../types';
// @ts-ignore
import type { Tenant } from '../types';
// @ts-ignore
import type { TenantGeneralSettings } from '../types';
// @ts-ignore
import type { TenantGeneralSettingsUpdate } from '../types';
// @ts-ignore
import type { TopicPolicyStatusResponse } from '../types';
// @ts-ignore
import type { Topics } from '../types';
// @ts-ignore
import type { UpdateConfigRequest } from '../types';
// @ts-ignore
import type { UpdateNoteRequest } from '../types';
// @ts-ignore
import type { UploadCsvDataSource200Response } from '../types';
// @ts-ignore
import type { UsageCostData } from '../types';
// @ts-ignore
import type { User } from '../types';
// @ts-ignore
import type { UserRequestPolicyRequest } from '../types';
// @ts-ignore
import type { UserUpdateRequest } from '../types';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Browser Agent Health Check
         * @summary Health Check
         * @param {string} authorization 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentHealthCheck: async (authorization: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('agentHealthCheck', 'authorization', authorization)
            const localVarPath = `/browser-agent-mgmt/health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attach a tag to a resource
         * @param {Array<AttachTagRequestInner>} attachTagRequestInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTag: async (attachTagRequestInner: Array<AttachTagRequestInner>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachTagRequestInner' is not null or undefined
            assertParamExists('attachTag', 'attachTagRequestInner', attachTagRequestInner)
            const localVarPath = `/tags/attach`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachTagRequestInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authenticate browser agent
         * @summary Authenticate browser agent
         * @param {BrowserAgentAuthRequest} [browserAgentAuthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browserAgentAuth: async (browserAgentAuthRequest?: BrowserAgentAuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/browser-agent-mgmt/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(browserAgentAuthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get LLM Lineage
         * @param {LLMLineageRequest} lLMLineageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsLlmLineagePost: async (lLMLineageRequest: LLMLineageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lLMLineageRequest' is not null or undefined
            assertParamExists('componentsLlmLineagePost', 'lLMLineageRequest', lLMLineageRequest)
            const localVarPath = `/components/llm/lineage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lLMLineageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new collector
         * @param {CreateCollectorRequest} createCollectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollector: async (createCollectorRequest: CreateCollectorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCollectorRequest' is not null or undefined
            assertParamExists('createCollector', 'createCollectorRequest', createCollectorRequest)
            const localVarPath = `/collector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCollectorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate collector deployment file for kubernetes
         * @param {CreateCollectorK8sClusterRequest} createCollectorK8sClusterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollectorK8sCluster: async (createCollectorK8sClusterRequest: CreateCollectorK8sClusterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCollectorK8sClusterRequest' is not null or undefined
            assertParamExists('createCollectorK8sCluster', 'createCollectorK8sClusterRequest', createCollectorK8sClusterRequest)
            const localVarPath = `/collector/k8s_cluster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCollectorK8sClusterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create note
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {CreateNoteRequest} createNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote: async (entityType: number, entityId: string, createNoteRequest: CreateNoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('createNote', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('createNote', 'entityId', entityId)
            // verify required parameter 'createNoteRequest' is not null or undefined
            assertParamExists('createNote', 'createNoteRequest', createNoteRequest)
            const localVarPath = `/entities/{entity-type}/{entity-id}/notes`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or link jira ticket
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {JiraRequest} jiraRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrLinkJiraTicket: async (entityType: number, entityId: string, jiraRequest: JiraRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('createOrLinkJiraTicket', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('createOrLinkJiraTicket', 'entityId', entityId)
            // verify required parameter 'jiraRequest' is not null or undefined
            assertParamExists('createOrLinkJiraTicket', 'jiraRequest', jiraRequest)
            const localVarPath = `/entities/{entity-type}/{entity-id}/jira-ticket`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jiraRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create jira config
         * @param {JiraConfig} jiraConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateJiraConfig: async (jiraConfig: JiraConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jiraConfig' is not null or undefined
            assertParamExists('createOrUpdateJiraConfig', 'jiraConfig', jiraConfig)
            const localVarPath = `/integrations/jira/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jiraConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a PII data storage config
         * @param {PiiConfig} piiConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePiiConfig: async (piiConfig: PiiConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'piiConfig' is not null or undefined
            assertParamExists('createOrUpdatePiiConfig', 'piiConfig', piiConfig)
            const localVarPath = `/tenants/config/pii-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piiConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update settings
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {SettingsObject} settingsObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSettings: async (entityType: number, entityId: string, settingsObject: SettingsObject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('createOrUpdateSettings', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('createOrUpdateSettings', 'entityId', entityId)
            // verify required parameter 'settingsObject' is not null or undefined
            assertParamExists('createOrUpdateSettings', 'settingsObject', settingsObject)
            const localVarPath = `/entities/{entity-type}/{entity-id}/settings`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a SMTP data storage config
         * @param {EmailConfig} emailConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSmtpConfig: async (emailConfig: EmailConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailConfig' is not null or undefined
            assertParamExists('createOrUpdateSmtpConfig', 'emailConfig', emailConfig)
            const localVarPath = `/tenants/config/email-smtp-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create policy based on onboarding user request
         * @param {UserRequestPolicyRequest} userRequestPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPolicyForUserRequest: async (userRequestPolicyRequest: UserRequestPolicyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestPolicyRequest' is not null or undefined
            assertParamExists('createPolicyForUserRequest', 'userRequestPolicyRequest', userRequestPolicyRequest)
            const localVarPath = `/policies/governance/user_request/create_policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create preference for provided entity
         * @param {string} entityId Entity Id
         * @param {Preference} preference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreference: async (entityId: string, preference: Preference, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('createPreference', 'entityId', entityId)
            // verify required parameter 'preference' is not null or undefined
            assertParamExists('createPreference', 'preference', preference)
            const localVarPath = `/entities/{entity-id}/preferences`
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preference, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a sensitive data config
         * @param {SensitiveDataConfigCreate} sensitiveDataConfigCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSensitiveDataConfig: async (sensitiveDataConfigCreate: SensitiveDataConfigCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sensitiveDataConfigCreate' is not null or undefined
            assertParamExists('createSensitiveDataConfig', 'sensitiveDataConfigCreate', sensitiveDataConfigCreate)
            const localVarPath = `/tenants/config/sensitive-settings/configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sensitiveDataConfigCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new tag / Update existing tag with new values if tag already exists;
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (newTag: NewTag, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newTag' is not null or undefined
            assertParamExists('createTag', 'newTag', newTag)
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new tenant
         * @param {CreateTenantRequest} createTenantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (createTenantRequest: CreateTenantRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTenantRequest' is not null or undefined
            assertParamExists('createTenant', 'createTenantRequest', createTenantRequest)
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTenantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Request for approval for a use case involving a AI entity
         * @param {EntityUserRequest} entityUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserRequestForEntity: async (entityUserRequest: EntityUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityUserRequest' is not null or undefined
            assertParamExists('createUserRequestForEntity', 'entityUserRequest', entityUserRequest)
            const localVarPath = `/policies/governance/user_request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a datasource
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasourcesDatasourceIdStartPost: async (datasourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasourceId' is not null or undefined
            assertParamExists('datasourcesDatasourceIdStartPost', 'datasourceId', datasourceId)
            const localVarPath = `/datasources/{datasource_id}/start`
                .replace(`{${"datasource_id"}}`, encodeURIComponent(String(datasourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop a datasource
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasourcesDatasourceIdStopPost: async (datasourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasourceId' is not null or undefined
            assertParamExists('datasourcesDatasourceIdStopPost', 'datasourceId', datasourceId)
            const localVarPath = `/datasources/{datasource_id}/stop`
                .replace(`{${"datasource_id"}}`, encodeURIComponent(String(datasourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new data source
         * @param {DatasourceConfig} datasourceConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasourcesPost: async (datasourceConfig: DatasourceConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasourceConfig' is not null or undefined
            assertParamExists('datasourcesPost', 'datasourceConfig', datasourceConfig)
            const localVarPath = `/datasources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasourceConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete collector
         * @param {string} id Collector ID to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollector: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCollector', 'id', id)
            const localVarPath = `/collector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a data source
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataSource: async (datasourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasourceId' is not null or undefined
            assertParamExists('deleteDataSource', 'datasourceId', datasourceId)
            const localVarPath = `/datasources/{datasource_id}`
                .replace(`{${"datasource_id"}}`, encodeURIComponent(String(datasourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete jira config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJiraConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integrations/jira/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete jira link for the entity.
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJiraLink: async (entityType: number, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('deleteJiraLink', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('deleteJiraLink', 'entityId', entityId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/jira-ticket`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete note for ID.
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {string} noteId Note ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (entityType: number, entityId: string, noteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('deleteNote', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('deleteNote', 'entityId', entityId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('deleteNote', 'noteId', noteId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/notes/{note-id}`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"note-id"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete preference for provided id
         * @param {string} entityId Entity Id
         * @param {string} preferenceId Preference Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreference: async (entityId: string, preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('deletePreference', 'entityId', entityId)
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('deletePreference', 'preferenceId', preferenceId)
            const localVarPath = `/entities/{entity-id}/preferences/{preference-id}`
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"preference-id"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User request
         * @param {DeleteUserRequestRequest} deleteUserRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRequest: async (deleteUserRequestRequest: DeleteUserRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUserRequestRequest' is not null or undefined
            assertParamExists('deleteUserRequest', 'deleteUserRequestRequest', deleteUserRequestRequest)
            const localVarPath = `/policies/governance/user_request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detach a tag from a resource
         * @param {Array<DetachTag>} detachTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachTag: async (detachTag: Array<DetachTag>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detachTag' is not null or undefined
            assertParamExists('detachTag', 'detachTag', detachTag)
            const localVarPath = `/tags/detach`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detachTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable a policy
         * @param {string} policyId Policy Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disablePolicy: async (policyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('disablePolicy', 'policyId', policyId)
            const localVarPath = `/policies/{policy_id}/disable`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a CSV file for a data source
         * @param {string} csvConfigId ID of the data source configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvDataSource: async (csvConfigId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvConfigId' is not null or undefined
            assertParamExists('downloadCsvDataSource', 'csvConfigId', csvConfigId)
            const localVarPath = `/upload/{csv_config_id}/download`
                .replace(`{${"csv_config_id"}}`, encodeURIComponent(String(csvConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable Browser Agent
         * @summary Enable Browser Agent
         * @param {EnableBrowserAgentRequest} [enableBrowserAgentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableBrowserAgent: async (enableBrowserAgentRequest?: EnableBrowserAgentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/browser-agent/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableBrowserAgentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable a policy
         * @param {string} policyId Policy Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enablePolicy: async (policyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('enablePolicy', 'policyId', policyId)
            const localVarPath = `/policies/{policy_id}/enable`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List events
         * @param {EventType} [eventType] Event entity types to filter
         * @param {Array<string>} [entityType] Event entity types to filter
         * @param {Array<string>} [anchorEntityTypes] Anchor entity types to filter
         * @param {Array<string>} [anchorEntityIds] Anchor entity ids to filter
         * @param {EventStatus} [eventStatus] Event status to filter
         * @param {Array<EventSeverity>} [eventSeverities] Event severity to filter
         * @param {Array<string>} [categories] Event category to filter
         * @param {number} [complianceType] Compliance Type to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsGet: async (eventType?: EventType, entityType?: Array<string>, anchorEntityTypes?: Array<string>, anchorEntityIds?: Array<string>, eventStatus?: EventStatus, eventSeverities?: Array<EventSeverity>, categories?: Array<string>, complianceType?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eventType !== undefined) {
                localVarQueryParameter['event-type'] = eventType;
            }

            if (entityType) {
                localVarQueryParameter['entity-type'] = entityType.join(COLLECTION_FORMATS.csv);
            }

            if (anchorEntityTypes) {
                localVarQueryParameter['anchor-entity-types'] = anchorEntityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (anchorEntityIds) {
                localVarQueryParameter['anchor-entity-ids'] = anchorEntityIds.join(COLLECTION_FORMATS.csv);
            }

            if (eventStatus !== undefined) {
                localVarQueryParameter['event-status'] = eventStatus;
            }

            if (eventSeverities) {
                localVarQueryParameter['event-severities'] = eventSeverities.join(COLLECTION_FORMATS.csv);
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories.join(COLLECTION_FORMATS.csv);
            }

            if (complianceType !== undefined) {
                localVarQueryParameter['compliance-type'] = complianceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export data into CSV
         * @param {CsvExportRequest} csvExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv: async (csvExportRequest: CsvExportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvExportRequest' is not null or undefined
            assertParamExists('exportCsv', 'csvExportRequest', csvExportRequest)
            const localVarPath = `/entities/export/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(csvExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Action Metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionMetadata: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/policies/action/meta_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Meta Info for AI Service
         * @param {string} [aiServiceDefinitionId] ai_service_definition_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiServiceMetaInfo: async (aiServiceDefinitionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trust-platform/ai-service-meta-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aiServiceDefinitionId !== undefined) {
                localVarQueryParameter['ai_service_definition_id'] = aiServiceDefinitionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ai Service Recommendation
         * @param {string} [aiServiceDefinitionId] ai_service_definition_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiServiceRecommendation: async (aiServiceDefinitionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trust-platform/recommendations/ai-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aiServiceDefinitionId !== undefined) {
                localVarQueryParameter['ai_service_definition_id'] = aiServiceDefinitionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all compute instance rate records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllComputeInstanceRateRecords: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cost/rate-card/compute-instance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all uploaded CSV files config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCsvUploads: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get feature flags
         * @param {boolean} [enabledOnly] Filter feature flags by their enabled state
         * @param {boolean} [computedOnly] Only return computed Filter feature flags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFeatureFlags: async (enabledOnly?: boolean, computedOnly?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (enabledOnly !== undefined) {
                localVarQueryParameter['enabledOnly'] = enabledOnly;
            }

            if (computedOnly !== undefined) {
                localVarQueryParameter['computedOnly'] = computedOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Managed LLM Inference rate cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagedLLMInferenceRecords: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cost/rate-card/managed-llm-inference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all raw events
         * @param {number} [startTime] Filter events from this timestamp (inclusive)
         * @param {number} [endTime] Filter events up to this timestamp (exclusive)
         * @param {Array<string>} [aiServiceId] Filter events based on AI service reference
         * @param {Array<string>} [userId] Filter events based on user reference
         * @param {Array<string>} [machineId] Filter events based on machine reference
         * @param {Array<GetAllRawEventsSourceNamesEnum>} [sourceNames] Filter events based on source name (e.g., code42, Singulr Browser Extension, cortex)
         * @param {Array<GetAllRawEventsEventTypesEnum>} [eventTypes] Filter events based on event type
         * @param {string} [pageCursor] Page cursor for pagination
         * @param {number} [limit] The number of results per page (for pagination)
         * @param {number} [aggregationBucketSecs] Aggregation bucket size in seconds for ai service access events
         * @param {GetAllRawEventsSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRawEvents: async (startTime?: number, endTime?: number, aiServiceId?: Array<string>, userId?: Array<string>, machineId?: Array<string>, sourceNames?: Array<GetAllRawEventsSourceNamesEnum>, eventTypes?: Array<GetAllRawEventsEventTypesEnum>, pageCursor?: string, limit?: number, aggregationBucketSecs?: number, sort?: GetAllRawEventsSortEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/raw-events/ai-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (aiServiceId) {
                localVarQueryParameter['ai_service_id'] = aiServiceId.join(COLLECTION_FORMATS.csv);
            }

            if (userId) {
                localVarQueryParameter['user_id'] = userId.join(COLLECTION_FORMATS.csv);
            }

            if (machineId) {
                localVarQueryParameter['machine_id'] = machineId.join(COLLECTION_FORMATS.csv);
            }

            if (sourceNames) {
                localVarQueryParameter['source_names'] = sourceNames.join(COLLECTION_FORMATS.csv);
            }

            if (eventTypes) {
                localVarQueryParameter['event_types'] = eventTypes.join(COLLECTION_FORMATS.csv);
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page_cursor'] = pageCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (aggregationBucketSecs !== undefined) {
                localVarQueryParameter['aggregation_bucket_secs'] = aggregationBucketSecs;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all roles available to users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/all_roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Vector DB Rate cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVectorDBRecords: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cost/rate-card/vector-db`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provide annotation aggregations for list of content ids
         * @param {AnnotationAggregationTypeEnum} [aggregateType] Pass true to get aggregates of values. Default behaviour return key aggregates
         * @param {Array<string>} [sourceIds] list of source id to filter
         * @param {Array<string>} [destinationIds] list of destination id to filter
         * @param {Array<string>} [linkedContentIds] list of linked content ids to filter
         * @param {Array<string>} [namedEntityTypes] type of named entities in annotations
         * @param {Array<string>} [namedEntityValues] values of named entities in annotations
         * @param {number} [startTime] time range filter
         * @param {number} [endTime] time range filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationAggregation: async (aggregateType?: AnnotationAggregationTypeEnum, sourceIds?: Array<string>, destinationIds?: Array<string>, linkedContentIds?: Array<string>, namedEntityTypes?: Array<string>, namedEntityValues?: Array<string>, startTime?: number, endTime?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations/aggregation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aggregateType !== undefined) {
                localVarQueryParameter['aggregate_type'] = aggregateType;
            }

            if (sourceIds) {
                localVarQueryParameter['source_ids'] = sourceIds.join(COLLECTION_FORMATS.csv);
            }

            if (destinationIds) {
                localVarQueryParameter['destination_ids'] = destinationIds.join(COLLECTION_FORMATS.csv);
            }

            if (linkedContentIds) {
                localVarQueryParameter['linked_content_ids'] = linkedContentIds.join(COLLECTION_FORMATS.csv);
            }

            if (namedEntityTypes) {
                localVarQueryParameter['named_entity_types'] = namedEntityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (namedEntityValues) {
                localVarQueryParameter['named_entity_values'] = namedEntityValues.join(COLLECTION_FORMATS.csv);
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filtered annotation list
         * @param {Array<string>} namedEntityTypes type of named entities
         * @param {number} offset offset index for pagination
         * @param {number} limit page limit for pagination
         * @param {Array<string>} [namedEntityValues] values of named entities
         * @param {Array<string>} [contentIds] content ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationList: async (namedEntityTypes: Array<string>, offset: number, limit: number, namedEntityValues?: Array<string>, contentIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namedEntityTypes' is not null or undefined
            assertParamExists('getAnnotationList', 'namedEntityTypes', namedEntityTypes)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getAnnotationList', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getAnnotationList', 'limit', limit)
            const localVarPath = `/annotations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (namedEntityTypes) {
                localVarQueryParameter['named_entity_types'] = namedEntityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (namedEntityValues) {
                localVarQueryParameter['named_entity_values'] = namedEntityValues.join(COLLECTION_FORMATS.csv);
            }

            if (contentIds) {
                localVarQueryParameter['content_ids'] = contentIds.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch annotation details using content id
         * @param {string} contentId Id of content
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationsOfContent: async (contentId: string, contentEntityType: ContentTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getAnnotationsOfContent', 'contentId', contentId)
            // verify required parameter 'contentEntityType' is not null or undefined
            assertParamExists('getAnnotationsOfContent', 'contentEntityType', contentEntityType)
            const localVarPath = `/contents/{content_id}/annotations`
                .replace(`{${"content_id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentEntityType !== undefined) {
                localVarQueryParameter['content_entity_type'] = contentEntityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the policies applied to an entity type + policy type
         * @param {string} entityType Entity type
         * @param {string} policyType Policy type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationStatusForEntityTypeAndPolicyType: async (entityType: string, policyType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getApplicationStatusForEntityTypeAndPolicyType', 'entityType', entityType)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('getApplicationStatusForEntityTypeAndPolicyType', 'policyType', policyType)
            const localVarPath = `/policies/entity-types/{entity-type}/policy-types/{policy-type}/application_status`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"policy-type"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get audit events
         * @param {GetAuditEventsCategoryEnum} category Category of audit events.
         * @param {Array<number>} [entityType] Entity Type
         * @param {number} [startTime] Start time filter
         * @param {number} [endTime] End time filter
         * @param {GetAuditEventsOperationEnum} [operation] Operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditEvents: async (category: GetAuditEventsCategoryEnum, entityType?: Array<number>, startTime?: number, endTime?: number, operation?: GetAuditEventsOperationEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('getAuditEvents', 'category', category)
            const localVarPath = `/audit/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType) {
                localVarQueryParameter['entity_type'] = entityType.join(COLLECTION_FORMATS.csv);
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all collectors
         * @param {string} [id] Collector ID to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectors: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/collector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get infra configurations
         * @summary Get infra configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigDetail: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mgmt/tenant/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get infra configurations
         * @summary Get infra configurations
         * @param {string} deploymentId Deployment Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigDetailsByDeployment: async (deploymentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('getConfigDetailsByDeployment', 'deploymentId', deploymentId)
            const localVarPath = `/mgmt/tenant/config/{deployment-id}`
                .replace(`{${"deployment-id"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get content facets
         * @param {ContentFacetTypeEnum} facetProperty Facet on this property
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {Array<string>} [sourceIds] list of source id to filter
         * @param {Array<string>} [destinationIds] list of destination id to filter
         * @param {Array<string>} [linkedContentIds] list of linked content ids to filter
         * @param {Array<string>} [namedEntityTypes] type of named entities in annotations
         * @param {Array<string>} [namedEntityValues] values of named entities in annotations
         * @param {number} [startTime] time range filter
         * @param {number} [endTime] time range filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFacets: async (facetProperty: ContentFacetTypeEnum, contentEntityType: ContentTypeEnum, sourceIds?: Array<string>, destinationIds?: Array<string>, linkedContentIds?: Array<string>, namedEntityTypes?: Array<string>, namedEntityValues?: Array<string>, startTime?: number, endTime?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facetProperty' is not null or undefined
            assertParamExists('getContentFacets', 'facetProperty', facetProperty)
            // verify required parameter 'contentEntityType' is not null or undefined
            assertParamExists('getContentFacets', 'contentEntityType', contentEntityType)
            const localVarPath = `/contents/facets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facetProperty !== undefined) {
                localVarQueryParameter['facet_property'] = facetProperty;
            }

            if (contentEntityType !== undefined) {
                localVarQueryParameter['content_entity_type'] = contentEntityType;
            }

            if (sourceIds) {
                localVarQueryParameter['source_ids'] = sourceIds.join(COLLECTION_FORMATS.csv);
            }

            if (destinationIds) {
                localVarQueryParameter['destination_ids'] = destinationIds.join(COLLECTION_FORMATS.csv);
            }

            if (linkedContentIds) {
                localVarQueryParameter['linked_content_ids'] = linkedContentIds.join(COLLECTION_FORMATS.csv);
            }

            if (namedEntityTypes) {
                localVarQueryParameter['named_entity_types'] = namedEntityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (namedEntityValues) {
                localVarQueryParameter['named_entity_values'] = namedEntityValues.join(COLLECTION_FORMATS.csv);
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch content detail using id
         * @param {string} contentId Id of content
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFromId: async (contentId: string, contentEntityType: ContentTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getContentFromId', 'contentId', contentId)
            // verify required parameter 'contentEntityType' is not null or undefined
            assertParamExists('getContentFromId', 'contentEntityType', contentEntityType)
            const localVarPath = `/contents/{content_id}`
                .replace(`{${"content_id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentEntityType !== undefined) {
                localVarQueryParameter['content_entity_type'] = contentEntityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of content
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {number} offset offset index for pagination
         * @param {number} limit page limit for pagination
         * @param {Array<string>} [sourceIds] list of source id to filter
         * @param {Array<string>} [destinationIds] list of destination id to filter
         * @param {Array<string>} [linkedContentIds] list of linked content ids to filter
         * @param {Array<string>} [namedEntityTypes] type of named entities in annotations
         * @param {Array<string>} [namedEntityValues] values of named entities in annotations
         * @param {number} [startTime] time range filter
         * @param {number} [endTime] time range filter
         * @param {GetContentListSortOrderEnum} [sortOrder] Define the order of content. Sorting parameter is always time of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentList: async (contentEntityType: ContentTypeEnum, offset: number, limit: number, sourceIds?: Array<string>, destinationIds?: Array<string>, linkedContentIds?: Array<string>, namedEntityTypes?: Array<string>, namedEntityValues?: Array<string>, startTime?: number, endTime?: number, sortOrder?: GetContentListSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentEntityType' is not null or undefined
            assertParamExists('getContentList', 'contentEntityType', contentEntityType)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getContentList', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getContentList', 'limit', limit)
            const localVarPath = `/contents/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentEntityType !== undefined) {
                localVarQueryParameter['content_entity_type'] = contentEntityType;
            }

            if (sourceIds) {
                localVarQueryParameter['source_ids'] = sourceIds.join(COLLECTION_FORMATS.csv);
            }

            if (destinationIds) {
                localVarQueryParameter['destination_ids'] = destinationIds.join(COLLECTION_FORMATS.csv);
            }

            if (linkedContentIds) {
                localVarQueryParameter['linked_content_ids'] = linkedContentIds.join(COLLECTION_FORMATS.csv);
            }

            if (namedEntityTypes) {
                localVarQueryParameter['named_entity_types'] = namedEntityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (namedEntityValues) {
                localVarQueryParameter['named_entity_values'] = namedEntityValues.join(COLLECTION_FORMATS.csv);
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get schema for core entity type.
         * @param {string} entityType Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoreEntityTypeSchema: async (entityType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getCoreEntityTypeSchema', 'entityType', entityType)
            const localVarPath = `/schemas/core/entity-types/{entity_type}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get rate card records by filter and group
         * @param {FilterAndGroupRateCardRequest} filterAndGroupRateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostRecordsByFilterAndGroup: async (filterAndGroupRateCardRequest: FilterAndGroupRateCardRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterAndGroupRateCardRequest' is not null or undefined
            assertParamExists('getCostRecordsByFilterAndGroup', 'filterAndGroupRateCardRequest', filterAndGroupRateCardRequest)
            const localVarPath = `/cost/filter-and-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterAndGroupRateCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a data source
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSource: async (datasourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasourceId' is not null or undefined
            assertParamExists('getDataSource', 'datasourceId', datasourceId)
            const localVarPath = `/datasources/{datasource_id}`
                .replace(`{${"datasource_id"}}`, encodeURIComponent(String(datasourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all data sources
         * @param {string} [collectorId] Collector ID to filter
         * @param {string} [datasourceType] Type of data source AWS_INVENTORY, AWS_FLOW_LOGS, AWS_ROUTE53, AWS_EVENT_ACCESS_LOGS, AWS_SAGEMAKER, GCP_INVENTORY, GCP_FLOW_LOGS, GCP_CLOUD_DNS, GCP_EVENT_ACCESS_LOGS, GCP_VERTEX_AI
         * @param {string} [vendor] Vendor name to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSources: async (collectorId?: string, datasourceType?: string, vendor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datasources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (collectorId !== undefined) {
                localVarQueryParameter['collector_id'] = collectorId;
            }

            if (datasourceType !== undefined) {
                localVarQueryParameter['datasource_type'] = datasourceType;
            }

            if (vendor !== undefined) {
                localVarQueryParameter['vendor'] = vendor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity facets
         * @param {number} entityType Entity Type
         * @param {string} facetProperty Property to fetch facets
         * @param {boolean} [includeCount] Return facet count
         * @param {string} [queryId] Query Id to limit facets
         * @param {GetFacetDataParam} [getFacetDataParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityFacets: async (entityType: number, facetProperty: string, includeCount?: boolean, queryId?: string, getFacetDataParam?: GetFacetDataParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityFacets', 'entityType', entityType)
            // verify required parameter 'facetProperty' is not null or undefined
            assertParamExists('getEntityFacets', 'facetProperty', facetProperty)
            const localVarPath = `/entities/{entity-type}/facets/{facet-property}`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"facet-property"}}`, encodeURIComponent(String(facetProperty)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCount !== undefined) {
                localVarQueryParameter['include-count'] = includeCount;
            }

            if (queryId !== undefined) {
                localVarQueryParameter['query-id'] = queryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFacetDataParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * supported mappings ip-> app ; ip->workload; app->ip; workload->ip
         * @summary Get entity mappings
         * @param {number} entityType Entity Type
         * @param {number} mapToEntityType Entity Type to map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappings: async (entityType: number, mapToEntityType: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityMappings', 'entityType', entityType)
            // verify required parameter 'mapToEntityType' is not null or undefined
            assertParamExists('getEntityMappings', 'mapToEntityType', mapToEntityType)
            const localVarPath = `/entities/{entity-type}/mappings/{map-to-entity-type}`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"map-to-entity-type"}}`, encodeURIComponent(String(mapToEntityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity mappings by reference object
         * @param {number} destEntityType Entity Type
         * @param {Array<EntityReference>} entityReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappingsByReference: async (destEntityType: number, entityReference: Array<EntityReference>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'destEntityType' is not null or undefined
            assertParamExists('getEntityMappingsByReference', 'destEntityType', destEntityType)
            // verify required parameter 'entityReference' is not null or undefined
            assertParamExists('getEntityMappingsByReference', 'entityReference', entityReference)
            const localVarPath = `/entities/{dest-entity-type}/mappings`
                .replace(`{${"dest-entity-type"}}`, encodeURIComponent(String(destEntityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityReference, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity data
         * @param {number} entityType Entity Type
         * @param {string} queryId Query id to fetch data
         * @param {GetEntityDataParam} getEntityDataParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityPage: async (entityType: number, queryId: string, getEntityDataParam: GetEntityDataParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityPage', 'entityType', entityType)
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('getEntityPage', 'queryId', queryId)
            // verify required parameter 'getEntityDataParam' is not null or undefined
            assertParamExists('getEntityPage', 'getEntityDataParam', getEntityDataParam)
            const localVarPath = `/entities/{entity-type}/data/{query-id}`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"query-id"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getEntityDataParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity trend metrics according to provided filters
         * @param {EntityTrendRequest} [entityTrendRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTrend: async (entityTrendRequest?: EntityTrendRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/entity/trend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityTrendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get schema for UI entity type.
         * @param {string} entityType Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypeSchema: async (entityType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityTypeSchema', 'entityType', entityType)
            const localVarPath = `/schemas/ui/entity-types/{entity_type}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get schema for UI enum types.
         * @param {string} enumType Enum type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumTypeSchema: async (enumType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enumType' is not null or undefined
            assertParamExists('getEnumTypeSchema', 'enumType', enumType)
            const localVarPath = `/schemas/ui/enum-types/{enum_type}`
                .replace(`{${"enum_type"}}`, encodeURIComponent(String(enumType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Event Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get events facets
         * @param {EventFacetTypeEnum} facetProperty Facet on this property
         * @param {EventType} [eventType] Event entity types to filter
         * @param {Array<string>} [entityType] Event entity types to filter
         * @param {Array<string>} [anchorEntityTypes] Anchor entity types to filter
         * @param {Array<string>} [anchorEntityIds] Anchor entity ids to filter
         * @param {EventStatus} [eventStatus] Event status to filter
         * @param {Array<EventSeverity>} [eventSeverities] Event severity to filter
         * @param {Array<string>} [categories] Event category to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventFacets: async (facetProperty: EventFacetTypeEnum, eventType?: EventType, entityType?: Array<string>, anchorEntityTypes?: Array<string>, anchorEntityIds?: Array<string>, eventStatus?: EventStatus, eventSeverities?: Array<EventSeverity>, categories?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facetProperty' is not null or undefined
            assertParamExists('getEventFacets', 'facetProperty', facetProperty)
            const localVarPath = `/events/facets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facetProperty !== undefined) {
                localVarQueryParameter['facet-property'] = facetProperty;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['event-type'] = eventType;
            }

            if (entityType) {
                localVarQueryParameter['entity-type'] = entityType.join(COLLECTION_FORMATS.csv);
            }

            if (anchorEntityTypes) {
                localVarQueryParameter['anchor-entity-types'] = anchorEntityTypes.join(COLLECTION_FORMATS.csv);
            }

            if (anchorEntityIds) {
                localVarQueryParameter['anchor-entity-ids'] = anchorEntityIds.join(COLLECTION_FORMATS.csv);
            }

            if (eventStatus !== undefined) {
                localVarQueryParameter['event-status'] = eventStatus;
            }

            if (eventSeverities) {
                localVarQueryParameter['event-severities'] = eventSeverities.join(COLLECTION_FORMATS.csv);
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Event Severity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSeverities: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events/severity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Event to Content Navigation Metadata
         * @param {NavigationRequests} navigationRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventToContentNavigationMetadata: async (navigationRequests: NavigationRequests, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'navigationRequests' is not null or undefined
            assertParamExists('getEventToContentNavigationMetadata', 'navigationRequests', navigationRequests)
            const localVarPath = `/navigation/event/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(navigationRequests, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get general settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/config/general-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get jira config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJiraConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integrations/jira/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get lifecycle events
         * @param {number} [entityType] Entity Type
         * @param {string} [entityId] Entity id to fetch logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLifecycleEvents: async (entityType?: number, entityId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lifecycle/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a jira linked to this entity
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedJiraTicket: async (entityType: number, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getLinkedJiraTicket', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getLinkedJiraTicket', 'entityId', entityId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/jira-ticket`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of Machine Events
         * @param {MachineEventRequest} [machineEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMachineEventsList: async (machineEventRequest?: MachineEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/machine-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(machineEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of Machine Events
         * @param {MachineEventTimeSeriesRequest} [machineEventTimeSeriesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMachineEventsListTimeSeries: async (machineEventTimeSeriesRequest?: MachineEventTimeSeriesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/machine-events/time-series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(machineEventTimeSeriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metric schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSchema: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metric series
         * @param {MetricSeriesQueryRequest} [metricSeriesQueryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSeries: async (metricSeriesQueryRequest?: MetricSeriesQueryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricSeriesQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metric series for AI service (Automatically chooses the fq metric)
         * @param {MetricSeriesQueryRequest} [metricSeriesQueryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSeriesForAIService: async (metricSeriesQueryRequest?: MetricSeriesQueryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/series/ai_service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricSeriesQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metric tags
         * @param {MetricTagListRequest} [metricTagListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricTagValues: async (metricTagListRequest?: MetricTagListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricTagListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a note
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {string} noteId Note ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById: async (entityType: number, entityId: string, noteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getNoteById', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getNoteById', 'entityId', entityId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('getNoteById', 'noteId', noteId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/notes/{note-id}`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"note-id"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all items which are currently onboarded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardedItems: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/policies/governance/board`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get activity related info for items which are currently onboarded
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardedItemsActivity: async (entityRef: Array<EntityRef>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRef' is not null or undefined
            assertParamExists('getOnboardedItemsActivity', 'entityRef', entityRef)
            const localVarPath = `/policies/governance/board/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get header info items which are currently onboarded like risk
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardedItemsHeaderInfo: async (entityRef: Array<EntityRef>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRef' is not null or undefined
            assertParamExists('getOnboardedItemsHeaderInfo', 'entityRef', entityRef)
            const localVarPath = `/policies/governance/board/header_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current user\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get PII data storage config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPiiConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/config/pii-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Policy
         * @summary Get Policy
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolices: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/browser-agent-mgmt/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get possible values of attributes of the entity type used in policy
         * @param {string} applicableEntityType Applicable entity type for this policy type.
         * @param {string} [attributeName] Attribute name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyAttributeValues: async (applicableEntityType: string, attributeName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicableEntityType' is not null or undefined
            assertParamExists('getPolicyAttributeValues', 'applicableEntityType', applicableEntityType)
            const localVarPath = `/schemas/policy/attribute-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (applicableEntityType !== undefined) {
                localVarQueryParameter['applicable_entity_type'] = applicableEntityType;
            }

            if (attributeName !== undefined) {
                localVarQueryParameter['attribute_name'] = attributeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all policy types and their supported entity types and properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyEntityTypeProperties: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schemas/policy/entity-type-properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get preference for provided id
         * @param {string} entityId Entity Id
         * @param {string} preferenceId Preference Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreference: async (entityId: string, preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getPreference', 'entityId', entityId)
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('getPreference', 'preferenceId', preferenceId)
            const localVarPath = `/entities/{entity-id}/preferences/{preference-id}`
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"preference-id"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all preferences for provided entity
         * @param {string} entityId Entity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferences: async (entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getPreferences', 'entityId', entityId)
            const localVarPath = `/entities/{entity-id}/preferences`
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recommendation for Entity ID.
         * @param {string} entityId Entity ID
         * @param {string} entityType Entity Type
         * @param {string} deploymentEntityType Deployment Entity Type
         * @param {number} startTime Start time
         * @param {number} endTime End time
         * @param {string} [entityName] Entity Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendationCostForEntity: async (entityId: string, entityType: string, deploymentEntityType: string, startTime: number, endTime: number, entityName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRecommendationCostForEntity', 'entityId', entityId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getRecommendationCostForEntity', 'entityType', entityType)
            // verify required parameter 'deploymentEntityType' is not null or undefined
            assertParamExists('getRecommendationCostForEntity', 'deploymentEntityType', deploymentEntityType)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getRecommendationCostForEntity', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getRecommendationCostForEntity', 'endTime', endTime)
            const localVarPath = `/cost/recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (entityName !== undefined) {
                localVarQueryParameter['entity_name'] = entityName;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (deploymentEntityType !== undefined) {
                localVarQueryParameter['deployment_entity_type'] = deploymentEntityType;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all regions
         * @param {string} vendorName Vendor name to filter
         * @param {string} [regionSubtype] Region sub-type to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions: async (vendorName: string, regionSubtype?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorName' is not null or undefined
            assertParamExists('getRegions', 'vendorName', vendorName)
            const localVarPath = `/config/region`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vendorName !== undefined) {
                localVarQueryParameter['vendor_name'] = vendorName;
            }

            if (regionSubtype !== undefined) {
                localVarQueryParameter['region_subtype'] = regionSubtype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Runtime Config
         * @summary Get Runtime Config
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuntimeConfig: async (agentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/browser-agent-mgmt/runtime-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (agentId !== undefined) {
                localVarQueryParameter['agent_id'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get savings cost for Entity Group.
         * @param {string} entityGroupType Entity Group Type
         * @param {number} startTime Start time
         * @param {number} endTime End time
         * @param {Array<string>} [entityGroupNames] List of Entity Group Names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavingsCostForEntityGroup: async (entityGroupType: string, startTime: number, endTime: number, entityGroupNames?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityGroupType' is not null or undefined
            assertParamExists('getSavingsCostForEntityGroup', 'entityGroupType', entityGroupType)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getSavingsCostForEntityGroup', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getSavingsCostForEntityGroup', 'endTime', endTime)
            const localVarPath = `/cost/savings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityGroupType !== undefined) {
                localVarQueryParameter['entity_group_type'] = entityGroupType;
            }

            if (entityGroupNames) {
                localVarQueryParameter['entity_group_names'] = entityGroupNames.join(COLLECTION_FORMATS.csv);
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sensitive data settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensitiveSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/config/sensitive-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get settings
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (entityType: number, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getSettings', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getSettings', 'entityId', entityId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/settings`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get settings
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsConfig: async (entityType: number, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getSettingsConfig', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getSettingsConfig', 'entityId', entityId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/settings-config`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Email SMTP data storage config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmtpConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/config/email-smtp-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get app, workload, or library linked vulnerabilities
         * @param {number} entityType Entity Type
         * @param {string} entityId Entity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareVulnerabilities: async (entityType: number, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getSoftwareVulnerabilities', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getSoftwareVulnerabilities', 'entityId', entityId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/software/vulnerabilities`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedDatasources: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/supported-datasources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supported preference types for provided id
         * @param {number} entityType Entity Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedPreferences: async (entityType: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getSupportedPreferences', 'entityType', entityType)
            const localVarPath = `/entities/{entity-type}/supported-preferences/`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Is a topic approved, unapproved or unclear based on closest, already-existing policy topics
         * @summary approval status of topics - approved/unapproved/unclear
         * @param {Topics} topics 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsApprovalStatus: async (topics: Topics, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topics' is not null or undefined
            assertParamExists('getTopicsApprovalStatus', 'topics', topics)
            const localVarPath = `/ai/topics/policy/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topics, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unverified AI Service
         * @param {GetEntityDataParam} getEntityDataParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnverifiedAiService: async (getEntityDataParam: GetEntityDataParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getEntityDataParam' is not null or undefined
            assertParamExists('getUnverifiedAiService', 'getEntityDataParam', getEntityDataParam)
            const localVarPath = `/trust-platform/unverified-ai-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getEntityDataParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get usage cost for Entity ID.
         * @param {string} entityId List of entities
         * @param {string} entityType Entity Type
         * @param {number} startTime Start time
         * @param {number} endTime End time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageCostForEntity: async (entityId: string, entityType: string, startTime: number, endTime: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getUsageCostForEntity', 'entityId', entityId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getUsageCostForEntity', 'entityType', entityType)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getUsageCostForEntity', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getUsageCostForEntity', 'endTime', endTime)
            const localVarPath = `/cost/usage/entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get usage cost for Entity Group.
         * @param {GroupUsageCostRequest} groupUsageCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageCostForEntityGroup: async (groupUsageCostRequest: GroupUsageCostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupUsageCostRequest' is not null or undefined
            assertParamExists('getUsageCostForEntityGroup', 'groupUsageCostRequest', groupUsageCostRequest)
            const localVarPath = `/cost/usage/entity-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupUsageCostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Requests for an AI entity
         * @param {EntityRef} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRequestsForEntity: async (entityRef: EntityRef, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRef' is not null or undefined
            assertParamExists('getUserRequestsForEntity', 'entityRef', entityRef)
            const localVarPath = `/policies/governance/user_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new graph
         * @param {string} entityType The type of the Entity
         * @param {string} entityId The ID of the Entity
         * @param {string} graphType The type of graph
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphEntityTypeEntityIdGraphTypeGet: async (entityType: string, entityId: string, graphType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('graphEntityTypeEntityIdGraphTypeGet', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('graphEntityTypeEntityIdGraphTypeGet', 'entityId', entityId)
            // verify required parameter 'graphType' is not null or undefined
            assertParamExists('graphEntityTypeEntityIdGraphTypeGet', 'graphType', graphType)
            const localVarPath = `/graph/{entity_type}/{entity_id}/{graph_type}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"graph_type"}}`, encodeURIComponent(String(graphType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ingest data
         * @summary Ingest data
         * @param {string} [authorization] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        ingestData: async (authorization?: string, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/browser-agent-mgmt/ingest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List different builds for Browser Extension
         * @summary List different builds for Browser Extension
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBrowserAgentBuilds: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/browser-agent/builds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List different builds for Browser Extension
         * @summary List different builds for Browser Extension
         * @param {number} start 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBrowserAgents: async (start: number, limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listBrowserAgents', 'start', start)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('listBrowserAgents', 'limit', limit)
            const localVarPath = `/browser-agent/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List schemas for concrete policy types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConcretePolicySchemas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schemas/core/policy-entity-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List schemas for core entity types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCoreEntityTypeSchemas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schemas/core/entity-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List schemas for UI entity types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntityTypeSchemas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schemas/ui/entity-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List schema for UI enum types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnumTypeSchemas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schemas/ui/enum-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List notes
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotes: async (entityType: number, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('listNotes', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('listNotes', 'entityId', entityId)
            const localVarPath = `/entities/{entity-type}/{entity-id}/notes`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get risk categories for risk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRiskCategory: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schemas/risk/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tags
         * @param {string} [key] Filter tags by key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTags: async (key?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all localized messages for the current language. The language can be specified using the `Accept-Language` header.
         * @summary Get all localized messages
         * @param {string} [acceptLanguage] Language for the messages (e.g., \&#39;en\&#39;, \&#39;es\&#39;, \&#39;fr\&#39;). Defaults to \&#39;en\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesGet: async (acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a localized message by providing the message key. The language can be specified using the `Accept-Language` header.
         * @summary Get a localized message by key
         * @param {string} key The key for the message to be retrieved.
         * @param {string} [acceptLanguage] Language for the message (e.g., \&#39;en\&#39;, \&#39;es\&#39;, \&#39;fr\&#39;). Defaults to \&#39;en\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesKeyGet: async (key: string, acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('messagesKeyGet', 'key', key)
            const localVarPath = `/messages/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Offboard a set of supported entities
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offboardEntities: async (entityRef: Array<EntityRef>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRef' is not null or undefined
            assertParamExists('offboardEntities', 'entityRef', entityRef)
            const localVarPath = `/policies/governance/board/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Onboard a set of supported entities
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardEntities: async (entityRef: Array<EntityRef>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRef' is not null or undefined
            assertParamExists('onboardEntities', 'entityRef', entityRef)
            const localVarPath = `/policies/governance/board/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the policies applied to an entity and its surrounding context
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesApplicationStatusPost: async (entityRef: Array<EntityRef>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRef' is not null or undefined
            assertParamExists('policiesApplicationStatusPost', 'entityRef', entityRef)
            const localVarPath = `/policies/application_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List State Transitions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesApprovalStatusStateTransitionsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/policies/approval_status/state_transitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve a set of supported entities
         * @param {EntityRefs} entityRefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesApprovePost: async (entityRefs: EntityRefs, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRefs' is not null or undefined
            assertParamExists('policiesApprovePost', 'entityRefs', entityRefs)
            const localVarPath = `/policies/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRefs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List policies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete policy for ID.
         * @param {string} policyId Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPolicyIdDelete: async (policyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('policiesPolicyIdDelete', 'policyId', policyId)
            const localVarPath = `/policies/{policy_id}`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get policy for ID.
         * @param {string} policyId Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPolicyIdGet: async (policyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('policiesPolicyIdGet', 'policyId', policyId)
            const localVarPath = `/policies/{policy_id}`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a policy
         * @param {string} policyId Policy ID
         * @param {PolicyData} policyData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPolicyIdPut: async (policyId: string, policyData: PolicyData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('policiesPolicyIdPut', 'policyId', policyId)
            // verify required parameter 'policyData' is not null or undefined
            assertParamExists('policiesPolicyIdPut', 'policyData', policyData)
            const localVarPath = `/policies/{policy_id}`
                .replace(`{${"policy_id"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Policy
         * @param {PolicyData} policyData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPost: async (policyData: PolicyData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyData' is not null or undefined
            assertParamExists('policiesPost', 'policyData', policyData)
            const localVarPath = `/policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the approval status of a set of supported entities by property values
         * @param {ApprovalStatusChange} approvalStatusChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPropertyApprovalStatusChangePost: async (approvalStatusChange: ApprovalStatusChange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'approvalStatusChange' is not null or undefined
            assertParamExists('policiesPropertyApprovalStatusChangePost', 'approvalStatusChange', approvalStatusChange)
            const localVarPath = `/policies/property/approval_status/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalStatusChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve a set of supported entities by property values
         * @param {PropertyValues} propertyValues 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPropertyApprovePost: async (propertyValues: PropertyValues, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyValues' is not null or undefined
            assertParamExists('policiesPropertyApprovePost', 'propertyValues', propertyValues)
            const localVarPath = `/policies/property/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyValues, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Un-approve a set of supported entities by property values
         * @param {PropertyValues} propertyValues 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPropertyUnapprovePost: async (propertyValues: PropertyValues, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyValues' is not null or undefined
            assertParamExists('policiesPropertyUnapprovePost', 'propertyValues', propertyValues)
            const localVarPath = `/policies/property/unapprove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyValues, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Un-approve a set of supported entities
         * @param {EntityRefs} entityRefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesUnapprovePost: async (entityRefs: EntityRefs, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityRefs' is not null or undefined
            assertParamExists('policiesUnapprovePost', 'entityRefs', entityRefs)
            const localVarPath = `/policies/unapprove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityRefs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List policy contexts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/policy-contexts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get policy context for ID.
         * @param {string} policyCtxId Policy Context ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPolicyCtxIdGet: async (policyCtxId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyCtxId' is not null or undefined
            assertParamExists('policyContextsPolicyCtxIdGet', 'policyCtxId', policyCtxId)
            const localVarPath = `/policy-contexts/{policy_ctx_id}`
                .replace(`{${"policy_ctx_id"}}`, encodeURIComponent(String(policyCtxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get policy suggestions translated from a policy context using ID.
         * @param {string} policyCtxId Policy Context ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPolicyCtxIdPolicySuggestionsGet: async (policyCtxId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyCtxId' is not null or undefined
            assertParamExists('policyContextsPolicyCtxIdPolicySuggestionsGet', 'policyCtxId', policyCtxId)
            const localVarPath = `/policy-contexts/{policy_ctx_id}/policy-suggestions`
                .replace(`{${"policy_ctx_id"}}`, encodeURIComponent(String(policyCtxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a policy context
         * @param {string} policyCtxId Policy Context ID
         * @param {PolicyContextData} policyContextData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPolicyCtxIdPut: async (policyCtxId: string, policyContextData: PolicyContextData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyCtxId' is not null or undefined
            assertParamExists('policyContextsPolicyCtxIdPut', 'policyCtxId', policyCtxId)
            // verify required parameter 'policyContextData' is not null or undefined
            assertParamExists('policyContextsPolicyCtxIdPut', 'policyContextData', policyContextData)
            const localVarPath = `/policy-contexts/{policy_ctx_id}`
                .replace(`{${"policy_ctx_id"}}`, encodeURIComponent(String(policyCtxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyContextData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Policy Context
         * @param {PolicyContextData} policyContextData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPost: async (policyContextData: PolicyContextData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyContextData' is not null or undefined
            assertParamExists('policyContextsPost', 'policyContextData', policyContextData)
            const localVarPath = `/policy-contexts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyContextData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get preview of provided summary
         * @param {boolean} conditionMetFlag Condition Met Flag false indicates negation of condition
         * @param {PolicyData} policyData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyPreview: async (conditionMetFlag: boolean, policyData: PolicyData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conditionMetFlag' is not null or undefined
            assertParamExists('policyPreview', 'conditionMetFlag', conditionMetFlag)
            // verify required parameter 'policyData' is not null or undefined
            assertParamExists('policyPreview', 'policyData', policyData)
            const localVarPath = `/policies/preview/condition/{condition_met_flag}`
                .replace(`{${"condition_met_flag"}}`, encodeURIComponent(String(conditionMetFlag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get summary of defined policies
         * @param {PolicySummaryRequest} policySummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policySummary: async (policySummaryRequest: PolicySummaryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policySummaryRequest' is not null or undefined
            assertParamExists('policySummary', 'policySummaryRequest', policySummaryRequest)
            const localVarPath = `/policies/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policySummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register User using browser agent
         * @summary Register User using browser agent
         * @param {RegisterUserRequest} [registerUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (registerUserRequest?: RegisterUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/browser-agent/register-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds/updates the provided secrets to the secrets manager
         * @summary Set Update Secrets
         * @param {CustomerSecretInput} customerSecretInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUpdateSecrets: async (customerSecretInput: CustomerSecretInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerSecretInput' is not null or undefined
            assertParamExists('setUpdateSecrets', 'customerSecretInput', customerSecretInput)
            const localVarPath = `/ai/secrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSecretInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change infra configurations
         * @summary Change infra configurations
         * @param {UpdateConfigRequest} [updateConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig: async (updateConfigRequest?: UpdateConfigRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mgmt/tenant/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change infra configurations
         * @summary Change infra configurations
         * @param {string} deploymentId Deployment Id
         * @param {UpdateConfigRequest} [updateConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigByDeployment: async (deploymentId: string, updateConfigRequest?: UpdateConfigRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('updateConfigByDeployment', 'deploymentId', deploymentId)
            const localVarPath = `/mgmt/tenant/config/{deployment-id}`
                .replace(`{${"deployment-id"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a data source
         * @param {string} datasourceId 
         * @param {DatasourceConfig} datasourceConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataSource: async (datasourceId: string, datasourceConfig: DatasourceConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasourceId' is not null or undefined
            assertParamExists('updateDataSource', 'datasourceId', datasourceId)
            // verify required parameter 'datasourceConfig' is not null or undefined
            assertParamExists('updateDataSource', 'datasourceConfig', datasourceConfig)
            const localVarPath = `/datasources/{datasource_id}`
                .replace(`{${"datasource_id"}}`, encodeURIComponent(String(datasourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasourceConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update general settings
         * @param {TenantGeneralSettingsUpdate} tenantGeneralSettingsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralSettings: async (tenantGeneralSettingsUpdate: TenantGeneralSettingsUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantGeneralSettingsUpdate' is not null or undefined
            assertParamExists('updateGeneralSettings', 'tenantGeneralSettingsUpdate', tenantGeneralSettingsUpdate)
            const localVarPath = `/tenants/config/general-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantGeneralSettingsUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update note
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {string} noteId Note ID
         * @param {UpdateNoteRequest} updateNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (entityType: number, entityId: string, noteId: string, updateNoteRequest: UpdateNoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('updateNote', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('updateNote', 'entityId', entityId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateNote', 'noteId', noteId)
            // verify required parameter 'updateNoteRequest' is not null or undefined
            assertParamExists('updateNote', 'updateNoteRequest', updateNoteRequest)
            const localVarPath = `/entities/{entity-type}/{entity-id}/notes/{note-id}`
                .replace(`{${"entity-type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"note-id"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update preference for provided id
         * @param {string} entityId Entity Id
         * @param {string} preferenceId Preference Id
         * @param {Preference} preference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreference: async (entityId: string, preferenceId: string, preference: Preference, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('updatePreference', 'entityId', entityId)
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updatePreference', 'preferenceId', preferenceId)
            // verify required parameter 'preference' is not null or undefined
            assertParamExists('updatePreference', 'preference', preference)
            const localVarPath = `/entities/{entity-id}/preferences/{preference-id}`
                .replace(`{${"entity-id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"preference-id"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preference, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a sensitive data config
         * @param {string} configId Sensitive Data Config ID
         * @param {SensitiveDataConfigUpdate} sensitiveDataConfigUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensitiveDataConfig: async (configId: string, sensitiveDataConfigUpdate: SensitiveDataConfigUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('updateSensitiveDataConfig', 'configId', configId)
            // verify required parameter 'sensitiveDataConfigUpdate' is not null or undefined
            assertParamExists('updateSensitiveDataConfig', 'sensitiveDataConfigUpdate', sensitiveDataConfigUpdate)
            const localVarPath = `/tenants/config/sensitive-settings/configs/{configId}`
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sensitiveDataConfigUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Request for approval for a use case involving a AI entity
         * @param {EntityUserRequest} entityUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRequestForEntity: async (entityUserRequest: EntityUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityUserRequest' is not null or undefined
            assertParamExists('updateUserRequestForEntity', 'entityUserRequest', entityUserRequest)
            const localVarPath = `/policies/governance/user_request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the user\'s role
         * @param {string} userId User Id
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole: async (userId: string, userUpdateRequest: UserUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserRole', 'userId', userId)
            // verify required parameter 'userUpdateRequest' is not null or undefined
            assertParamExists('updateUserRole', 'userUpdateRequest', userUpdateRequest)
            const localVarPath = `/users/{user_id}/role`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a CSV file for data sources
         * @param {File} [file] The CSV file to upload
         * @param {UploadCsvDataSourceCsvTypeEnum} [csvType] The type of CSV being uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCsvDataSource: async (file?: File, csvType?: UploadCsvDataSourceCsvTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (csvType !== undefined) { 
                localVarFormParams.append('csv_type', csvType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns True if the customer key is present in the secrets manager, else False
         * @summary Check if customer key is present
         * @param {string} secretName The name of the secret to check for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usingCustomerKey: async (secretName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('usingCustomerKey', 'secretName', secretName)
            const localVarPath = `/ai/secrets/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (secretName !== undefined) {
                localVarQueryParameter['secret_name'] = secretName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Browser Agent Health Check
         * @summary Health Check
         * @param {string} authorization 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentHealthCheck(authorization: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentHealthCheck(authorization, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.agentHealthCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Attach a tag to a resource
         * @param {Array<AttachTagRequestInner>} attachTagRequestInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachTag(attachTagRequestInner: Array<AttachTagRequestInner>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachTag(attachTagRequestInner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.attachTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Authenticate browser agent
         * @summary Authenticate browser agent
         * @param {BrowserAgentAuthRequest} [browserAgentAuthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async browserAgentAuth(browserAgentAuthRequest?: BrowserAgentAuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowserAgentAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.browserAgentAuth(browserAgentAuthRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.browserAgentAuth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get LLM Lineage
         * @param {LLMLineageRequest} lLMLineageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentsLlmLineagePost(lLMLineageRequest: LLMLineageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LLMLineageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentsLlmLineagePost(lLMLineageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.componentsLlmLineagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new collector
         * @param {CreateCollectorRequest} createCollectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCollector(createCollectorRequest: CreateCollectorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCollector201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCollector(createCollectorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCollector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate collector deployment file for kubernetes
         * @param {CreateCollectorK8sClusterRequest} createCollectorK8sClusterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCollectorK8sCluster(createCollectorK8sClusterRequest: CreateCollectorK8sClusterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCollectorK8sCluster(createCollectorK8sClusterRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCollectorK8sCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create note
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {CreateNoteRequest} createNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNote(entityType: number, entityId: string, createNoteRequest: CreateNoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNote(entityType, entityId, createNoteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create or link jira ticket
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {JiraRequest} jiraRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrLinkJiraTicket(entityType: number, entityId: string, jiraRequest: JiraRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JiraDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrLinkJiraTicket(entityType, entityId, jiraRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOrLinkJiraTicket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create jira config
         * @param {JiraConfig} jiraConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateJiraConfig(jiraConfig: JiraConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JiraConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateJiraConfig(jiraConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOrUpdateJiraConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a PII data storage config
         * @param {PiiConfig} piiConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdatePiiConfig(piiConfig: PiiConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiiConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdatePiiConfig(piiConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOrUpdatePiiConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create or update settings
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {SettingsObject} settingsObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateSettings(entityType: number, entityId: string, settingsObject: SettingsObject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateSettings(entityType, entityId, settingsObject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOrUpdateSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a SMTP data storage config
         * @param {EmailConfig} emailConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateSmtpConfig(emailConfig: EmailConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateSmtpConfig(emailConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOrUpdateSmtpConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create policy based on onboarding user request
         * @param {UserRequestPolicyRequest} userRequestPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPolicyForUserRequest(userRequestPolicyRequest: UserRequestPolicyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPolicyForUserRequest(userRequestPolicyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createPolicyForUserRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create preference for provided entity
         * @param {string} entityId Entity Id
         * @param {Preference} preference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreference(entityId: string, preference: Preference, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Preference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPreference(entityId, preference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a sensitive data config
         * @param {SensitiveDataConfigCreate} sensitiveDataConfigCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSensitiveDataConfig(sensitiveDataConfigCreate: SensitiveDataConfigCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensitiveDataConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSensitiveDataConfig(sensitiveDataConfigCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createSensitiveDataConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new tag / Update existing tag with new values if tag already exists;
         * @param {NewTag} newTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(newTag: NewTag, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(newTag, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new tenant
         * @param {CreateTenantRequest} createTenantRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(createTenantRequest: CreateTenantRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(createTenantRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createTenant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create User Request for approval for a use case involving a AI entity
         * @param {EntityUserRequest} entityUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserRequestForEntity(entityUserRequest: EntityUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserRequestForEntity(entityUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createUserRequestForEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Start a datasource
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasourcesDatasourceIdStartPost(datasourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasourcesDatasourceIdStartPost(datasourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.datasourcesDatasourceIdStartPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Stop a datasource
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasourcesDatasourceIdStopPost(datasourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasourcesDatasourceIdStopPost(datasourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.datasourcesDatasourceIdStopPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new data source
         * @param {DatasourceConfig} datasourceConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasourcesPost(datasourceConfig: DatasourceConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasourcesPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasourcesPost(datasourceConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.datasourcesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete collector
         * @param {string} id Collector ID to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCollector(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCollector(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteCollector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a data source
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataSource(datasourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataSource(datasourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteDataSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete jira config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJiraConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJiraConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteJiraConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete jira link for the entity.
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJiraLink(entityType: number, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJiraLink(entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteJiraLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete note for ID.
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {string} noteId Note ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(entityType: number, entityId: string, noteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(entityType, entityId, noteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete preference for provided id
         * @param {string} entityId Entity Id
         * @param {string} preferenceId Preference Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePreference(entityId: string, preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePreference(entityId, preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deletePreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete User request
         * @param {DeleteUserRequestRequest} deleteUserRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRequest(deleteUserRequestRequest: DeleteUserRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRequest(deleteUserRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.deleteUserRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Detach a tag from a resource
         * @param {Array<DetachTag>} detachTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachTag(detachTag: Array<DetachTag>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachTag(detachTag, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.detachTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Disable a policy
         * @param {string} policyId Policy Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disablePolicy(policyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disablePolicy(policyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.disablePolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Download a CSV file for a data source
         * @param {string} csvConfigId ID of the data source configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCsvDataSource(csvConfigId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCsvDataSource(csvConfigId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.downloadCsvDataSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Enable Browser Agent
         * @summary Enable Browser Agent
         * @param {EnableBrowserAgentRequest} [enableBrowserAgentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableBrowserAgent(enableBrowserAgentRequest?: EnableBrowserAgentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnableBrowserAgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableBrowserAgent(enableBrowserAgentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.enableBrowserAgent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enable a policy
         * @param {string} policyId Policy Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enablePolicy(policyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enablePolicy(policyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.enablePolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List events
         * @param {EventType} [eventType] Event entity types to filter
         * @param {Array<string>} [entityType] Event entity types to filter
         * @param {Array<string>} [anchorEntityTypes] Anchor entity types to filter
         * @param {Array<string>} [anchorEntityIds] Anchor entity ids to filter
         * @param {EventStatus} [eventStatus] Event status to filter
         * @param {Array<EventSeverity>} [eventSeverities] Event severity to filter
         * @param {Array<string>} [categories] Event category to filter
         * @param {number} [complianceType] Compliance Type to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsGet(eventType?: EventType, entityType?: Array<string>, anchorEntityTypes?: Array<string>, anchorEntityIds?: Array<string>, eventStatus?: EventStatus, eventSeverities?: Array<EventSeverity>, categories?: Array<string>, complianceType?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsGet(eventType, entityType, anchorEntityTypes, anchorEntityIds, eventStatus, eventSeverities, categories, complianceType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.eventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Export data into CSV
         * @param {CsvExportRequest} csvExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCsv(csvExportRequest: CsvExportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCsv(csvExportRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.exportCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Action Metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionMetadata(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionMetadata(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getActionMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Meta Info for AI Service
         * @param {string} [aiServiceDefinitionId] ai_service_definition_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiServiceMetaInfo(aiServiceDefinitionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAiServiceMetaInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiServiceMetaInfo(aiServiceDefinitionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAiServiceMetaInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ai Service Recommendation
         * @param {string} [aiServiceDefinitionId] ai_service_definition_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiServiceRecommendation(aiServiceDefinitionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiServiceRecommendation(aiServiceDefinitionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAiServiceRecommendation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all compute instance rate records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllComputeInstanceRateRecords(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllComputeInstanceRateRecords200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllComputeInstanceRateRecords(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllComputeInstanceRateRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all uploaded CSV files config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCsvUploads(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllCsvUploads200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCsvUploads(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllCsvUploads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get feature flags
         * @param {boolean} [enabledOnly] Filter feature flags by their enabled state
         * @param {boolean} [computedOnly] Only return computed Filter feature flags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFeatureFlags(enabledOnly?: boolean, computedOnly?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllFeatureFlags200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFeatureFlags(enabledOnly, computedOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllFeatureFlags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all Managed LLM Inference rate cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllManagedLLMInferenceRecords(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllManagedLLMInferenceRecords200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllManagedLLMInferenceRecords(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllManagedLLMInferenceRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all raw events
         * @param {number} [startTime] Filter events from this timestamp (inclusive)
         * @param {number} [endTime] Filter events up to this timestamp (exclusive)
         * @param {Array<string>} [aiServiceId] Filter events based on AI service reference
         * @param {Array<string>} [userId] Filter events based on user reference
         * @param {Array<string>} [machineId] Filter events based on machine reference
         * @param {Array<GetAllRawEventsSourceNamesEnum>} [sourceNames] Filter events based on source name (e.g., code42, Singulr Browser Extension, cortex)
         * @param {Array<GetAllRawEventsEventTypesEnum>} [eventTypes] Filter events based on event type
         * @param {string} [pageCursor] Page cursor for pagination
         * @param {number} [limit] The number of results per page (for pagination)
         * @param {number} [aggregationBucketSecs] Aggregation bucket size in seconds for ai service access events
         * @param {GetAllRawEventsSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRawEvents(startTime?: number, endTime?: number, aiServiceId?: Array<string>, userId?: Array<string>, machineId?: Array<string>, sourceNames?: Array<GetAllRawEventsSourceNamesEnum>, eventTypes?: Array<GetAllRawEventsEventTypesEnum>, pageCursor?: string, limit?: number, aggregationBucketSecs?: number, sort?: GetAllRawEventsSortEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllRawEvents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRawEvents(startTime, endTime, aiServiceId, userId, machineId, sourceNames, eventTypes, pageCursor, limit, aggregationBucketSecs, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllRawEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all roles available to users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all Vector DB Rate cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVectorDBRecords(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllVectorDBRecords200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVectorDBRecords(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAllVectorDBRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Provide annotation aggregations for list of content ids
         * @param {AnnotationAggregationTypeEnum} [aggregateType] Pass true to get aggregates of values. Default behaviour return key aggregates
         * @param {Array<string>} [sourceIds] list of source id to filter
         * @param {Array<string>} [destinationIds] list of destination id to filter
         * @param {Array<string>} [linkedContentIds] list of linked content ids to filter
         * @param {Array<string>} [namedEntityTypes] type of named entities in annotations
         * @param {Array<string>} [namedEntityValues] values of named entities in annotations
         * @param {number} [startTime] time range filter
         * @param {number} [endTime] time range filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationAggregation(aggregateType?: AnnotationAggregationTypeEnum, sourceIds?: Array<string>, destinationIds?: Array<string>, linkedContentIds?: Array<string>, namedEntityTypes?: Array<string>, namedEntityValues?: Array<string>, startTime?: number, endTime?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationAggregate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationAggregation(aggregateType, sourceIds, destinationIds, linkedContentIds, namedEntityTypes, namedEntityValues, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAnnotationAggregation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get filtered annotation list
         * @param {Array<string>} namedEntityTypes type of named entities
         * @param {number} offset offset index for pagination
         * @param {number} limit page limit for pagination
         * @param {Array<string>} [namedEntityValues] values of named entities
         * @param {Array<string>} [contentIds] content ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationList(namedEntityTypes: Array<string>, offset: number, limit: number, namedEntityValues?: Array<string>, contentIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnnotationList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationList(namedEntityTypes, offset, limit, namedEntityValues, contentIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAnnotationList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch annotation details using content id
         * @param {string} contentId Id of content
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationsOfContent(contentId: string, contentEntityType: ContentTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationsOfContent(contentId, contentEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAnnotationsOfContent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the policies applied to an entity type + policy type
         * @param {string} entityType Entity type
         * @param {string} policyType Policy type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationStatusForEntityTypeAndPolicyType(entityType: string, policyType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityAppliedPolicy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationStatusForEntityTypeAndPolicyType(entityType, policyType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getApplicationStatusForEntityTypeAndPolicyType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get audit events
         * @param {GetAuditEventsCategoryEnum} category Category of audit events.
         * @param {Array<number>} [entityType] Entity Type
         * @param {number} [startTime] Start time filter
         * @param {number} [endTime] End time filter
         * @param {GetAuditEventsOperationEnum} [operation] Operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditEvents(category: GetAuditEventsCategoryEnum, entityType?: Array<number>, startTime?: number, endTime?: number, operation?: GetAuditEventsOperationEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditEvents(category, entityType, startTime, endTime, operation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAuditEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all collectors
         * @param {string} [id] Collector ID to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectors(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCollectors200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectors(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getCollectors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get infra configurations
         * @summary Get infra configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigDetail(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigDetail(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConfigDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get infra configurations
         * @summary Get infra configurations
         * @param {string} deploymentId Deployment Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigDetailsByDeployment(deploymentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigDetailsByDeployment(deploymentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConfigDetailsByDeployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get content facets
         * @param {ContentFacetTypeEnum} facetProperty Facet on this property
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {Array<string>} [sourceIds] list of source id to filter
         * @param {Array<string>} [destinationIds] list of destination id to filter
         * @param {Array<string>} [linkedContentIds] list of linked content ids to filter
         * @param {Array<string>} [namedEntityTypes] type of named entities in annotations
         * @param {Array<string>} [namedEntityValues] values of named entities in annotations
         * @param {number} [startTime] time range filter
         * @param {number} [endTime] time range filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentFacets(facetProperty: ContentFacetTypeEnum, contentEntityType: ContentTypeEnum, sourceIds?: Array<string>, destinationIds?: Array<string>, linkedContentIds?: Array<string>, namedEntityTypes?: Array<string>, namedEntityValues?: Array<string>, startTime?: number, endTime?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContentFacets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentFacets(facetProperty, contentEntityType, sourceIds, destinationIds, linkedContentIds, namedEntityTypes, namedEntityValues, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getContentFacets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch content detail using id
         * @param {string} contentId Id of content
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentFromId(contentId: string, contentEntityType: ContentTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentFromId(contentId, contentEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getContentFromId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of content
         * @param {ContentTypeEnum} contentEntityType Entity type of content
         * @param {number} offset offset index for pagination
         * @param {number} limit page limit for pagination
         * @param {Array<string>} [sourceIds] list of source id to filter
         * @param {Array<string>} [destinationIds] list of destination id to filter
         * @param {Array<string>} [linkedContentIds] list of linked content ids to filter
         * @param {Array<string>} [namedEntityTypes] type of named entities in annotations
         * @param {Array<string>} [namedEntityValues] values of named entities in annotations
         * @param {number} [startTime] time range filter
         * @param {number} [endTime] time range filter
         * @param {GetContentListSortOrderEnum} [sortOrder] Define the order of content. Sorting parameter is always time of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentList(contentEntityType: ContentTypeEnum, offset: number, limit: number, sourceIds?: Array<string>, destinationIds?: Array<string>, linkedContentIds?: Array<string>, namedEntityTypes?: Array<string>, namedEntityValues?: Array<string>, startTime?: number, endTime?: number, sortOrder?: GetContentListSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContentList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentList(contentEntityType, offset, limit, sourceIds, destinationIds, linkedContentIds, namedEntityTypes, namedEntityValues, startTime, endTime, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getContentList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get schema for core entity type.
         * @param {string} entityType Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoreEntityTypeSchema(entityType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityTypeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoreEntityTypeSchema(entityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getCoreEntityTypeSchema']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get rate card records by filter and group
         * @param {FilterAndGroupRateCardRequest} filterAndGroupRateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostRecordsByFilterAndGroup(filterAndGroupRateCardRequest: FilterAndGroupRateCardRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterAndGroupRateCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostRecordsByFilterAndGroup(filterAndGroupRateCardRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getCostRecordsByFilterAndGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a data source
         * @param {string} datasourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSource(datasourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasourceConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSource(datasourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getDataSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all data sources
         * @param {string} [collectorId] Collector ID to filter
         * @param {string} [datasourceType] Type of data source AWS_INVENTORY, AWS_FLOW_LOGS, AWS_ROUTE53, AWS_EVENT_ACCESS_LOGS, AWS_SAGEMAKER, GCP_INVENTORY, GCP_FLOW_LOGS, GCP_CLOUD_DNS, GCP_EVENT_ACCESS_LOGS, GCP_VERTEX_AI
         * @param {string} [vendor] Vendor name to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSources(collectorId?: string, datasourceType?: string, vendor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDataSources200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSources(collectorId, datasourceType, vendor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getDataSources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity facets
         * @param {number} entityType Entity Type
         * @param {string} facetProperty Property to fetch facets
         * @param {boolean} [includeCount] Return facet count
         * @param {string} [queryId] Query Id to limit facets
         * @param {GetFacetDataParam} [getFacetDataParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityFacets(entityType: number, facetProperty: string, includeCount?: boolean, queryId?: string, getFacetDataParam?: GetFacetDataParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacetData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityFacets(entityType, facetProperty, includeCount, queryId, getFacetDataParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityFacets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * supported mappings ip-> app ; ip->workload; app->ip; workload->ip
         * @summary Get entity mappings
         * @param {number} entityType Entity Type
         * @param {number} mapToEntityType Entity Type to map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityMappings(entityType: number, mapToEntityType: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityMappingData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityMappings(entityType, mapToEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityMappings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity mappings by reference object
         * @param {number} destEntityType Entity Type
         * @param {Array<EntityReference>} entityReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityMappingsByReference(destEntityType: number, entityReference: Array<EntityReference>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityMappingData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityMappingsByReference(destEntityType, entityReference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityMappingsByReference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity data
         * @param {number} entityType Entity Type
         * @param {string} queryId Query id to fetch data
         * @param {GetEntityDataParam} getEntityDataParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityPage(entityType: number, queryId: string, getEntityDataParam: GetEntityDataParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityPage(entityType, queryId, getEntityDataParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get entity trend metrics according to provided filters
         * @param {EntityTrendRequest} [entityTrendRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityTrend(entityTrendRequest?: EntityTrendRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricSeriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityTrend(entityTrendRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get schema for UI entity type.
         * @param {string} entityType Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityTypeSchema(entityType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityTypeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityTypeSchema(entityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityTypeSchema']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get schema for UI enum types.
         * @param {string} enumType Enum type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnumTypeSchema(enumType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnumTypeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnumTypeSchema(enumType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEnumTypeSchema']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Event Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEventCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get events facets
         * @param {EventFacetTypeEnum} facetProperty Facet on this property
         * @param {EventType} [eventType] Event entity types to filter
         * @param {Array<string>} [entityType] Event entity types to filter
         * @param {Array<string>} [anchorEntityTypes] Anchor entity types to filter
         * @param {Array<string>} [anchorEntityIds] Anchor entity ids to filter
         * @param {EventStatus} [eventStatus] Event status to filter
         * @param {Array<EventSeverity>} [eventSeverities] Event severity to filter
         * @param {Array<string>} [categories] Event category to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventFacets(facetProperty: EventFacetTypeEnum, eventType?: EventType, entityType?: Array<string>, anchorEntityTypes?: Array<string>, anchorEntityIds?: Array<string>, eventStatus?: EventStatus, eventSeverities?: Array<EventSeverity>, categories?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventReferenceFacets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventFacets(facetProperty, eventType, entityType, anchorEntityTypes, anchorEntityIds, eventStatus, eventSeverities, categories, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEventFacets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Event Severity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventSeverities(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventSeverities(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEventSeverities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Event to Content Navigation Metadata
         * @param {NavigationRequests} navigationRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventToContentNavigationMetadata(navigationRequests: NavigationRequests, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationResponses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventToContentNavigationMetadata(navigationRequests, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEventToContentNavigationMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get general settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantGeneralSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralSettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getGeneralSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get jira config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJiraConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JiraConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJiraConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getJiraConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get lifecycle events
         * @param {number} [entityType] Entity Type
         * @param {string} [entityId] Entity id to fetch logs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLifecycleEvents(entityType?: number, entityId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LifecycleEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLifecycleEvents(entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getLifecycleEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a jira linked to this entity
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkedJiraTicket(entityType: number, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JiraDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkedJiraTicket(entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getLinkedJiraTicket']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of Machine Events
         * @param {MachineEventRequest} [machineEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMachineEventsList(machineEventRequest?: MachineEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMachineEventsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMachineEventsList(machineEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMachineEventsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of Machine Events
         * @param {MachineEventTimeSeriesRequest} [machineEventTimeSeriesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMachineEventsListTimeSeries(machineEventTimeSeriesRequest?: MachineEventTimeSeriesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMachineEventsListTimeSeries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMachineEventsListTimeSeries(machineEventTimeSeriesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMachineEventsListTimeSeries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get metric schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricSchema(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MetricSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricSchema(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMetricSchema']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get metric series
         * @param {MetricSeriesQueryRequest} [metricSeriesQueryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricSeries(metricSeriesQueryRequest?: MetricSeriesQueryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricSeriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricSeries(metricSeriesQueryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMetricSeries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get metric series for AI service (Automatically chooses the fq metric)
         * @param {MetricSeriesQueryRequest} [metricSeriesQueryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricSeriesForAIService(metricSeriesQueryRequest?: MetricSeriesQueryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricSeriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricSeriesForAIService(metricSeriesQueryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMetricSeriesForAIService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get metric tags
         * @param {MetricTagListRequest} [metricTagListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricTagValues(metricTagListRequest?: MetricTagListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MetricTagListInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricTagValues(metricTagListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMetricTagValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a note
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {string} noteId Note ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteById(entityType: number, entityId: string, noteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteById(entityType, entityId, noteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getNoteById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all items which are currently onboarded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnboardedItems(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityRef>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnboardedItems(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getOnboardedItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get activity related info for items which are currently onboarded
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnboardedItemsActivity(entityRef: Array<EntityRef>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityActivityInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnboardedItemsActivity(entityRef, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getOnboardedItemsActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get header info items which are currently onboarded like risk
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnboardedItemsHeaderInfo(entityRef: Array<EntityRef>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityHeaderInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnboardedItemsHeaderInfo(entityRef, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getOnboardedItemsHeaderInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the current user\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrCreateUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrCreateUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getOrCreateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get PII data storage config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPiiConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiiConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPiiConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPiiConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Policy
         * @summary Get Policy
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolices(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolices(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPolices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get possible values of attributes of the entity type used in policy
         * @param {string} applicableEntityType Applicable entity type for this policy type.
         * @param {string} [attributeName] Attribute name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicyAttributeValues(applicableEntityType: string, attributeName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttributeValuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyAttributeValues(applicableEntityType, attributeName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPolicyAttributeValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all policy types and their supported entity types and properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicyEntityTypeProperties(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyTypePropertyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyEntityTypeProperties(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPolicyEntityTypeProperties']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get preference for provided id
         * @param {string} entityId Entity Id
         * @param {string} preferenceId Preference Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreference(entityId: string, preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Preference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreference(entityId, preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all preferences for provided entity
         * @param {string} entityId Entity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreferences(entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Preference>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreferences(entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get recommendation for Entity ID.
         * @param {string} entityId Entity ID
         * @param {string} entityType Entity Type
         * @param {string} deploymentEntityType Deployment Entity Type
         * @param {number} startTime Start time
         * @param {number} endTime End time
         * @param {string} [entityName] Entity Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendationCostForEntity(entityId: string, entityType: string, deploymentEntityType: string, startTime: number, endTime: number, entityName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecommendationData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendationCostForEntity(entityId, entityType, deploymentEntityType, startTime, endTime, entityName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getRecommendationCostForEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all regions
         * @param {string} vendorName Vendor name to filter
         * @param {string} [regionSubtype] Region sub-type to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegions(vendorName: string, regionSubtype?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetRegions200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(vendorName, regionSubtype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getRegions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Runtime Config
         * @summary Get Runtime Config
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRuntimeConfig(agentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRuntimeConfig(agentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getRuntimeConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get savings cost for Entity Group.
         * @param {string} entityGroupType Entity Group Type
         * @param {number} startTime Start time
         * @param {number} endTime End time
         * @param {Array<string>} [entityGroupNames] List of Entity Group Names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavingsCostForEntityGroup(entityGroupType: string, startTime: number, endTime: number, entityGroupNames?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavingsData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavingsCostForEntityGroup(entityGroupType, startTime, endTime, entityGroupNames, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSavingsCostForEntityGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get sensitive data settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensitiveSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensitiveDataSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensitiveSettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSensitiveSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get settings
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(entityType: number, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get settings
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsConfig(entityType: number, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsConfigObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsConfig(entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSettingsConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Email SMTP data storage config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSmtpConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSmtpConfig(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSmtpConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get app, workload, or library linked vulnerabilities
         * @param {number} entityType Entity Type
         * @param {string} entityId Entity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSoftwareVulnerabilities(entityType: number, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LibraryCveList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSoftwareVulnerabilities(entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSoftwareVulnerabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedDatasources(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSupportedDatasources200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedDatasources(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSupportedDatasources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get supported preference types for provided id
         * @param {number} entityType Entity Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedPreferences(entityType: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupportedPreferenceItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedPreferences(entityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSupportedPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenants(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tenant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenants(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getTenants']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Is a topic approved, unapproved or unclear based on closest, already-existing policy topics
         * @summary approval status of topics - approved/unapproved/unclear
         * @param {Topics} topics 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicsApprovalStatus(topics: Topics, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicPolicyStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicsApprovalStatus(topics, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getTopicsApprovalStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unverified AI Service
         * @param {GetEntityDataParam} getEntityDataParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnverifiedAiService(getEntityDataParam: GetEntityDataParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnverifiedAiService(getEntityDataParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getUnverifiedAiService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get usage cost for Entity ID.
         * @param {string} entityId List of entities
         * @param {string} entityType Entity Type
         * @param {number} startTime Start time
         * @param {number} endTime End time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsageCostForEntity(entityId: string, entityType: string, startTime: number, endTime: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsageCostData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsageCostForEntity(entityId, entityType, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getUsageCostForEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get usage cost for Entity Group.
         * @param {GroupUsageCostRequest} groupUsageCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsageCostForEntityGroup(groupUsageCostRequest: GroupUsageCostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupUsageCostData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsageCostForEntityGroup(groupUsageCostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getUsageCostForEntityGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Requests for an AI entity
         * @param {EntityRef} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRequestsForEntity(entityRef: EntityRef, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityUserRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRequestsForEntity(entityRef, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getUserRequestsForEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new graph
         * @param {string} entityType The type of the Entity
         * @param {string} entityId The ID of the Entity
         * @param {string} graphType The type of graph
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphEntityTypeEntityIdGraphTypeGet(entityType: string, entityId: string, graphType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphEntityTypeEntityIdGraphTypeGet(entityType, entityId, graphType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.graphEntityTypeEntityIdGraphTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ingest data
         * @summary Ingest data
         * @param {string} [authorization] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async ingestData(authorization?: string, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowserAgentIngestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ingestData(authorization, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.ingestData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List different builds for Browser Extension
         * @summary List different builds for Browser Extension
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBrowserAgentBuilds(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrowserAgentBuildListResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBrowserAgentBuilds(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listBrowserAgentBuilds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List different builds for Browser Extension
         * @summary List different builds for Browser Extension
         * @param {number} start 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBrowserAgents(start: number, limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrowserAgentResponsePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBrowserAgents(start, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listBrowserAgents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List schemas for concrete policy types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConcretePolicySchemas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityTypeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConcretePolicySchemas(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listConcretePolicySchemas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List schemas for core entity types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCoreEntityTypeSchemas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityTypeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCoreEntityTypeSchemas(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCoreEntityTypeSchemas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List schemas for UI entity types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEntityTypeSchemas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityTypeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEntityTypeSchemas(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listEntityTypeSchemas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List schema for UI enum types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEnumTypeSchemas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumTypeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEnumTypeSchemas(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listEnumTypeSchemas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List notes
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotes(entityType: number, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Note>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotes(entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get risk categories for risk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRiskCategory(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskCategoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRiskCategory(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listRiskCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all tags
         * @param {string} [key] Filter tags by key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTags(key?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTags(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all localized messages for the current language. The language can be specified using the `Accept-Language` header.
         * @summary Get all localized messages
         * @param {string} [acceptLanguage] Language for the messages (e.g., \&#39;en\&#39;, \&#39;es\&#39;, \&#39;fr\&#39;). Defaults to \&#39;en\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesGet(acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesGet(acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.messagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a localized message by providing the message key. The language can be specified using the `Accept-Language` header.
         * @summary Get a localized message by key
         * @param {string} key The key for the message to be retrieved.
         * @param {string} [acceptLanguage] Language for the message (e.g., \&#39;en\&#39;, \&#39;es\&#39;, \&#39;fr\&#39;). Defaults to \&#39;en\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesKeyGet(key: string, acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagesKeyGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesKeyGet(key, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.messagesKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Offboard a set of supported entities
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offboardEntities(entityRef: Array<EntityRef>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offboardEntities(entityRef, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.offboardEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Onboard a set of supported entities
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardEntities(entityRef: Array<EntityRef>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardEntities(entityRef, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.onboardEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the policies applied to an entity and its surrounding context
         * @param {Array<EntityRef>} entityRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesApplicationStatusPost(entityRef: Array<EntityRef>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityAppliedPolicy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesApplicationStatusPost(entityRef, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesApplicationStatusPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List State Transitions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesApprovalStatusStateTransitionsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StateTransition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesApprovalStatusStateTransitionsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesApprovalStatusStateTransitionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Approve a set of supported entities
         * @param {EntityRefs} entityRefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesApprovePost(entityRefs: EntityRefs, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesApprovePost(entityRefs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesApprovePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List policies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PolicyData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete policy for ID.
         * @param {string} policyId Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesPolicyIdDelete(policyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesPolicyIdDelete(policyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesPolicyIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get policy for ID.
         * @param {string} policyId Policy ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesPolicyIdGet(policyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesPolicyIdGet(policyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesPolicyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a policy
         * @param {string} policyId Policy ID
         * @param {PolicyData} policyData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesPolicyIdPut(policyId: string, policyData: PolicyData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesPolicyIdPut(policyId, policyData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesPolicyIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new Policy
         * @param {PolicyData} policyData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesPost(policyData: PolicyData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesPost(policyData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the approval status of a set of supported entities by property values
         * @param {ApprovalStatusChange} approvalStatusChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesPropertyApprovalStatusChangePost(approvalStatusChange: ApprovalStatusChange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesPropertyApprovalStatusChangePost(approvalStatusChange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesPropertyApprovalStatusChangePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Approve a set of supported entities by property values
         * @param {PropertyValues} propertyValues 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesPropertyApprovePost(propertyValues: PropertyValues, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesPropertyApprovePost(propertyValues, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesPropertyApprovePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Un-approve a set of supported entities by property values
         * @param {PropertyValues} propertyValues 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesPropertyUnapprovePost(propertyValues: PropertyValues, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesPropertyUnapprovePost(propertyValues, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesPropertyUnapprovePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Un-approve a set of supported entities
         * @param {EntityRefs} entityRefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesUnapprovePost(entityRefs: EntityRefs, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesUnapprovePost(entityRefs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policiesUnapprovePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List policy contexts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyContextsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PolicyContextData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyContextsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policyContextsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get policy context for ID.
         * @param {string} policyCtxId Policy Context ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyContextsPolicyCtxIdGet(policyCtxId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyContextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyContextsPolicyCtxIdGet(policyCtxId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policyContextsPolicyCtxIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get policy suggestions translated from a policy context using ID.
         * @param {string} policyCtxId Policy Context ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyContextsPolicyCtxIdPolicySuggestionsGet(policyCtxId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PolicyData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyContextsPolicyCtxIdPolicySuggestionsGet(policyCtxId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policyContextsPolicyCtxIdPolicySuggestionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a policy context
         * @param {string} policyCtxId Policy Context ID
         * @param {PolicyContextData} policyContextData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyContextsPolicyCtxIdPut(policyCtxId: string, policyContextData: PolicyContextData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyContextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyContextsPolicyCtxIdPut(policyCtxId, policyContextData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policyContextsPolicyCtxIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new Policy Context
         * @param {PolicyContextData} policyContextData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyContextsPost(policyContextData: PolicyContextData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyContextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyContextsPost(policyContextData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policyContextsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get preview of provided summary
         * @param {boolean} conditionMetFlag Condition Met Flag false indicates negation of condition
         * @param {PolicyData} policyData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyPreview(conditionMetFlag: boolean, policyData: PolicyData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyPreviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyPreview(conditionMetFlag, policyData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policyPreview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get summary of defined policies
         * @param {PolicySummaryRequest} policySummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policySummary(policySummaryRequest: PolicySummaryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policySummary(policySummaryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.policySummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Register User using browser agent
         * @summary Register User using browser agent
         * @param {RegisterUserRequest} [registerUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(registerUserRequest?: RegisterUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(registerUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.registerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Adds/updates the provided secrets to the secrets manager
         * @summary Set Update Secrets
         * @param {CustomerSecretInput} customerSecretInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUpdateSecrets(customerSecretInput: CustomerSecretInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUpdateSecrets(customerSecretInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.setUpdateSecrets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Change infra configurations
         * @summary Change infra configurations
         * @param {UpdateConfigRequest} [updateConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfig(updateConfigRequest?: UpdateConfigRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfig(updateConfigRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Change infra configurations
         * @summary Change infra configurations
         * @param {string} deploymentId Deployment Id
         * @param {UpdateConfigRequest} [updateConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfigByDeployment(deploymentId: string, updateConfigRequest?: UpdateConfigRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfigByDeployment(deploymentId, updateConfigRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateConfigByDeployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a data source
         * @param {string} datasourceId 
         * @param {DatasourceConfig} datasourceConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataSource(datasourceId: string, datasourceConfig: DatasourceConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataSource(datasourceId, datasourceConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateDataSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update general settings
         * @param {TenantGeneralSettingsUpdate} tenantGeneralSettingsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralSettings(tenantGeneralSettingsUpdate: TenantGeneralSettingsUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralSettings(tenantGeneralSettingsUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateGeneralSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update note
         * @param {number} entityType Entity type
         * @param {string} entityId Entity ID
         * @param {string} noteId Note ID
         * @param {UpdateNoteRequest} updateNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(entityType: number, entityId: string, noteId: string, updateNoteRequest: UpdateNoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(entityType, entityId, noteId, updateNoteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update preference for provided id
         * @param {string} entityId Entity Id
         * @param {string} preferenceId Preference Id
         * @param {Preference} preference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreference(entityId: string, preferenceId: string, preference: Preference, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Preference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreference(entityId, preferenceId, preference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updatePreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a sensitive data config
         * @param {string} configId Sensitive Data Config ID
         * @param {SensitiveDataConfigUpdate} sensitiveDataConfigUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSensitiveDataConfig(configId: string, sensitiveDataConfigUpdate: SensitiveDataConfigUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSensitiveDataConfig(configId, sensitiveDataConfigUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateSensitiveDataConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User Request for approval for a use case involving a AI entity
         * @param {EntityUserRequest} entityUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRequestForEntity(entityUserRequest: EntityUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRequestForEntity(entityUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateUserRequestForEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the user\'s role
         * @param {string} userId User Id
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRole(userId: string, userUpdateRequest: UserUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRole(userId, userUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateUserRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload a CSV file for data sources
         * @param {File} [file] The CSV file to upload
         * @param {UploadCsvDataSourceCsvTypeEnum} [csvType] The type of CSV being uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCsvDataSource(file?: File, csvType?: UploadCsvDataSourceCsvTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadCsvDataSource200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCsvDataSource(file, csvType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.uploadCsvDataSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns True if the customer key is present in the secrets manager, else False
         * @summary Check if customer key is present
         * @param {string} secretName The name of the secret to check for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usingCustomerKey(secretName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usingCustomerKey(secretName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usingCustomerKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Browser Agent Health Check
         * @summary Health Check
         * @param {DefaultApiAgentHealthCheckRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentHealthCheck(requestParameters: DefaultApiAgentHealthCheckRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.agentHealthCheck(requestParameters.authorization, requestParameters.version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attach a tag to a resource
         * @param {DefaultApiAttachTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTag(requestParameters: DefaultApiAttachTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.attachTag(requestParameters.attachTagRequestInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Authenticate browser agent
         * @summary Authenticate browser agent
         * @param {DefaultApiBrowserAgentAuthRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browserAgentAuth(requestParameters: DefaultApiBrowserAgentAuthRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BrowserAgentAuthResponse> {
            return localVarFp.browserAgentAuth(requestParameters.browserAgentAuthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get LLM Lineage
         * @param {DefaultApiComponentsLlmLineagePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsLlmLineagePost(requestParameters: DefaultApiComponentsLlmLineagePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<LLMLineageResponse> {
            return localVarFp.componentsLlmLineagePost(requestParameters.lLMLineageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new collector
         * @param {DefaultApiCreateCollectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollector(requestParameters: DefaultApiCreateCollectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateCollector201Response> {
            return localVarFp.createCollector(requestParameters.createCollectorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate collector deployment file for kubernetes
         * @param {DefaultApiCreateCollectorK8sClusterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCollectorK8sCluster(requestParameters: DefaultApiCreateCollectorK8sClusterRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.createCollectorK8sCluster(requestParameters.createCollectorK8sClusterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create note
         * @param {DefaultApiCreateNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(requestParameters: DefaultApiCreateNoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.createNote(requestParameters.entityType, requestParameters.entityId, requestParameters.createNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or link jira ticket
         * @param {DefaultApiCreateOrLinkJiraTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrLinkJiraTicket(requestParameters: DefaultApiCreateOrLinkJiraTicketRequest, options?: RawAxiosRequestConfig): AxiosPromise<JiraDetails> {
            return localVarFp.createOrLinkJiraTicket(requestParameters.entityType, requestParameters.entityId, requestParameters.jiraRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create jira config
         * @param {DefaultApiCreateOrUpdateJiraConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateJiraConfig(requestParameters: DefaultApiCreateOrUpdateJiraConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<JiraConfig> {
            return localVarFp.createOrUpdateJiraConfig(requestParameters.jiraConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a PII data storage config
         * @param {DefaultApiCreateOrUpdatePiiConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePiiConfig(requestParameters: DefaultApiCreateOrUpdatePiiConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<PiiConfig> {
            return localVarFp.createOrUpdatePiiConfig(requestParameters.piiConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update settings
         * @param {DefaultApiCreateOrUpdateSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSettings(requestParameters: DefaultApiCreateOrUpdateSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SettingsObject> {
            return localVarFp.createOrUpdateSettings(requestParameters.entityType, requestParameters.entityId, requestParameters.settingsObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a SMTP data storage config
         * @param {DefaultApiCreateOrUpdateSmtpConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSmtpConfig(requestParameters: DefaultApiCreateOrUpdateSmtpConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<EmailConfig> {
            return localVarFp.createOrUpdateSmtpConfig(requestParameters.emailConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create policy based on onboarding user request
         * @param {DefaultApiCreatePolicyForUserRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPolicyForUserRequest(requestParameters: DefaultApiCreatePolicyForUserRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyData> {
            return localVarFp.createPolicyForUserRequest(requestParameters.userRequestPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create preference for provided entity
         * @param {DefaultApiCreatePreferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreference(requestParameters: DefaultApiCreatePreferenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Preference> {
            return localVarFp.createPreference(requestParameters.entityId, requestParameters.preference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a sensitive data config
         * @param {DefaultApiCreateSensitiveDataConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSensitiveDataConfig(requestParameters: DefaultApiCreateSensitiveDataConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<SensitiveDataConfig> {
            return localVarFp.createSensitiveDataConfig(requestParameters.sensitiveDataConfigCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new tag / Update existing tag with new values if tag already exists;
         * @param {DefaultApiCreateTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(requestParameters: DefaultApiCreateTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createTag(requestParameters.newTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new tenant
         * @param {DefaultApiCreateTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(requestParameters: DefaultApiCreateTenantRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tenant> {
            return localVarFp.createTenant(requestParameters.createTenantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User Request for approval for a use case involving a AI entity
         * @param {DefaultApiCreateUserRequestForEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserRequestForEntity(requestParameters: DefaultApiCreateUserRequestForEntityRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createUserRequestForEntity(requestParameters.entityUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a datasource
         * @param {DefaultApiDatasourcesDatasourceIdStartPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasourcesDatasourceIdStartPost(requestParameters: DefaultApiDatasourcesDatasourceIdStartPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.datasourcesDatasourceIdStartPost(requestParameters.datasourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop a datasource
         * @param {DefaultApiDatasourcesDatasourceIdStopPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasourcesDatasourceIdStopPost(requestParameters: DefaultApiDatasourcesDatasourceIdStopPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.datasourcesDatasourceIdStopPost(requestParameters.datasourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new data source
         * @param {DefaultApiDatasourcesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasourcesPost(requestParameters: DefaultApiDatasourcesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatasourcesPost201Response> {
            return localVarFp.datasourcesPost(requestParameters.datasourceConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete collector
         * @param {DefaultApiDeleteCollectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCollector(requestParameters: DefaultApiDeleteCollectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCollector(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a data source
         * @param {DefaultApiDeleteDataSourceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataSource(requestParameters: DefaultApiDeleteDataSourceRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDataSource(requestParameters.datasourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete jira config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJiraConfig(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteJiraConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete jira link for the entity.
         * @param {DefaultApiDeleteJiraLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJiraLink(requestParameters: DefaultApiDeleteJiraLinkRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteJiraLink(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete note for ID.
         * @param {DefaultApiDeleteNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(requestParameters: DefaultApiDeleteNoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteNote(requestParameters.entityType, requestParameters.entityId, requestParameters.noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete preference for provided id
         * @param {DefaultApiDeletePreferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreference(requestParameters: DefaultApiDeletePreferenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePreference(requestParameters.entityId, requestParameters.preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User request
         * @param {DefaultApiDeleteUserRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRequest(requestParameters: DefaultApiDeleteUserRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserRequest(requestParameters.deleteUserRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detach a tag from a resource
         * @param {DefaultApiDetachTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachTag(requestParameters: DefaultApiDetachTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.detachTag(requestParameters.detachTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable a policy
         * @param {DefaultApiDisablePolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disablePolicy(requestParameters: DefaultApiDisablePolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.disablePolicy(requestParameters.policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a CSV file for a data source
         * @param {DefaultApiDownloadCsvDataSourceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvDataSource(requestParameters: DefaultApiDownloadCsvDataSourceRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.downloadCsvDataSource(requestParameters.csvConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable Browser Agent
         * @summary Enable Browser Agent
         * @param {DefaultApiEnableBrowserAgentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableBrowserAgent(requestParameters: DefaultApiEnableBrowserAgentRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<EnableBrowserAgentResponse> {
            return localVarFp.enableBrowserAgent(requestParameters.enableBrowserAgentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable a policy
         * @param {DefaultApiEnablePolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enablePolicy(requestParameters: DefaultApiEnablePolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.enablePolicy(requestParameters.policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List events
         * @param {DefaultApiEventsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsGet(requestParameters: DefaultApiEventsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<EventListResponse> {
            return localVarFp.eventsGet(requestParameters.eventType, requestParameters.entityType, requestParameters.anchorEntityTypes, requestParameters.anchorEntityIds, requestParameters.eventStatus, requestParameters.eventSeverities, requestParameters.categories, requestParameters.complianceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export data into CSV
         * @param {DefaultApiExportCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCsv(requestParameters: DefaultApiExportCsvRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.exportCsv(requestParameters.csvExportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Action Metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionMetadata(options?: RawAxiosRequestConfig): AxiosPromise<ActionMetadata> {
            return localVarFp.getActionMetadata(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Meta Info for AI Service
         * @param {DefaultApiGetAiServiceMetaInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiServiceMetaInfo(requestParameters: DefaultApiGetAiServiceMetaInfoRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetAiServiceMetaInfoResponse> {
            return localVarFp.getAiServiceMetaInfo(requestParameters.aiServiceDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ai Service Recommendation
         * @param {DefaultApiGetAiServiceRecommendationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiServiceRecommendation(requestParameters: DefaultApiGetAiServiceRecommendationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<EntityData> {
            return localVarFp.getAiServiceRecommendation(requestParameters.aiServiceDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all compute instance rate records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllComputeInstanceRateRecords(options?: RawAxiosRequestConfig): AxiosPromise<GetAllComputeInstanceRateRecords200Response> {
            return localVarFp.getAllComputeInstanceRateRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all uploaded CSV files config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCsvUploads(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetAllCsvUploads200ResponseInner>> {
            return localVarFp.getAllCsvUploads(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get feature flags
         * @param {DefaultApiGetAllFeatureFlagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFeatureFlags(requestParameters: DefaultApiGetAllFeatureFlagsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetAllFeatureFlags200Response> {
            return localVarFp.getAllFeatureFlags(requestParameters.enabledOnly, requestParameters.computedOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Managed LLM Inference rate cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllManagedLLMInferenceRecords(options?: RawAxiosRequestConfig): AxiosPromise<GetAllManagedLLMInferenceRecords200Response> {
            return localVarFp.getAllManagedLLMInferenceRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all raw events
         * @param {DefaultApiGetAllRawEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRawEvents(requestParameters: DefaultApiGetAllRawEventsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetAllRawEvents200Response> {
            return localVarFp.getAllRawEvents(requestParameters.startTime, requestParameters.endTime, requestParameters.aiServiceId, requestParameters.userId, requestParameters.machineId, requestParameters.sourceNames, requestParameters.eventTypes, requestParameters.pageCursor, requestParameters.limit, requestParameters.aggregationBucketSecs, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all roles available to users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles(options?: RawAxiosRequestConfig): AxiosPromise<Array<Role>> {
            return localVarFp.getAllRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: RawAxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.getAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Vector DB Rate cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVectorDBRecords(options?: RawAxiosRequestConfig): AxiosPromise<GetAllVectorDBRecords200Response> {
            return localVarFp.getAllVectorDBRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provide annotation aggregations for list of content ids
         * @param {DefaultApiGetAnnotationAggregationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationAggregation(requestParameters: DefaultApiGetAnnotationAggregationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<AnnotationAggregate>> {
            return localVarFp.getAnnotationAggregation(requestParameters.aggregateType, requestParameters.sourceIds, requestParameters.destinationIds, requestParameters.linkedContentIds, requestParameters.namedEntityTypes, requestParameters.namedEntityValues, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filtered annotation list
         * @param {DefaultApiGetAnnotationListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationList(requestParameters: DefaultApiGetAnnotationListRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAnnotationList200Response> {
            return localVarFp.getAnnotationList(requestParameters.namedEntityTypes, requestParameters.offset, requestParameters.limit, requestParameters.namedEntityValues, requestParameters.contentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch annotation details using content id
         * @param {DefaultApiGetAnnotationsOfContentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationsOfContent(requestParameters: DefaultApiGetAnnotationsOfContentRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AnnotationData>> {
            return localVarFp.getAnnotationsOfContent(requestParameters.contentId, requestParameters.contentEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the policies applied to an entity type + policy type
         * @param {DefaultApiGetApplicationStatusForEntityTypeAndPolicyTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationStatusForEntityTypeAndPolicyType(requestParameters: DefaultApiGetApplicationStatusForEntityTypeAndPolicyTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityAppliedPolicy>> {
            return localVarFp.getApplicationStatusForEntityTypeAndPolicyType(requestParameters.entityType, requestParameters.policyType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get audit events
         * @param {DefaultApiGetAuditEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditEvents(requestParameters: DefaultApiGetAuditEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuditEventsResponse> {
            return localVarFp.getAuditEvents(requestParameters.category, requestParameters.entityType, requestParameters.startTime, requestParameters.endTime, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all collectors
         * @param {DefaultApiGetCollectorsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectors(requestParameters: DefaultApiGetCollectorsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetCollectors200ResponseInner>> {
            return localVarFp.getCollectors(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get infra configurations
         * @summary Get infra configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigDetail(options?: RawAxiosRequestConfig): AxiosPromise<GetConfigResponse> {
            return localVarFp.getConfigDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * Get infra configurations
         * @summary Get infra configurations
         * @param {DefaultApiGetConfigDetailsByDeploymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigDetailsByDeployment(requestParameters: DefaultApiGetConfigDetailsByDeploymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<InstanceConfig> {
            return localVarFp.getConfigDetailsByDeployment(requestParameters.deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get content facets
         * @param {DefaultApiGetContentFacetsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFacets(requestParameters: DefaultApiGetContentFacetsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetContentFacets200Response> {
            return localVarFp.getContentFacets(requestParameters.facetProperty, requestParameters.contentEntityType, requestParameters.sourceIds, requestParameters.destinationIds, requestParameters.linkedContentIds, requestParameters.namedEntityTypes, requestParameters.namedEntityValues, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch content detail using id
         * @param {DefaultApiGetContentFromIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFromId(requestParameters: DefaultApiGetContentFromIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContentData> {
            return localVarFp.getContentFromId(requestParameters.contentId, requestParameters.contentEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of content
         * @param {DefaultApiGetContentListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentList(requestParameters: DefaultApiGetContentListRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetContentList200Response> {
            return localVarFp.getContentList(requestParameters.contentEntityType, requestParameters.offset, requestParameters.limit, requestParameters.sourceIds, requestParameters.destinationIds, requestParameters.linkedContentIds, requestParameters.namedEntityTypes, requestParameters.namedEntityValues, requestParameters.startTime, requestParameters.endTime, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get schema for core entity type.
         * @param {DefaultApiGetCoreEntityTypeSchemaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoreEntityTypeSchema(requestParameters: DefaultApiGetCoreEntityTypeSchemaRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityTypeSchema> {
            return localVarFp.getCoreEntityTypeSchema(requestParameters.entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get rate card records by filter and group
         * @param {DefaultApiGetCostRecordsByFilterAndGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostRecordsByFilterAndGroup(requestParameters: DefaultApiGetCostRecordsByFilterAndGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<FilterAndGroupRateCard> {
            return localVarFp.getCostRecordsByFilterAndGroup(requestParameters.filterAndGroupRateCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a data source
         * @param {DefaultApiGetDataSourceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSource(requestParameters: DefaultApiGetDataSourceRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatasourceConfig> {
            return localVarFp.getDataSource(requestParameters.datasourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all data sources
         * @param {DefaultApiGetDataSourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSources(requestParameters: DefaultApiGetDataSourcesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetDataSources200ResponseInner>> {
            return localVarFp.getDataSources(requestParameters.collectorId, requestParameters.datasourceType, requestParameters.vendor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity facets
         * @param {DefaultApiGetEntityFacetsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityFacets(requestParameters: DefaultApiGetEntityFacetsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FacetData>> {
            return localVarFp.getEntityFacets(requestParameters.entityType, requestParameters.facetProperty, requestParameters.includeCount, requestParameters.queryId, requestParameters.getFacetDataParam, options).then((request) => request(axios, basePath));
        },
        /**
         * supported mappings ip-> app ; ip->workload; app->ip; workload->ip
         * @summary Get entity mappings
         * @param {DefaultApiGetEntityMappingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappings(requestParameters: DefaultApiGetEntityMappingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityMappingData>> {
            return localVarFp.getEntityMappings(requestParameters.entityType, requestParameters.mapToEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity mappings by reference object
         * @param {DefaultApiGetEntityMappingsByReferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappingsByReference(requestParameters: DefaultApiGetEntityMappingsByReferenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityMappingData>> {
            return localVarFp.getEntityMappingsByReference(requestParameters.destEntityType, requestParameters.entityReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity data
         * @param {DefaultApiGetEntityPageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityPage(requestParameters: DefaultApiGetEntityPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityData> {
            return localVarFp.getEntityPage(requestParameters.entityType, requestParameters.queryId, requestParameters.getEntityDataParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity trend metrics according to provided filters
         * @param {DefaultApiGetEntityTrendRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTrend(requestParameters: DefaultApiGetEntityTrendRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<MetricSeriesResponse> {
            return localVarFp.getEntityTrend(requestParameters.entityTrendRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get schema for UI entity type.
         * @param {DefaultApiGetEntityTypeSchemaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypeSchema(requestParameters: DefaultApiGetEntityTypeSchemaRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityTypeSchema> {
            return localVarFp.getEntityTypeSchema(requestParameters.entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get schema for UI enum types.
         * @param {DefaultApiGetEnumTypeSchemaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnumTypeSchema(requestParameters: DefaultApiGetEnumTypeSchemaRequest, options?: RawAxiosRequestConfig): AxiosPromise<EnumTypeSchema> {
            return localVarFp.getEnumTypeSchema(requestParameters.enumType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Event Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCategories(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEventCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get events facets
         * @param {DefaultApiGetEventFacetsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventFacets(requestParameters: DefaultApiGetEventFacetsRequest, options?: RawAxiosRequestConfig): AxiosPromise<EventReferenceFacets> {
            return localVarFp.getEventFacets(requestParameters.facetProperty, requestParameters.eventType, requestParameters.entityType, requestParameters.anchorEntityTypes, requestParameters.anchorEntityIds, requestParameters.eventStatus, requestParameters.eventSeverities, requestParameters.categories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Event Severity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSeverities(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEventSeverities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Event to Content Navigation Metadata
         * @param {DefaultApiGetEventToContentNavigationMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventToContentNavigationMetadata(requestParameters: DefaultApiGetEventToContentNavigationMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<NavigationResponses> {
            return localVarFp.getEventToContentNavigationMetadata(requestParameters.navigationRequests, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get general settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralSettings(options?: RawAxiosRequestConfig): AxiosPromise<TenantGeneralSettings> {
            return localVarFp.getGeneralSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get jira config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJiraConfig(options?: RawAxiosRequestConfig): AxiosPromise<JiraConfig> {
            return localVarFp.getJiraConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get lifecycle events
         * @param {DefaultApiGetLifecycleEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLifecycleEvents(requestParameters: DefaultApiGetLifecycleEventsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<LifecycleEventsResponse> {
            return localVarFp.getLifecycleEvents(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a jira linked to this entity
         * @param {DefaultApiGetLinkedJiraTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkedJiraTicket(requestParameters: DefaultApiGetLinkedJiraTicketRequest, options?: RawAxiosRequestConfig): AxiosPromise<JiraDetails> {
            return localVarFp.getLinkedJiraTicket(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of Machine Events
         * @param {DefaultApiGetMachineEventsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMachineEventsList(requestParameters: DefaultApiGetMachineEventsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetMachineEventsList200Response> {
            return localVarFp.getMachineEventsList(requestParameters.machineEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of Machine Events
         * @param {DefaultApiGetMachineEventsListTimeSeriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMachineEventsListTimeSeries(requestParameters: DefaultApiGetMachineEventsListTimeSeriesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetMachineEventsListTimeSeries200Response> {
            return localVarFp.getMachineEventsListTimeSeries(requestParameters.machineEventTimeSeriesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metric schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSchema(options?: RawAxiosRequestConfig): AxiosPromise<Array<MetricSchema>> {
            return localVarFp.getMetricSchema(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metric series
         * @param {DefaultApiGetMetricSeriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSeries(requestParameters: DefaultApiGetMetricSeriesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<MetricSeriesResponse> {
            return localVarFp.getMetricSeries(requestParameters.metricSeriesQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metric series for AI service (Automatically chooses the fq metric)
         * @param {DefaultApiGetMetricSeriesForAIServiceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSeriesForAIService(requestParameters: DefaultApiGetMetricSeriesForAIServiceRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<MetricSeriesResponse> {
            return localVarFp.getMetricSeriesForAIService(requestParameters.metricSeriesQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metric tags
         * @param {DefaultApiGetMetricTagValuesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricTagValues(requestParameters: DefaultApiGetMetricTagValuesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<MetricTagListInner>> {
            return localVarFp.getMetricTagValues(requestParameters.metricTagListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a note
         * @param {DefaultApiGetNoteByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById(requestParameters: DefaultApiGetNoteByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.getNoteById(requestParameters.entityType, requestParameters.entityId, requestParameters.noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all items which are currently onboarded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardedItems(options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityRef>> {
            return localVarFp.getOnboardedItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get activity related info for items which are currently onboarded
         * @param {DefaultApiGetOnboardedItemsActivityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardedItemsActivity(requestParameters: DefaultApiGetOnboardedItemsActivityRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityActivityInfo>> {
            return localVarFp.getOnboardedItemsActivity(requestParameters.entityRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get header info items which are currently onboarded like risk
         * @param {DefaultApiGetOnboardedItemsHeaderInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardedItemsHeaderInfo(requestParameters: DefaultApiGetOnboardedItemsHeaderInfoRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityHeaderInfo>> {
            return localVarFp.getOnboardedItemsHeaderInfo(requestParameters.entityRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current user\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateUser(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getOrCreateUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get PII data storage config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPiiConfig(options?: RawAxiosRequestConfig): AxiosPromise<PiiConfig> {
            return localVarFp.getPiiConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Policy
         * @summary Get Policy
         * @param {DefaultApiGetPolicesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolices(requestParameters: DefaultApiGetPolicesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getPolices(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get possible values of attributes of the entity type used in policy
         * @param {DefaultApiGetPolicyAttributeValuesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyAttributeValues(requestParameters: DefaultApiGetPolicyAttributeValuesRequest, options?: RawAxiosRequestConfig): AxiosPromise<AttributeValuesResponse> {
            return localVarFp.getPolicyAttributeValues(requestParameters.applicableEntityType, requestParameters.attributeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all policy types and their supported entity types and properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyEntityTypeProperties(options?: RawAxiosRequestConfig): AxiosPromise<PolicyTypePropertyList> {
            return localVarFp.getPolicyEntityTypeProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get preference for provided id
         * @param {DefaultApiGetPreferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreference(requestParameters: DefaultApiGetPreferenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Preference> {
            return localVarFp.getPreference(requestParameters.entityId, requestParameters.preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all preferences for provided entity
         * @param {DefaultApiGetPreferencesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreferences(requestParameters: DefaultApiGetPreferencesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Preference>> {
            return localVarFp.getPreferences(requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recommendation for Entity ID.
         * @param {DefaultApiGetRecommendationCostForEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendationCostForEntity(requestParameters: DefaultApiGetRecommendationCostForEntityRequest, options?: RawAxiosRequestConfig): AxiosPromise<RecommendationData> {
            return localVarFp.getRecommendationCostForEntity(requestParameters.entityId, requestParameters.entityType, requestParameters.deploymentEntityType, requestParameters.startTime, requestParameters.endTime, requestParameters.entityName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all regions
         * @param {DefaultApiGetRegionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(requestParameters: DefaultApiGetRegionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetRegions200ResponseInner>> {
            return localVarFp.getRegions(requestParameters.vendorName, requestParameters.regionSubtype, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Runtime Config
         * @summary Get Runtime Config
         * @param {DefaultApiGetRuntimeConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuntimeConfig(requestParameters: DefaultApiGetRuntimeConfigRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeConfigResponse> {
            return localVarFp.getRuntimeConfig(requestParameters.agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get savings cost for Entity Group.
         * @param {DefaultApiGetSavingsCostForEntityGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavingsCostForEntityGroup(requestParameters: DefaultApiGetSavingsCostForEntityGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<SavingsData> {
            return localVarFp.getSavingsCostForEntityGroup(requestParameters.entityGroupType, requestParameters.startTime, requestParameters.endTime, requestParameters.entityGroupNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sensitive data settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensitiveSettings(options?: RawAxiosRequestConfig): AxiosPromise<SensitiveDataSettings> {
            return localVarFp.getSensitiveSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get settings
         * @param {DefaultApiGetSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(requestParameters: DefaultApiGetSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SettingsObject> {
            return localVarFp.getSettings(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get settings
         * @param {DefaultApiGetSettingsConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsConfig(requestParameters: DefaultApiGetSettingsConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<SettingsConfigObject> {
            return localVarFp.getSettingsConfig(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Email SMTP data storage config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmtpConfig(options?: RawAxiosRequestConfig): AxiosPromise<EmailConfig> {
            return localVarFp.getSmtpConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get app, workload, or library linked vulnerabilities
         * @param {DefaultApiGetSoftwareVulnerabilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareVulnerabilities(requestParameters: DefaultApiGetSoftwareVulnerabilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<LibraryCveList> {
            return localVarFp.getSoftwareVulnerabilities(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedDatasources(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetSupportedDatasources200ResponseInner>> {
            return localVarFp.getSupportedDatasources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supported preference types for provided id
         * @param {DefaultApiGetSupportedPreferencesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedPreferences(requestParameters: DefaultApiGetSupportedPreferencesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SupportedPreferenceItem>> {
            return localVarFp.getSupportedPreferences(requestParameters.entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants(options?: RawAxiosRequestConfig): AxiosPromise<Array<Tenant>> {
            return localVarFp.getTenants(options).then((request) => request(axios, basePath));
        },
        /**
         * Is a topic approved, unapproved or unclear based on closest, already-existing policy topics
         * @summary approval status of topics - approved/unapproved/unclear
         * @param {DefaultApiGetTopicsApprovalStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsApprovalStatus(requestParameters: DefaultApiGetTopicsApprovalStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<TopicPolicyStatusResponse> {
            return localVarFp.getTopicsApprovalStatus(requestParameters.topics, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unverified AI Service
         * @param {DefaultApiGetUnverifiedAiServiceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnverifiedAiService(requestParameters: DefaultApiGetUnverifiedAiServiceRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityData> {
            return localVarFp.getUnverifiedAiService(requestParameters.getEntityDataParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get usage cost for Entity ID.
         * @param {DefaultApiGetUsageCostForEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageCostForEntity(requestParameters: DefaultApiGetUsageCostForEntityRequest, options?: RawAxiosRequestConfig): AxiosPromise<UsageCostData> {
            return localVarFp.getUsageCostForEntity(requestParameters.entityId, requestParameters.entityType, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get usage cost for Entity Group.
         * @param {DefaultApiGetUsageCostForEntityGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageCostForEntityGroup(requestParameters: DefaultApiGetUsageCostForEntityGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupUsageCostData> {
            return localVarFp.getUsageCostForEntityGroup(requestParameters.groupUsageCostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Requests for an AI entity
         * @param {DefaultApiGetUserRequestsForEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRequestsForEntity(requestParameters: DefaultApiGetUserRequestsForEntityRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityUserRequest>> {
            return localVarFp.getUserRequestsForEntity(requestParameters.entityRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new graph
         * @param {DefaultApiGraphEntityTypeEntityIdGraphTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphEntityTypeEntityIdGraphTypeGet(requestParameters: DefaultApiGraphEntityTypeEntityIdGraphTypeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GraphResponse> {
            return localVarFp.graphEntityTypeEntityIdGraphTypeGet(requestParameters.entityType, requestParameters.entityId, requestParameters.graphType, options).then((request) => request(axios, basePath));
        },
        /**
         * Ingest data
         * @summary Ingest data
         * @param {DefaultApiIngestDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        ingestData(requestParameters: DefaultApiIngestDataRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BrowserAgentIngestResponse> {
            return localVarFp.ingestData(requestParameters.authorization, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * List different builds for Browser Extension
         * @summary List different builds for Browser Extension
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBrowserAgentBuilds(options?: RawAxiosRequestConfig): AxiosPromise<Array<BrowserAgentBuildListResponseInner>> {
            return localVarFp.listBrowserAgentBuilds(options).then((request) => request(axios, basePath));
        },
        /**
         * List different builds for Browser Extension
         * @summary List different builds for Browser Extension
         * @param {DefaultApiListBrowserAgentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBrowserAgents(requestParameters: DefaultApiListBrowserAgentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<BrowserAgentResponsePage> {
            return localVarFp.listBrowserAgents(requestParameters.start, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List schemas for concrete policy types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConcretePolicySchemas(options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityTypeSchema>> {
            return localVarFp.listConcretePolicySchemas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List schemas for core entity types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCoreEntityTypeSchemas(options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityTypeSchema>> {
            return localVarFp.listCoreEntityTypeSchemas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List schemas for UI entity types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntityTypeSchemas(options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityTypeSchema>> {
            return localVarFp.listEntityTypeSchemas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List schema for UI enum types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnumTypeSchemas(options?: RawAxiosRequestConfig): AxiosPromise<Array<EnumTypeSchema>> {
            return localVarFp.listEnumTypeSchemas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List notes
         * @param {DefaultApiListNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotes(requestParameters: DefaultApiListNotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Note>> {
            return localVarFp.listNotes(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get risk categories for risk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRiskCategory(options?: RawAxiosRequestConfig): AxiosPromise<RiskCategoryList> {
            return localVarFp.listRiskCategory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tags
         * @param {DefaultApiListTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTags(requestParameters: DefaultApiListTagsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<TagList> {
            return localVarFp.listTags(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all localized messages for the current language. The language can be specified using the `Accept-Language` header.
         * @summary Get all localized messages
         * @param {DefaultApiMessagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesGet(requestParameters: DefaultApiMessagesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.messagesGet(requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a localized message by providing the message key. The language can be specified using the `Accept-Language` header.
         * @summary Get a localized message by key
         * @param {DefaultApiMessagesKeyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesKeyGet(requestParameters: DefaultApiMessagesKeyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MessagesKeyGet200Response> {
            return localVarFp.messagesKeyGet(requestParameters.key, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Offboard a set of supported entities
         * @param {DefaultApiOffboardEntitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offboardEntities(requestParameters: DefaultApiOffboardEntitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.offboardEntities(requestParameters.entityRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Onboard a set of supported entities
         * @param {DefaultApiOnboardEntitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardEntities(requestParameters: DefaultApiOnboardEntitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.onboardEntities(requestParameters.entityRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the policies applied to an entity and its surrounding context
         * @param {DefaultApiPoliciesApplicationStatusPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesApplicationStatusPost(requestParameters: DefaultApiPoliciesApplicationStatusPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityAppliedPolicy>> {
            return localVarFp.policiesApplicationStatusPost(requestParameters.entityRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List State Transitions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesApprovalStatusStateTransitionsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<StateTransition>> {
            return localVarFp.policiesApprovalStatusStateTransitionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve a set of supported entities
         * @param {DefaultApiPoliciesApprovePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesApprovePost(requestParameters: DefaultApiPoliciesApprovePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.policiesApprovePost(requestParameters.entityRefs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List policies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<PolicyData>> {
            return localVarFp.policiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete policy for ID.
         * @param {DefaultApiPoliciesPolicyIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPolicyIdDelete(requestParameters: DefaultApiPoliciesPolicyIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.policiesPolicyIdDelete(requestParameters.policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get policy for ID.
         * @param {DefaultApiPoliciesPolicyIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPolicyIdGet(requestParameters: DefaultApiPoliciesPolicyIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyData> {
            return localVarFp.policiesPolicyIdGet(requestParameters.policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a policy
         * @param {DefaultApiPoliciesPolicyIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPolicyIdPut(requestParameters: DefaultApiPoliciesPolicyIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyData> {
            return localVarFp.policiesPolicyIdPut(requestParameters.policyId, requestParameters.policyData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Policy
         * @param {DefaultApiPoliciesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPost(requestParameters: DefaultApiPoliciesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyData> {
            return localVarFp.policiesPost(requestParameters.policyData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the approval status of a set of supported entities by property values
         * @param {DefaultApiPoliciesPropertyApprovalStatusChangePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPropertyApprovalStatusChangePost(requestParameters: DefaultApiPoliciesPropertyApprovalStatusChangePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.policiesPropertyApprovalStatusChangePost(requestParameters.approvalStatusChange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve a set of supported entities by property values
         * @param {DefaultApiPoliciesPropertyApprovePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPropertyApprovePost(requestParameters: DefaultApiPoliciesPropertyApprovePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.policiesPropertyApprovePost(requestParameters.propertyValues, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Un-approve a set of supported entities by property values
         * @param {DefaultApiPoliciesPropertyUnapprovePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesPropertyUnapprovePost(requestParameters: DefaultApiPoliciesPropertyUnapprovePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.policiesPropertyUnapprovePost(requestParameters.propertyValues, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Un-approve a set of supported entities
         * @param {DefaultApiPoliciesUnapprovePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesUnapprovePost(requestParameters: DefaultApiPoliciesUnapprovePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.policiesUnapprovePost(requestParameters.entityRefs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List policy contexts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<PolicyContextData>> {
            return localVarFp.policyContextsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get policy context for ID.
         * @param {DefaultApiPolicyContextsPolicyCtxIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPolicyCtxIdGet(requestParameters: DefaultApiPolicyContextsPolicyCtxIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyContextData> {
            return localVarFp.policyContextsPolicyCtxIdGet(requestParameters.policyCtxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get policy suggestions translated from a policy context using ID.
         * @param {DefaultApiPolicyContextsPolicyCtxIdPolicySuggestionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPolicyCtxIdPolicySuggestionsGet(requestParameters: DefaultApiPolicyContextsPolicyCtxIdPolicySuggestionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PolicyData>> {
            return localVarFp.policyContextsPolicyCtxIdPolicySuggestionsGet(requestParameters.policyCtxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a policy context
         * @param {DefaultApiPolicyContextsPolicyCtxIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPolicyCtxIdPut(requestParameters: DefaultApiPolicyContextsPolicyCtxIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyContextData> {
            return localVarFp.policyContextsPolicyCtxIdPut(requestParameters.policyCtxId, requestParameters.policyContextData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Policy Context
         * @param {DefaultApiPolicyContextsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyContextsPost(requestParameters: DefaultApiPolicyContextsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyContextData> {
            return localVarFp.policyContextsPost(requestParameters.policyContextData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get preview of provided summary
         * @param {DefaultApiPolicyPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyPreview(requestParameters: DefaultApiPolicyPreviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicyPreviewResponse> {
            return localVarFp.policyPreview(requestParameters.conditionMetFlag, requestParameters.policyData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get summary of defined policies
         * @param {DefaultApiPolicySummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policySummary(requestParameters: DefaultApiPolicySummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<PolicySummaryResponse> {
            return localVarFp.policySummary(requestParameters.policySummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Register User using browser agent
         * @summary Register User using browser agent
         * @param {DefaultApiRegisterUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(requestParameters: DefaultApiRegisterUserRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RegisterUserResponse> {
            return localVarFp.registerUser(requestParameters.registerUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds/updates the provided secrets to the secrets manager
         * @summary Set Update Secrets
         * @param {DefaultApiSetUpdateSecretsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUpdateSecrets(requestParameters: DefaultApiSetUpdateSecretsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.setUpdateSecrets(requestParameters.customerSecretInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Change infra configurations
         * @summary Change infra configurations
         * @param {DefaultApiUpdateConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig(requestParameters: DefaultApiUpdateConfigRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateConfig(requestParameters.updateConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change infra configurations
         * @summary Change infra configurations
         * @param {DefaultApiUpdateConfigByDeploymentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigByDeployment(requestParameters: DefaultApiUpdateConfigByDeploymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateConfigByDeployment(requestParameters.deploymentId, requestParameters.updateConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a data source
         * @param {DefaultApiUpdateDataSourceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataSource(requestParameters: DefaultApiUpdateDataSourceRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateDataSource(requestParameters.datasourceId, requestParameters.datasourceConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update general settings
         * @param {DefaultApiUpdateGeneralSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralSettings(requestParameters: DefaultApiUpdateGeneralSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateGeneralSettings(requestParameters.tenantGeneralSettingsUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update note
         * @param {DefaultApiUpdateNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(requestParameters: DefaultApiUpdateNoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.updateNote(requestParameters.entityType, requestParameters.entityId, requestParameters.noteId, requestParameters.updateNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update preference for provided id
         * @param {DefaultApiUpdatePreferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreference(requestParameters: DefaultApiUpdatePreferenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Preference> {
            return localVarFp.updatePreference(requestParameters.entityId, requestParameters.preferenceId, requestParameters.preference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a sensitive data config
         * @param {DefaultApiUpdateSensitiveDataConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensitiveDataConfig(requestParameters: DefaultApiUpdateSensitiveDataConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateSensitiveDataConfig(requestParameters.configId, requestParameters.sensitiveDataConfigUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Request for approval for a use case involving a AI entity
         * @param {DefaultApiUpdateUserRequestForEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRequestForEntity(requestParameters: DefaultApiUpdateUserRequestForEntityRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateUserRequestForEntity(requestParameters.entityUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the user\'s role
         * @param {DefaultApiUpdateUserRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole(requestParameters: DefaultApiUpdateUserRoleRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.updateUserRole(requestParameters.userId, requestParameters.userUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a CSV file for data sources
         * @param {DefaultApiUploadCsvDataSourceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCsvDataSource(requestParameters: DefaultApiUploadCsvDataSourceRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UploadCsvDataSource200Response> {
            return localVarFp.uploadCsvDataSource(requestParameters.file, requestParameters.csvType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns True if the customer key is present in the secrets manager, else False
         * @summary Check if customer key is present
         * @param {DefaultApiUsingCustomerKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usingCustomerKey(requestParameters: DefaultApiUsingCustomerKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usingCustomerKey(requestParameters.secretName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for agentHealthCheck operation in DefaultApi.
 * @export
 * @interface DefaultApiAgentHealthCheckRequest
 */
export interface DefaultApiAgentHealthCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAgentHealthCheck
     */
    readonly authorization: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiAgentHealthCheck
     */
    readonly version?: string
}

/**
 * Request parameters for attachTag operation in DefaultApi.
 * @export
 * @interface DefaultApiAttachTagRequest
 */
export interface DefaultApiAttachTagRequest {
    /**
     * 
     * @type {Array<AttachTagRequestInner>}
     * @memberof DefaultApiAttachTag
     */
    readonly attachTagRequestInner: Array<AttachTagRequestInner>
}

/**
 * Request parameters for browserAgentAuth operation in DefaultApi.
 * @export
 * @interface DefaultApiBrowserAgentAuthRequest
 */
export interface DefaultApiBrowserAgentAuthRequest {
    /**
     * 
     * @type {BrowserAgentAuthRequest}
     * @memberof DefaultApiBrowserAgentAuth
     */
    readonly browserAgentAuthRequest?: BrowserAgentAuthRequest
}

/**
 * Request parameters for componentsLlmLineagePost operation in DefaultApi.
 * @export
 * @interface DefaultApiComponentsLlmLineagePostRequest
 */
export interface DefaultApiComponentsLlmLineagePostRequest {
    /**
     * 
     * @type {LLMLineageRequest}
     * @memberof DefaultApiComponentsLlmLineagePost
     */
    readonly lLMLineageRequest: LLMLineageRequest
}

/**
 * Request parameters for createCollector operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateCollectorRequest
 */
export interface DefaultApiCreateCollectorRequest {
    /**
     * 
     * @type {CreateCollectorRequest}
     * @memberof DefaultApiCreateCollector
     */
    readonly createCollectorRequest: CreateCollectorRequest
}

/**
 * Request parameters for createCollectorK8sCluster operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateCollectorK8sClusterRequest
 */
export interface DefaultApiCreateCollectorK8sClusterRequest {
    /**
     * 
     * @type {CreateCollectorK8sClusterRequest}
     * @memberof DefaultApiCreateCollectorK8sCluster
     */
    readonly createCollectorK8sClusterRequest: CreateCollectorK8sClusterRequest
}

/**
 * Request parameters for createNote operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateNoteRequest
 */
export interface DefaultApiCreateNoteRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiCreateNote
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiCreateNote
     */
    readonly entityId: string

    /**
     * 
     * @type {CreateNoteRequest}
     * @memberof DefaultApiCreateNote
     */
    readonly createNoteRequest: CreateNoteRequest
}

/**
 * Request parameters for createOrLinkJiraTicket operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateOrLinkJiraTicketRequest
 */
export interface DefaultApiCreateOrLinkJiraTicketRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiCreateOrLinkJiraTicket
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiCreateOrLinkJiraTicket
     */
    readonly entityId: string

    /**
     * 
     * @type {JiraRequest}
     * @memberof DefaultApiCreateOrLinkJiraTicket
     */
    readonly jiraRequest: JiraRequest
}

/**
 * Request parameters for createOrUpdateJiraConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateOrUpdateJiraConfigRequest
 */
export interface DefaultApiCreateOrUpdateJiraConfigRequest {
    /**
     * 
     * @type {JiraConfig}
     * @memberof DefaultApiCreateOrUpdateJiraConfig
     */
    readonly jiraConfig: JiraConfig
}

/**
 * Request parameters for createOrUpdatePiiConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateOrUpdatePiiConfigRequest
 */
export interface DefaultApiCreateOrUpdatePiiConfigRequest {
    /**
     * 
     * @type {PiiConfig}
     * @memberof DefaultApiCreateOrUpdatePiiConfig
     */
    readonly piiConfig: PiiConfig
}

/**
 * Request parameters for createOrUpdateSettings operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateOrUpdateSettingsRequest
 */
export interface DefaultApiCreateOrUpdateSettingsRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiCreateOrUpdateSettings
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiCreateOrUpdateSettings
     */
    readonly entityId: string

    /**
     * 
     * @type {SettingsObject}
     * @memberof DefaultApiCreateOrUpdateSettings
     */
    readonly settingsObject: SettingsObject
}

/**
 * Request parameters for createOrUpdateSmtpConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateOrUpdateSmtpConfigRequest
 */
export interface DefaultApiCreateOrUpdateSmtpConfigRequest {
    /**
     * 
     * @type {EmailConfig}
     * @memberof DefaultApiCreateOrUpdateSmtpConfig
     */
    readonly emailConfig: EmailConfig
}

/**
 * Request parameters for createPolicyForUserRequest operation in DefaultApi.
 * @export
 * @interface DefaultApiCreatePolicyForUserRequestRequest
 */
export interface DefaultApiCreatePolicyForUserRequestRequest {
    /**
     * 
     * @type {UserRequestPolicyRequest}
     * @memberof DefaultApiCreatePolicyForUserRequest
     */
    readonly userRequestPolicyRequest: UserRequestPolicyRequest
}

/**
 * Request parameters for createPreference operation in DefaultApi.
 * @export
 * @interface DefaultApiCreatePreferenceRequest
 */
export interface DefaultApiCreatePreferenceRequest {
    /**
     * Entity Id
     * @type {string}
     * @memberof DefaultApiCreatePreference
     */
    readonly entityId: string

    /**
     * 
     * @type {Preference}
     * @memberof DefaultApiCreatePreference
     */
    readonly preference: Preference
}

/**
 * Request parameters for createSensitiveDataConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateSensitiveDataConfigRequest
 */
export interface DefaultApiCreateSensitiveDataConfigRequest {
    /**
     * 
     * @type {SensitiveDataConfigCreate}
     * @memberof DefaultApiCreateSensitiveDataConfig
     */
    readonly sensitiveDataConfigCreate: SensitiveDataConfigCreate
}

/**
 * Request parameters for createTag operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateTagRequest
 */
export interface DefaultApiCreateTagRequest {
    /**
     * 
     * @type {NewTag}
     * @memberof DefaultApiCreateTag
     */
    readonly newTag: NewTag
}

/**
 * Request parameters for createTenant operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateTenantRequest
 */
export interface DefaultApiCreateTenantRequest {
    /**
     * 
     * @type {CreateTenantRequest}
     * @memberof DefaultApiCreateTenant
     */
    readonly createTenantRequest: CreateTenantRequest
}

/**
 * Request parameters for createUserRequestForEntity operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateUserRequestForEntityRequest
 */
export interface DefaultApiCreateUserRequestForEntityRequest {
    /**
     * 
     * @type {EntityUserRequest}
     * @memberof DefaultApiCreateUserRequestForEntity
     */
    readonly entityUserRequest: EntityUserRequest
}

/**
 * Request parameters for datasourcesDatasourceIdStartPost operation in DefaultApi.
 * @export
 * @interface DefaultApiDatasourcesDatasourceIdStartPostRequest
 */
export interface DefaultApiDatasourcesDatasourceIdStartPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDatasourcesDatasourceIdStartPost
     */
    readonly datasourceId: string
}

/**
 * Request parameters for datasourcesDatasourceIdStopPost operation in DefaultApi.
 * @export
 * @interface DefaultApiDatasourcesDatasourceIdStopPostRequest
 */
export interface DefaultApiDatasourcesDatasourceIdStopPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDatasourcesDatasourceIdStopPost
     */
    readonly datasourceId: string
}

/**
 * Request parameters for datasourcesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiDatasourcesPostRequest
 */
export interface DefaultApiDatasourcesPostRequest {
    /**
     * 
     * @type {DatasourceConfig}
     * @memberof DefaultApiDatasourcesPost
     */
    readonly datasourceConfig: DatasourceConfig
}

/**
 * Request parameters for deleteCollector operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteCollectorRequest
 */
export interface DefaultApiDeleteCollectorRequest {
    /**
     * Collector ID to delete
     * @type {string}
     * @memberof DefaultApiDeleteCollector
     */
    readonly id: string
}

/**
 * Request parameters for deleteDataSource operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteDataSourceRequest
 */
export interface DefaultApiDeleteDataSourceRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDeleteDataSource
     */
    readonly datasourceId: string
}

/**
 * Request parameters for deleteJiraLink operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteJiraLinkRequest
 */
export interface DefaultApiDeleteJiraLinkRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiDeleteJiraLink
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiDeleteJiraLink
     */
    readonly entityId: string
}

/**
 * Request parameters for deleteNote operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteNoteRequest
 */
export interface DefaultApiDeleteNoteRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiDeleteNote
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiDeleteNote
     */
    readonly entityId: string

    /**
     * Note ID
     * @type {string}
     * @memberof DefaultApiDeleteNote
     */
    readonly noteId: string
}

/**
 * Request parameters for deletePreference operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePreferenceRequest
 */
export interface DefaultApiDeletePreferenceRequest {
    /**
     * Entity Id
     * @type {string}
     * @memberof DefaultApiDeletePreference
     */
    readonly entityId: string

    /**
     * Preference Id
     * @type {string}
     * @memberof DefaultApiDeletePreference
     */
    readonly preferenceId: string
}

/**
 * Request parameters for deleteUserRequest operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteUserRequestRequest
 */
export interface DefaultApiDeleteUserRequestRequest {
    /**
     * 
     * @type {DeleteUserRequestRequest}
     * @memberof DefaultApiDeleteUserRequest
     */
    readonly deleteUserRequestRequest: DeleteUserRequestRequest
}

/**
 * Request parameters for detachTag operation in DefaultApi.
 * @export
 * @interface DefaultApiDetachTagRequest
 */
export interface DefaultApiDetachTagRequest {
    /**
     * 
     * @type {Array<DetachTag>}
     * @memberof DefaultApiDetachTag
     */
    readonly detachTag: Array<DetachTag>
}

/**
 * Request parameters for disablePolicy operation in DefaultApi.
 * @export
 * @interface DefaultApiDisablePolicyRequest
 */
export interface DefaultApiDisablePolicyRequest {
    /**
     * Policy Id
     * @type {string}
     * @memberof DefaultApiDisablePolicy
     */
    readonly policyId: string
}

/**
 * Request parameters for downloadCsvDataSource operation in DefaultApi.
 * @export
 * @interface DefaultApiDownloadCsvDataSourceRequest
 */
export interface DefaultApiDownloadCsvDataSourceRequest {
    /**
     * ID of the data source configuration
     * @type {string}
     * @memberof DefaultApiDownloadCsvDataSource
     */
    readonly csvConfigId: string
}

/**
 * Request parameters for enableBrowserAgent operation in DefaultApi.
 * @export
 * @interface DefaultApiEnableBrowserAgentRequest
 */
export interface DefaultApiEnableBrowserAgentRequest {
    /**
     * 
     * @type {EnableBrowserAgentRequest}
     * @memberof DefaultApiEnableBrowserAgent
     */
    readonly enableBrowserAgentRequest?: EnableBrowserAgentRequest
}

/**
 * Request parameters for enablePolicy operation in DefaultApi.
 * @export
 * @interface DefaultApiEnablePolicyRequest
 */
export interface DefaultApiEnablePolicyRequest {
    /**
     * Policy Id
     * @type {string}
     * @memberof DefaultApiEnablePolicy
     */
    readonly policyId: string
}

/**
 * Request parameters for eventsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiEventsGetRequest
 */
export interface DefaultApiEventsGetRequest {
    /**
     * Event entity types to filter
     * @type {EventType}
     * @memberof DefaultApiEventsGet
     */
    readonly eventType?: EventType

    /**
     * Event entity types to filter
     * @type {Array<string>}
     * @memberof DefaultApiEventsGet
     */
    readonly entityType?: Array<string>

    /**
     * Anchor entity types to filter
     * @type {Array<string>}
     * @memberof DefaultApiEventsGet
     */
    readonly anchorEntityTypes?: Array<string>

    /**
     * Anchor entity ids to filter
     * @type {Array<string>}
     * @memberof DefaultApiEventsGet
     */
    readonly anchorEntityIds?: Array<string>

    /**
     * Event status to filter
     * @type {EventStatus}
     * @memberof DefaultApiEventsGet
     */
    readonly eventStatus?: EventStatus

    /**
     * Event severity to filter
     * @type {Array<EventSeverity>}
     * @memberof DefaultApiEventsGet
     */
    readonly eventSeverities?: Array<EventSeverity>

    /**
     * Event category to filter
     * @type {Array<string>}
     * @memberof DefaultApiEventsGet
     */
    readonly categories?: Array<string>

    /**
     * Compliance Type to filter
     * @type {number}
     * @memberof DefaultApiEventsGet
     */
    readonly complianceType?: number
}

/**
 * Request parameters for exportCsv operation in DefaultApi.
 * @export
 * @interface DefaultApiExportCsvRequest
 */
export interface DefaultApiExportCsvRequest {
    /**
     * 
     * @type {CsvExportRequest}
     * @memberof DefaultApiExportCsv
     */
    readonly csvExportRequest: CsvExportRequest
}

/**
 * Request parameters for getAiServiceMetaInfo operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAiServiceMetaInfoRequest
 */
export interface DefaultApiGetAiServiceMetaInfoRequest {
    /**
     * ai_service_definition_id
     * @type {string}
     * @memberof DefaultApiGetAiServiceMetaInfo
     */
    readonly aiServiceDefinitionId?: string
}

/**
 * Request parameters for getAiServiceRecommendation operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAiServiceRecommendationRequest
 */
export interface DefaultApiGetAiServiceRecommendationRequest {
    /**
     * ai_service_definition_id
     * @type {string}
     * @memberof DefaultApiGetAiServiceRecommendation
     */
    readonly aiServiceDefinitionId?: string
}

/**
 * Request parameters for getAllFeatureFlags operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllFeatureFlagsRequest
 */
export interface DefaultApiGetAllFeatureFlagsRequest {
    /**
     * Filter feature flags by their enabled state
     * @type {boolean}
     * @memberof DefaultApiGetAllFeatureFlags
     */
    readonly enabledOnly?: boolean

    /**
     * Only return computed Filter feature flags
     * @type {boolean}
     * @memberof DefaultApiGetAllFeatureFlags
     */
    readonly computedOnly?: boolean
}

/**
 * Request parameters for getAllRawEvents operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAllRawEventsRequest
 */
export interface DefaultApiGetAllRawEventsRequest {
    /**
     * Filter events from this timestamp (inclusive)
     * @type {number}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly startTime?: number

    /**
     * Filter events up to this timestamp (exclusive)
     * @type {number}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly endTime?: number

    /**
     * Filter events based on AI service reference
     * @type {Array<string>}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly aiServiceId?: Array<string>

    /**
     * Filter events based on user reference
     * @type {Array<string>}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly userId?: Array<string>

    /**
     * Filter events based on machine reference
     * @type {Array<string>}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly machineId?: Array<string>

    /**
     * Filter events based on source name (e.g., code42, Singulr Browser Extension, cortex)
     * @type {Array<'CODE42' | 'SINGULR_BROWSER_EXTENSION' | 'XDR'>}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly sourceNames?: Array<GetAllRawEventsSourceNamesEnum>

    /**
     * Filter events based on event type
     * @type {Array<'AI_SERVICE_ACCESS_EVENT' | 'FILE_UPLOAD_EVENT' | 'COPY_PASTE_EVENT' | 'POLICY_ENFORCEMENT_EVENT'>}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly eventTypes?: Array<GetAllRawEventsEventTypesEnum>

    /**
     * Page cursor for pagination
     * @type {string}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly pageCursor?: string

    /**
     * The number of results per page (for pagination)
     * @type {number}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly limit?: number

    /**
     * Aggregation bucket size in seconds for ai service access events
     * @type {number}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly aggregationBucketSecs?: number

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof DefaultApiGetAllRawEvents
     */
    readonly sort?: GetAllRawEventsSortEnum
}

/**
 * Request parameters for getAnnotationAggregation operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAnnotationAggregationRequest
 */
export interface DefaultApiGetAnnotationAggregationRequest {
    /**
     * Pass true to get aggregates of values. Default behaviour return key aggregates
     * @type {AnnotationAggregationTypeEnum}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly aggregateType?: AnnotationAggregationTypeEnum

    /**
     * list of source id to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly sourceIds?: Array<string>

    /**
     * list of destination id to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly destinationIds?: Array<string>

    /**
     * list of linked content ids to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly linkedContentIds?: Array<string>

    /**
     * type of named entities in annotations
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly namedEntityTypes?: Array<string>

    /**
     * values of named entities in annotations
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly namedEntityValues?: Array<string>

    /**
     * time range filter
     * @type {number}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly startTime?: number

    /**
     * time range filter
     * @type {number}
     * @memberof DefaultApiGetAnnotationAggregation
     */
    readonly endTime?: number
}

/**
 * Request parameters for getAnnotationList operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAnnotationListRequest
 */
export interface DefaultApiGetAnnotationListRequest {
    /**
     * type of named entities
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationList
     */
    readonly namedEntityTypes: Array<string>

    /**
     * offset index for pagination
     * @type {number}
     * @memberof DefaultApiGetAnnotationList
     */
    readonly offset: number

    /**
     * page limit for pagination
     * @type {number}
     * @memberof DefaultApiGetAnnotationList
     */
    readonly limit: number

    /**
     * values of named entities
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationList
     */
    readonly namedEntityValues?: Array<string>

    /**
     * content ids
     * @type {Array<string>}
     * @memberof DefaultApiGetAnnotationList
     */
    readonly contentIds?: Array<string>
}

/**
 * Request parameters for getAnnotationsOfContent operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAnnotationsOfContentRequest
 */
export interface DefaultApiGetAnnotationsOfContentRequest {
    /**
     * Id of content
     * @type {string}
     * @memberof DefaultApiGetAnnotationsOfContent
     */
    readonly contentId: string

    /**
     * Entity type of content
     * @type {ContentTypeEnum}
     * @memberof DefaultApiGetAnnotationsOfContent
     */
    readonly contentEntityType: ContentTypeEnum
}

/**
 * Request parameters for getApplicationStatusForEntityTypeAndPolicyType operation in DefaultApi.
 * @export
 * @interface DefaultApiGetApplicationStatusForEntityTypeAndPolicyTypeRequest
 */
export interface DefaultApiGetApplicationStatusForEntityTypeAndPolicyTypeRequest {
    /**
     * Entity type
     * @type {string}
     * @memberof DefaultApiGetApplicationStatusForEntityTypeAndPolicyType
     */
    readonly entityType: string

    /**
     * Policy type
     * @type {string}
     * @memberof DefaultApiGetApplicationStatusForEntityTypeAndPolicyType
     */
    readonly policyType: string
}

/**
 * Request parameters for getAuditEvents operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAuditEventsRequest
 */
export interface DefaultApiGetAuditEventsRequest {
    /**
     * Category of audit events.
     * @type {'env' | 'user' | 'trust_feed'}
     * @memberof DefaultApiGetAuditEvents
     */
    readonly category: GetAuditEventsCategoryEnum

    /**
     * Entity Type
     * @type {Array<number>}
     * @memberof DefaultApiGetAuditEvents
     */
    readonly entityType?: Array<number>

    /**
     * Start time filter
     * @type {number}
     * @memberof DefaultApiGetAuditEvents
     */
    readonly startTime?: number

    /**
     * End time filter
     * @type {number}
     * @memberof DefaultApiGetAuditEvents
     */
    readonly endTime?: number

    /**
     * Operation
     * @type {'CREATE' | 'UPDATE' | 'DELETE'}
     * @memberof DefaultApiGetAuditEvents
     */
    readonly operation?: GetAuditEventsOperationEnum
}

/**
 * Request parameters for getCollectors operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCollectorsRequest
 */
export interface DefaultApiGetCollectorsRequest {
    /**
     * Collector ID to filter
     * @type {string}
     * @memberof DefaultApiGetCollectors
     */
    readonly id?: string
}

/**
 * Request parameters for getConfigDetailsByDeployment operation in DefaultApi.
 * @export
 * @interface DefaultApiGetConfigDetailsByDeploymentRequest
 */
export interface DefaultApiGetConfigDetailsByDeploymentRequest {
    /**
     * Deployment Id
     * @type {string}
     * @memberof DefaultApiGetConfigDetailsByDeployment
     */
    readonly deploymentId: string
}

/**
 * Request parameters for getContentFacets operation in DefaultApi.
 * @export
 * @interface DefaultApiGetContentFacetsRequest
 */
export interface DefaultApiGetContentFacetsRequest {
    /**
     * Facet on this property
     * @type {ContentFacetTypeEnum}
     * @memberof DefaultApiGetContentFacets
     */
    readonly facetProperty: ContentFacetTypeEnum

    /**
     * Entity type of content
     * @type {ContentTypeEnum}
     * @memberof DefaultApiGetContentFacets
     */
    readonly contentEntityType: ContentTypeEnum

    /**
     * list of source id to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetContentFacets
     */
    readonly sourceIds?: Array<string>

    /**
     * list of destination id to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetContentFacets
     */
    readonly destinationIds?: Array<string>

    /**
     * list of linked content ids to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetContentFacets
     */
    readonly linkedContentIds?: Array<string>

    /**
     * type of named entities in annotations
     * @type {Array<string>}
     * @memberof DefaultApiGetContentFacets
     */
    readonly namedEntityTypes?: Array<string>

    /**
     * values of named entities in annotations
     * @type {Array<string>}
     * @memberof DefaultApiGetContentFacets
     */
    readonly namedEntityValues?: Array<string>

    /**
     * time range filter
     * @type {number}
     * @memberof DefaultApiGetContentFacets
     */
    readonly startTime?: number

    /**
     * time range filter
     * @type {number}
     * @memberof DefaultApiGetContentFacets
     */
    readonly endTime?: number
}

/**
 * Request parameters for getContentFromId operation in DefaultApi.
 * @export
 * @interface DefaultApiGetContentFromIdRequest
 */
export interface DefaultApiGetContentFromIdRequest {
    /**
     * Id of content
     * @type {string}
     * @memberof DefaultApiGetContentFromId
     */
    readonly contentId: string

    /**
     * Entity type of content
     * @type {ContentTypeEnum}
     * @memberof DefaultApiGetContentFromId
     */
    readonly contentEntityType: ContentTypeEnum
}

/**
 * Request parameters for getContentList operation in DefaultApi.
 * @export
 * @interface DefaultApiGetContentListRequest
 */
export interface DefaultApiGetContentListRequest {
    /**
     * Entity type of content
     * @type {ContentTypeEnum}
     * @memberof DefaultApiGetContentList
     */
    readonly contentEntityType: ContentTypeEnum

    /**
     * offset index for pagination
     * @type {number}
     * @memberof DefaultApiGetContentList
     */
    readonly offset: number

    /**
     * page limit for pagination
     * @type {number}
     * @memberof DefaultApiGetContentList
     */
    readonly limit: number

    /**
     * list of source id to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetContentList
     */
    readonly sourceIds?: Array<string>

    /**
     * list of destination id to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetContentList
     */
    readonly destinationIds?: Array<string>

    /**
     * list of linked content ids to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetContentList
     */
    readonly linkedContentIds?: Array<string>

    /**
     * type of named entities in annotations
     * @type {Array<string>}
     * @memberof DefaultApiGetContentList
     */
    readonly namedEntityTypes?: Array<string>

    /**
     * values of named entities in annotations
     * @type {Array<string>}
     * @memberof DefaultApiGetContentList
     */
    readonly namedEntityValues?: Array<string>

    /**
     * time range filter
     * @type {number}
     * @memberof DefaultApiGetContentList
     */
    readonly startTime?: number

    /**
     * time range filter
     * @type {number}
     * @memberof DefaultApiGetContentList
     */
    readonly endTime?: number

    /**
     * Define the order of content. Sorting parameter is always time of content
     * @type {object}
     * @memberof DefaultApiGetContentList
     */
    readonly sortOrder?: GetContentListSortOrderEnum
}

/**
 * Request parameters for getCoreEntityTypeSchema operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCoreEntityTypeSchemaRequest
 */
export interface DefaultApiGetCoreEntityTypeSchemaRequest {
    /**
     * Entity type
     * @type {string}
     * @memberof DefaultApiGetCoreEntityTypeSchema
     */
    readonly entityType: string
}

/**
 * Request parameters for getCostRecordsByFilterAndGroup operation in DefaultApi.
 * @export
 * @interface DefaultApiGetCostRecordsByFilterAndGroupRequest
 */
export interface DefaultApiGetCostRecordsByFilterAndGroupRequest {
    /**
     * 
     * @type {FilterAndGroupRateCardRequest}
     * @memberof DefaultApiGetCostRecordsByFilterAndGroup
     */
    readonly filterAndGroupRateCardRequest: FilterAndGroupRateCardRequest
}

/**
 * Request parameters for getDataSource operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDataSourceRequest
 */
export interface DefaultApiGetDataSourceRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetDataSource
     */
    readonly datasourceId: string
}

/**
 * Request parameters for getDataSources operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDataSourcesRequest
 */
export interface DefaultApiGetDataSourcesRequest {
    /**
     * Collector ID to filter
     * @type {string}
     * @memberof DefaultApiGetDataSources
     */
    readonly collectorId?: string

    /**
     * Type of data source AWS_INVENTORY, AWS_FLOW_LOGS, AWS_ROUTE53, AWS_EVENT_ACCESS_LOGS, AWS_SAGEMAKER, GCP_INVENTORY, GCP_FLOW_LOGS, GCP_CLOUD_DNS, GCP_EVENT_ACCESS_LOGS, GCP_VERTEX_AI
     * @type {string}
     * @memberof DefaultApiGetDataSources
     */
    readonly datasourceType?: string

    /**
     * Vendor name to filter
     * @type {string}
     * @memberof DefaultApiGetDataSources
     */
    readonly vendor?: string
}

/**
 * Request parameters for getEntityFacets operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityFacetsRequest
 */
export interface DefaultApiGetEntityFacetsRequest {
    /**
     * Entity Type
     * @type {number}
     * @memberof DefaultApiGetEntityFacets
     */
    readonly entityType: number

    /**
     * Property to fetch facets
     * @type {string}
     * @memberof DefaultApiGetEntityFacets
     */
    readonly facetProperty: string

    /**
     * Return facet count
     * @type {boolean}
     * @memberof DefaultApiGetEntityFacets
     */
    readonly includeCount?: boolean

    /**
     * Query Id to limit facets
     * @type {string}
     * @memberof DefaultApiGetEntityFacets
     */
    readonly queryId?: string

    /**
     * 
     * @type {GetFacetDataParam}
     * @memberof DefaultApiGetEntityFacets
     */
    readonly getFacetDataParam?: GetFacetDataParam
}

/**
 * Request parameters for getEntityMappings operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityMappingsRequest
 */
export interface DefaultApiGetEntityMappingsRequest {
    /**
     * Entity Type
     * @type {number}
     * @memberof DefaultApiGetEntityMappings
     */
    readonly entityType: number

    /**
     * Entity Type to map
     * @type {number}
     * @memberof DefaultApiGetEntityMappings
     */
    readonly mapToEntityType: number
}

/**
 * Request parameters for getEntityMappingsByReference operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityMappingsByReferenceRequest
 */
export interface DefaultApiGetEntityMappingsByReferenceRequest {
    /**
     * Entity Type
     * @type {number}
     * @memberof DefaultApiGetEntityMappingsByReference
     */
    readonly destEntityType: number

    /**
     * 
     * @type {Array<EntityReference>}
     * @memberof DefaultApiGetEntityMappingsByReference
     */
    readonly entityReference: Array<EntityReference>
}

/**
 * Request parameters for getEntityPage operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityPageRequest
 */
export interface DefaultApiGetEntityPageRequest {
    /**
     * Entity Type
     * @type {number}
     * @memberof DefaultApiGetEntityPage
     */
    readonly entityType: number

    /**
     * Query id to fetch data
     * @type {string}
     * @memberof DefaultApiGetEntityPage
     */
    readonly queryId: string

    /**
     * 
     * @type {GetEntityDataParam}
     * @memberof DefaultApiGetEntityPage
     */
    readonly getEntityDataParam: GetEntityDataParam
}

/**
 * Request parameters for getEntityTrend operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityTrendRequest
 */
export interface DefaultApiGetEntityTrendRequest {
    /**
     * 
     * @type {EntityTrendRequest}
     * @memberof DefaultApiGetEntityTrend
     */
    readonly entityTrendRequest?: EntityTrendRequest
}

/**
 * Request parameters for getEntityTypeSchema operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityTypeSchemaRequest
 */
export interface DefaultApiGetEntityTypeSchemaRequest {
    /**
     * Entity type
     * @type {string}
     * @memberof DefaultApiGetEntityTypeSchema
     */
    readonly entityType: string
}

/**
 * Request parameters for getEnumTypeSchema operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEnumTypeSchemaRequest
 */
export interface DefaultApiGetEnumTypeSchemaRequest {
    /**
     * Enum type
     * @type {string}
     * @memberof DefaultApiGetEnumTypeSchema
     */
    readonly enumType: string
}

/**
 * Request parameters for getEventFacets operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEventFacetsRequest
 */
export interface DefaultApiGetEventFacetsRequest {
    /**
     * Facet on this property
     * @type {EventFacetTypeEnum}
     * @memberof DefaultApiGetEventFacets
     */
    readonly facetProperty: EventFacetTypeEnum

    /**
     * Event entity types to filter
     * @type {EventType}
     * @memberof DefaultApiGetEventFacets
     */
    readonly eventType?: EventType

    /**
     * Event entity types to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetEventFacets
     */
    readonly entityType?: Array<string>

    /**
     * Anchor entity types to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetEventFacets
     */
    readonly anchorEntityTypes?: Array<string>

    /**
     * Anchor entity ids to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetEventFacets
     */
    readonly anchorEntityIds?: Array<string>

    /**
     * Event status to filter
     * @type {EventStatus}
     * @memberof DefaultApiGetEventFacets
     */
    readonly eventStatus?: EventStatus

    /**
     * Event severity to filter
     * @type {Array<EventSeverity>}
     * @memberof DefaultApiGetEventFacets
     */
    readonly eventSeverities?: Array<EventSeverity>

    /**
     * Event category to filter
     * @type {Array<string>}
     * @memberof DefaultApiGetEventFacets
     */
    readonly categories?: Array<string>
}

/**
 * Request parameters for getEventToContentNavigationMetadata operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEventToContentNavigationMetadataRequest
 */
export interface DefaultApiGetEventToContentNavigationMetadataRequest {
    /**
     * 
     * @type {NavigationRequests}
     * @memberof DefaultApiGetEventToContentNavigationMetadata
     */
    readonly navigationRequests: NavigationRequests
}

/**
 * Request parameters for getLifecycleEvents operation in DefaultApi.
 * @export
 * @interface DefaultApiGetLifecycleEventsRequest
 */
export interface DefaultApiGetLifecycleEventsRequest {
    /**
     * Entity Type
     * @type {number}
     * @memberof DefaultApiGetLifecycleEvents
     */
    readonly entityType?: number

    /**
     * Entity id to fetch logs
     * @type {string}
     * @memberof DefaultApiGetLifecycleEvents
     */
    readonly entityId?: string
}

/**
 * Request parameters for getLinkedJiraTicket operation in DefaultApi.
 * @export
 * @interface DefaultApiGetLinkedJiraTicketRequest
 */
export interface DefaultApiGetLinkedJiraTicketRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiGetLinkedJiraTicket
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiGetLinkedJiraTicket
     */
    readonly entityId: string
}

/**
 * Request parameters for getMachineEventsList operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMachineEventsListRequest
 */
export interface DefaultApiGetMachineEventsListRequest {
    /**
     * 
     * @type {MachineEventRequest}
     * @memberof DefaultApiGetMachineEventsList
     */
    readonly machineEventRequest?: MachineEventRequest
}

/**
 * Request parameters for getMachineEventsListTimeSeries operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMachineEventsListTimeSeriesRequest
 */
export interface DefaultApiGetMachineEventsListTimeSeriesRequest {
    /**
     * 
     * @type {MachineEventTimeSeriesRequest}
     * @memberof DefaultApiGetMachineEventsListTimeSeries
     */
    readonly machineEventTimeSeriesRequest?: MachineEventTimeSeriesRequest
}

/**
 * Request parameters for getMetricSeries operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMetricSeriesRequest
 */
export interface DefaultApiGetMetricSeriesRequest {
    /**
     * 
     * @type {MetricSeriesQueryRequest}
     * @memberof DefaultApiGetMetricSeries
     */
    readonly metricSeriesQueryRequest?: MetricSeriesQueryRequest
}

/**
 * Request parameters for getMetricSeriesForAIService operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMetricSeriesForAIServiceRequest
 */
export interface DefaultApiGetMetricSeriesForAIServiceRequest {
    /**
     * 
     * @type {MetricSeriesQueryRequest}
     * @memberof DefaultApiGetMetricSeriesForAIService
     */
    readonly metricSeriesQueryRequest?: MetricSeriesQueryRequest
}

/**
 * Request parameters for getMetricTagValues operation in DefaultApi.
 * @export
 * @interface DefaultApiGetMetricTagValuesRequest
 */
export interface DefaultApiGetMetricTagValuesRequest {
    /**
     * 
     * @type {MetricTagListRequest}
     * @memberof DefaultApiGetMetricTagValues
     */
    readonly metricTagListRequest?: MetricTagListRequest
}

/**
 * Request parameters for getNoteById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetNoteByIdRequest
 */
export interface DefaultApiGetNoteByIdRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiGetNoteById
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiGetNoteById
     */
    readonly entityId: string

    /**
     * Note ID
     * @type {string}
     * @memberof DefaultApiGetNoteById
     */
    readonly noteId: string
}

/**
 * Request parameters for getOnboardedItemsActivity operation in DefaultApi.
 * @export
 * @interface DefaultApiGetOnboardedItemsActivityRequest
 */
export interface DefaultApiGetOnboardedItemsActivityRequest {
    /**
     * 
     * @type {Array<EntityRef>}
     * @memberof DefaultApiGetOnboardedItemsActivity
     */
    readonly entityRef: Array<EntityRef>
}

/**
 * Request parameters for getOnboardedItemsHeaderInfo operation in DefaultApi.
 * @export
 * @interface DefaultApiGetOnboardedItemsHeaderInfoRequest
 */
export interface DefaultApiGetOnboardedItemsHeaderInfoRequest {
    /**
     * 
     * @type {Array<EntityRef>}
     * @memberof DefaultApiGetOnboardedItemsHeaderInfo
     */
    readonly entityRef: Array<EntityRef>
}

/**
 * Request parameters for getPolices operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPolicesRequest
 */
export interface DefaultApiGetPolicesRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetPolices
     */
    readonly authorization?: string
}

/**
 * Request parameters for getPolicyAttributeValues operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPolicyAttributeValuesRequest
 */
export interface DefaultApiGetPolicyAttributeValuesRequest {
    /**
     * Applicable entity type for this policy type.
     * @type {string}
     * @memberof DefaultApiGetPolicyAttributeValues
     */
    readonly applicableEntityType: string

    /**
     * Attribute name
     * @type {string}
     * @memberof DefaultApiGetPolicyAttributeValues
     */
    readonly attributeName?: string
}

/**
 * Request parameters for getPreference operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPreferenceRequest
 */
export interface DefaultApiGetPreferenceRequest {
    /**
     * Entity Id
     * @type {string}
     * @memberof DefaultApiGetPreference
     */
    readonly entityId: string

    /**
     * Preference Id
     * @type {string}
     * @memberof DefaultApiGetPreference
     */
    readonly preferenceId: string
}

/**
 * Request parameters for getPreferences operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPreferencesRequest
 */
export interface DefaultApiGetPreferencesRequest {
    /**
     * Entity Id
     * @type {string}
     * @memberof DefaultApiGetPreferences
     */
    readonly entityId: string
}

/**
 * Request parameters for getRecommendationCostForEntity operation in DefaultApi.
 * @export
 * @interface DefaultApiGetRecommendationCostForEntityRequest
 */
export interface DefaultApiGetRecommendationCostForEntityRequest {
    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiGetRecommendationCostForEntity
     */
    readonly entityId: string

    /**
     * Entity Type
     * @type {string}
     * @memberof DefaultApiGetRecommendationCostForEntity
     */
    readonly entityType: string

    /**
     * Deployment Entity Type
     * @type {string}
     * @memberof DefaultApiGetRecommendationCostForEntity
     */
    readonly deploymentEntityType: string

    /**
     * Start time
     * @type {number}
     * @memberof DefaultApiGetRecommendationCostForEntity
     */
    readonly startTime: number

    /**
     * End time
     * @type {number}
     * @memberof DefaultApiGetRecommendationCostForEntity
     */
    readonly endTime: number

    /**
     * Entity Name
     * @type {string}
     * @memberof DefaultApiGetRecommendationCostForEntity
     */
    readonly entityName?: string
}

/**
 * Request parameters for getRegions operation in DefaultApi.
 * @export
 * @interface DefaultApiGetRegionsRequest
 */
export interface DefaultApiGetRegionsRequest {
    /**
     * Vendor name to filter
     * @type {string}
     * @memberof DefaultApiGetRegions
     */
    readonly vendorName: string

    /**
     * Region sub-type to filter
     * @type {string}
     * @memberof DefaultApiGetRegions
     */
    readonly regionSubtype?: string
}

/**
 * Request parameters for getRuntimeConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiGetRuntimeConfigRequest
 */
export interface DefaultApiGetRuntimeConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRuntimeConfig
     */
    readonly agentId?: string
}

/**
 * Request parameters for getSavingsCostForEntityGroup operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSavingsCostForEntityGroupRequest
 */
export interface DefaultApiGetSavingsCostForEntityGroupRequest {
    /**
     * Entity Group Type
     * @type {string}
     * @memberof DefaultApiGetSavingsCostForEntityGroup
     */
    readonly entityGroupType: string

    /**
     * Start time
     * @type {number}
     * @memberof DefaultApiGetSavingsCostForEntityGroup
     */
    readonly startTime: number

    /**
     * End time
     * @type {number}
     * @memberof DefaultApiGetSavingsCostForEntityGroup
     */
    readonly endTime: number

    /**
     * List of Entity Group Names
     * @type {Array<string>}
     * @memberof DefaultApiGetSavingsCostForEntityGroup
     */
    readonly entityGroupNames?: Array<string>
}

/**
 * Request parameters for getSettings operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSettingsRequest
 */
export interface DefaultApiGetSettingsRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiGetSettings
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiGetSettings
     */
    readonly entityId: string
}

/**
 * Request parameters for getSettingsConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSettingsConfigRequest
 */
export interface DefaultApiGetSettingsConfigRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiGetSettingsConfig
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiGetSettingsConfig
     */
    readonly entityId: string
}

/**
 * Request parameters for getSoftwareVulnerabilities operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSoftwareVulnerabilitiesRequest
 */
export interface DefaultApiGetSoftwareVulnerabilitiesRequest {
    /**
     * Entity Type
     * @type {number}
     * @memberof DefaultApiGetSoftwareVulnerabilities
     */
    readonly entityType: number

    /**
     * Entity Id
     * @type {string}
     * @memberof DefaultApiGetSoftwareVulnerabilities
     */
    readonly entityId: string
}

/**
 * Request parameters for getSupportedPreferences operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSupportedPreferencesRequest
 */
export interface DefaultApiGetSupportedPreferencesRequest {
    /**
     * Entity Type
     * @type {number}
     * @memberof DefaultApiGetSupportedPreferences
     */
    readonly entityType: number
}

/**
 * Request parameters for getTopicsApprovalStatus operation in DefaultApi.
 * @export
 * @interface DefaultApiGetTopicsApprovalStatusRequest
 */
export interface DefaultApiGetTopicsApprovalStatusRequest {
    /**
     * 
     * @type {Topics}
     * @memberof DefaultApiGetTopicsApprovalStatus
     */
    readonly topics: Topics
}

/**
 * Request parameters for getUnverifiedAiService operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUnverifiedAiServiceRequest
 */
export interface DefaultApiGetUnverifiedAiServiceRequest {
    /**
     * 
     * @type {GetEntityDataParam}
     * @memberof DefaultApiGetUnverifiedAiService
     */
    readonly getEntityDataParam: GetEntityDataParam
}

/**
 * Request parameters for getUsageCostForEntity operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUsageCostForEntityRequest
 */
export interface DefaultApiGetUsageCostForEntityRequest {
    /**
     * List of entities
     * @type {string}
     * @memberof DefaultApiGetUsageCostForEntity
     */
    readonly entityId: string

    /**
     * Entity Type
     * @type {string}
     * @memberof DefaultApiGetUsageCostForEntity
     */
    readonly entityType: string

    /**
     * Start time
     * @type {number}
     * @memberof DefaultApiGetUsageCostForEntity
     */
    readonly startTime: number

    /**
     * End time
     * @type {number}
     * @memberof DefaultApiGetUsageCostForEntity
     */
    readonly endTime: number
}

/**
 * Request parameters for getUsageCostForEntityGroup operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUsageCostForEntityGroupRequest
 */
export interface DefaultApiGetUsageCostForEntityGroupRequest {
    /**
     * 
     * @type {GroupUsageCostRequest}
     * @memberof DefaultApiGetUsageCostForEntityGroup
     */
    readonly groupUsageCostRequest: GroupUsageCostRequest
}

/**
 * Request parameters for getUserRequestsForEntity operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUserRequestsForEntityRequest
 */
export interface DefaultApiGetUserRequestsForEntityRequest {
    /**
     * 
     * @type {EntityRef}
     * @memberof DefaultApiGetUserRequestsForEntity
     */
    readonly entityRef: EntityRef
}

/**
 * Request parameters for graphEntityTypeEntityIdGraphTypeGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGraphEntityTypeEntityIdGraphTypeGetRequest
 */
export interface DefaultApiGraphEntityTypeEntityIdGraphTypeGetRequest {
    /**
     * The type of the Entity
     * @type {string}
     * @memberof DefaultApiGraphEntityTypeEntityIdGraphTypeGet
     */
    readonly entityType: string

    /**
     * The ID of the Entity
     * @type {string}
     * @memberof DefaultApiGraphEntityTypeEntityIdGraphTypeGet
     */
    readonly entityId: string

    /**
     * The type of graph
     * @type {string}
     * @memberof DefaultApiGraphEntityTypeEntityIdGraphTypeGet
     */
    readonly graphType: string
}

/**
 * Request parameters for ingestData operation in DefaultApi.
 * @export
 * @interface DefaultApiIngestDataRequest
 */
export interface DefaultApiIngestDataRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiIngestData
     */
    readonly authorization?: string

    /**
     * 
     * @type {object}
     * @memberof DefaultApiIngestData
     */
    readonly body?: object
}

/**
 * Request parameters for listBrowserAgents operation in DefaultApi.
 * @export
 * @interface DefaultApiListBrowserAgentsRequest
 */
export interface DefaultApiListBrowserAgentsRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultApiListBrowserAgents
     */
    readonly start: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiListBrowserAgents
     */
    readonly limit: number
}

/**
 * Request parameters for listNotes operation in DefaultApi.
 * @export
 * @interface DefaultApiListNotesRequest
 */
export interface DefaultApiListNotesRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiListNotes
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiListNotes
     */
    readonly entityId: string
}

/**
 * Request parameters for listTags operation in DefaultApi.
 * @export
 * @interface DefaultApiListTagsRequest
 */
export interface DefaultApiListTagsRequest {
    /**
     * Filter tags by key
     * @type {string}
     * @memberof DefaultApiListTags
     */
    readonly key?: string
}

/**
 * Request parameters for messagesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMessagesGetRequest
 */
export interface DefaultApiMessagesGetRequest {
    /**
     * Language for the messages (e.g., \&#39;en\&#39;, \&#39;es\&#39;, \&#39;fr\&#39;). Defaults to \&#39;en\&#39;.
     * @type {string}
     * @memberof DefaultApiMessagesGet
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for messagesKeyGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMessagesKeyGetRequest
 */
export interface DefaultApiMessagesKeyGetRequest {
    /**
     * The key for the message to be retrieved.
     * @type {string}
     * @memberof DefaultApiMessagesKeyGet
     */
    readonly key: string

    /**
     * Language for the message (e.g., \&#39;en\&#39;, \&#39;es\&#39;, \&#39;fr\&#39;). Defaults to \&#39;en\&#39;.
     * @type {string}
     * @memberof DefaultApiMessagesKeyGet
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for offboardEntities operation in DefaultApi.
 * @export
 * @interface DefaultApiOffboardEntitiesRequest
 */
export interface DefaultApiOffboardEntitiesRequest {
    /**
     * 
     * @type {Array<EntityRef>}
     * @memberof DefaultApiOffboardEntities
     */
    readonly entityRef: Array<EntityRef>
}

/**
 * Request parameters for onboardEntities operation in DefaultApi.
 * @export
 * @interface DefaultApiOnboardEntitiesRequest
 */
export interface DefaultApiOnboardEntitiesRequest {
    /**
     * 
     * @type {Array<EntityRef>}
     * @memberof DefaultApiOnboardEntities
     */
    readonly entityRef: Array<EntityRef>
}

/**
 * Request parameters for policiesApplicationStatusPost operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesApplicationStatusPostRequest
 */
export interface DefaultApiPoliciesApplicationStatusPostRequest {
    /**
     * 
     * @type {Array<EntityRef>}
     * @memberof DefaultApiPoliciesApplicationStatusPost
     */
    readonly entityRef: Array<EntityRef>
}

/**
 * Request parameters for policiesApprovePost operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesApprovePostRequest
 */
export interface DefaultApiPoliciesApprovePostRequest {
    /**
     * 
     * @type {EntityRefs}
     * @memberof DefaultApiPoliciesApprovePost
     */
    readonly entityRefs: EntityRefs
}

/**
 * Request parameters for policiesPolicyIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesPolicyIdDeleteRequest
 */
export interface DefaultApiPoliciesPolicyIdDeleteRequest {
    /**
     * Policy ID
     * @type {string}
     * @memberof DefaultApiPoliciesPolicyIdDelete
     */
    readonly policyId: string
}

/**
 * Request parameters for policiesPolicyIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesPolicyIdGetRequest
 */
export interface DefaultApiPoliciesPolicyIdGetRequest {
    /**
     * Policy ID
     * @type {string}
     * @memberof DefaultApiPoliciesPolicyIdGet
     */
    readonly policyId: string
}

/**
 * Request parameters for policiesPolicyIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesPolicyIdPutRequest
 */
export interface DefaultApiPoliciesPolicyIdPutRequest {
    /**
     * Policy ID
     * @type {string}
     * @memberof DefaultApiPoliciesPolicyIdPut
     */
    readonly policyId: string

    /**
     * 
     * @type {PolicyData}
     * @memberof DefaultApiPoliciesPolicyIdPut
     */
    readonly policyData: PolicyData
}

/**
 * Request parameters for policiesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesPostRequest
 */
export interface DefaultApiPoliciesPostRequest {
    /**
     * 
     * @type {PolicyData}
     * @memberof DefaultApiPoliciesPost
     */
    readonly policyData: PolicyData
}

/**
 * Request parameters for policiesPropertyApprovalStatusChangePost operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesPropertyApprovalStatusChangePostRequest
 */
export interface DefaultApiPoliciesPropertyApprovalStatusChangePostRequest {
    /**
     * 
     * @type {ApprovalStatusChange}
     * @memberof DefaultApiPoliciesPropertyApprovalStatusChangePost
     */
    readonly approvalStatusChange: ApprovalStatusChange
}

/**
 * Request parameters for policiesPropertyApprovePost operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesPropertyApprovePostRequest
 */
export interface DefaultApiPoliciesPropertyApprovePostRequest {
    /**
     * 
     * @type {PropertyValues}
     * @memberof DefaultApiPoliciesPropertyApprovePost
     */
    readonly propertyValues: PropertyValues
}

/**
 * Request parameters for policiesPropertyUnapprovePost operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesPropertyUnapprovePostRequest
 */
export interface DefaultApiPoliciesPropertyUnapprovePostRequest {
    /**
     * 
     * @type {PropertyValues}
     * @memberof DefaultApiPoliciesPropertyUnapprovePost
     */
    readonly propertyValues: PropertyValues
}

/**
 * Request parameters for policiesUnapprovePost operation in DefaultApi.
 * @export
 * @interface DefaultApiPoliciesUnapprovePostRequest
 */
export interface DefaultApiPoliciesUnapprovePostRequest {
    /**
     * 
     * @type {EntityRefs}
     * @memberof DefaultApiPoliciesUnapprovePost
     */
    readonly entityRefs: EntityRefs
}

/**
 * Request parameters for policyContextsPolicyCtxIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPolicyContextsPolicyCtxIdGetRequest
 */
export interface DefaultApiPolicyContextsPolicyCtxIdGetRequest {
    /**
     * Policy Context ID
     * @type {string}
     * @memberof DefaultApiPolicyContextsPolicyCtxIdGet
     */
    readonly policyCtxId: string
}

/**
 * Request parameters for policyContextsPolicyCtxIdPolicySuggestionsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPolicyContextsPolicyCtxIdPolicySuggestionsGetRequest
 */
export interface DefaultApiPolicyContextsPolicyCtxIdPolicySuggestionsGetRequest {
    /**
     * Policy Context ID
     * @type {string}
     * @memberof DefaultApiPolicyContextsPolicyCtxIdPolicySuggestionsGet
     */
    readonly policyCtxId: string
}

/**
 * Request parameters for policyContextsPolicyCtxIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiPolicyContextsPolicyCtxIdPutRequest
 */
export interface DefaultApiPolicyContextsPolicyCtxIdPutRequest {
    /**
     * Policy Context ID
     * @type {string}
     * @memberof DefaultApiPolicyContextsPolicyCtxIdPut
     */
    readonly policyCtxId: string

    /**
     * 
     * @type {PolicyContextData}
     * @memberof DefaultApiPolicyContextsPolicyCtxIdPut
     */
    readonly policyContextData: PolicyContextData
}

/**
 * Request parameters for policyContextsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiPolicyContextsPostRequest
 */
export interface DefaultApiPolicyContextsPostRequest {
    /**
     * 
     * @type {PolicyContextData}
     * @memberof DefaultApiPolicyContextsPost
     */
    readonly policyContextData: PolicyContextData
}

/**
 * Request parameters for policyPreview operation in DefaultApi.
 * @export
 * @interface DefaultApiPolicyPreviewRequest
 */
export interface DefaultApiPolicyPreviewRequest {
    /**
     * Condition Met Flag false indicates negation of condition
     * @type {boolean}
     * @memberof DefaultApiPolicyPreview
     */
    readonly conditionMetFlag: boolean

    /**
     * 
     * @type {PolicyData}
     * @memberof DefaultApiPolicyPreview
     */
    readonly policyData: PolicyData
}

/**
 * Request parameters for policySummary operation in DefaultApi.
 * @export
 * @interface DefaultApiPolicySummaryRequest
 */
export interface DefaultApiPolicySummaryRequest {
    /**
     * 
     * @type {PolicySummaryRequest}
     * @memberof DefaultApiPolicySummary
     */
    readonly policySummaryRequest: PolicySummaryRequest
}

/**
 * Request parameters for registerUser operation in DefaultApi.
 * @export
 * @interface DefaultApiRegisterUserRequest
 */
export interface DefaultApiRegisterUserRequest {
    /**
     * 
     * @type {RegisterUserRequest}
     * @memberof DefaultApiRegisterUser
     */
    readonly registerUserRequest?: RegisterUserRequest
}

/**
 * Request parameters for setUpdateSecrets operation in DefaultApi.
 * @export
 * @interface DefaultApiSetUpdateSecretsRequest
 */
export interface DefaultApiSetUpdateSecretsRequest {
    /**
     * 
     * @type {CustomerSecretInput}
     * @memberof DefaultApiSetUpdateSecrets
     */
    readonly customerSecretInput: CustomerSecretInput
}

/**
 * Request parameters for updateConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateConfigRequest
 */
export interface DefaultApiUpdateConfigRequest {
    /**
     * 
     * @type {UpdateConfigRequest}
     * @memberof DefaultApiUpdateConfig
     */
    readonly updateConfigRequest?: UpdateConfigRequest
}

/**
 * Request parameters for updateConfigByDeployment operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateConfigByDeploymentRequest
 */
export interface DefaultApiUpdateConfigByDeploymentRequest {
    /**
     * Deployment Id
     * @type {string}
     * @memberof DefaultApiUpdateConfigByDeployment
     */
    readonly deploymentId: string

    /**
     * 
     * @type {UpdateConfigRequest}
     * @memberof DefaultApiUpdateConfigByDeployment
     */
    readonly updateConfigRequest?: UpdateConfigRequest
}

/**
 * Request parameters for updateDataSource operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateDataSourceRequest
 */
export interface DefaultApiUpdateDataSourceRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUpdateDataSource
     */
    readonly datasourceId: string

    /**
     * 
     * @type {DatasourceConfig}
     * @memberof DefaultApiUpdateDataSource
     */
    readonly datasourceConfig: DatasourceConfig
}

/**
 * Request parameters for updateGeneralSettings operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateGeneralSettingsRequest
 */
export interface DefaultApiUpdateGeneralSettingsRequest {
    /**
     * 
     * @type {TenantGeneralSettingsUpdate}
     * @memberof DefaultApiUpdateGeneralSettings
     */
    readonly tenantGeneralSettingsUpdate: TenantGeneralSettingsUpdate
}

/**
 * Request parameters for updateNote operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateNoteRequest
 */
export interface DefaultApiUpdateNoteRequest {
    /**
     * Entity type
     * @type {number}
     * @memberof DefaultApiUpdateNote
     */
    readonly entityType: number

    /**
     * Entity ID
     * @type {string}
     * @memberof DefaultApiUpdateNote
     */
    readonly entityId: string

    /**
     * Note ID
     * @type {string}
     * @memberof DefaultApiUpdateNote
     */
    readonly noteId: string

    /**
     * 
     * @type {UpdateNoteRequest}
     * @memberof DefaultApiUpdateNote
     */
    readonly updateNoteRequest: UpdateNoteRequest
}

/**
 * Request parameters for updatePreference operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdatePreferenceRequest
 */
export interface DefaultApiUpdatePreferenceRequest {
    /**
     * Entity Id
     * @type {string}
     * @memberof DefaultApiUpdatePreference
     */
    readonly entityId: string

    /**
     * Preference Id
     * @type {string}
     * @memberof DefaultApiUpdatePreference
     */
    readonly preferenceId: string

    /**
     * 
     * @type {Preference}
     * @memberof DefaultApiUpdatePreference
     */
    readonly preference: Preference
}

/**
 * Request parameters for updateSensitiveDataConfig operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateSensitiveDataConfigRequest
 */
export interface DefaultApiUpdateSensitiveDataConfigRequest {
    /**
     * Sensitive Data Config ID
     * @type {string}
     * @memberof DefaultApiUpdateSensitiveDataConfig
     */
    readonly configId: string

    /**
     * 
     * @type {SensitiveDataConfigUpdate}
     * @memberof DefaultApiUpdateSensitiveDataConfig
     */
    readonly sensitiveDataConfigUpdate: SensitiveDataConfigUpdate
}

/**
 * Request parameters for updateUserRequestForEntity operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateUserRequestForEntityRequest
 */
export interface DefaultApiUpdateUserRequestForEntityRequest {
    /**
     * 
     * @type {EntityUserRequest}
     * @memberof DefaultApiUpdateUserRequestForEntity
     */
    readonly entityUserRequest: EntityUserRequest
}

/**
 * Request parameters for updateUserRole operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateUserRoleRequest
 */
export interface DefaultApiUpdateUserRoleRequest {
    /**
     * User Id
     * @type {string}
     * @memberof DefaultApiUpdateUserRole
     */
    readonly userId: string

    /**
     * 
     * @type {UserUpdateRequest}
     * @memberof DefaultApiUpdateUserRole
     */
    readonly userUpdateRequest: UserUpdateRequest
}

/**
 * Request parameters for uploadCsvDataSource operation in DefaultApi.
 * @export
 * @interface DefaultApiUploadCsvDataSourceRequest
 */
export interface DefaultApiUploadCsvDataSourceRequest {
    /**
     * The CSV file to upload
     * @type {File}
     * @memberof DefaultApiUploadCsvDataSource
     */
    readonly file?: File

    /**
     * The type of CSV being uploaded
     * @type {string}
     * @memberof DefaultApiUploadCsvDataSource
     */
    readonly csvType?: UploadCsvDataSourceCsvTypeEnum
}

/**
 * Request parameters for usingCustomerKey operation in DefaultApi.
 * @export
 * @interface DefaultApiUsingCustomerKeyRequest
 */
export interface DefaultApiUsingCustomerKeyRequest {
    /**
     * The name of the secret to check for
     * @type {string}
     * @memberof DefaultApiUsingCustomerKey
     */
    readonly secretName: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Browser Agent Health Check
     * @summary Health Check
     * @param {DefaultApiAgentHealthCheckRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public agentHealthCheck(requestParameters: DefaultApiAgentHealthCheckRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).agentHealthCheck(requestParameters.authorization, requestParameters.version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attach a tag to a resource
     * @param {DefaultApiAttachTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public attachTag(requestParameters: DefaultApiAttachTagRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).attachTag(requestParameters.attachTagRequestInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authenticate browser agent
     * @summary Authenticate browser agent
     * @param {DefaultApiBrowserAgentAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public browserAgentAuth(requestParameters: DefaultApiBrowserAgentAuthRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).browserAgentAuth(requestParameters.browserAgentAuthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get LLM Lineage
     * @param {DefaultApiComponentsLlmLineagePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public componentsLlmLineagePost(requestParameters: DefaultApiComponentsLlmLineagePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).componentsLlmLineagePost(requestParameters.lLMLineageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new collector
     * @param {DefaultApiCreateCollectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCollector(requestParameters: DefaultApiCreateCollectorRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCollector(requestParameters.createCollectorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate collector deployment file for kubernetes
     * @param {DefaultApiCreateCollectorK8sClusterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCollectorK8sCluster(requestParameters: DefaultApiCreateCollectorK8sClusterRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCollectorK8sCluster(requestParameters.createCollectorK8sClusterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create note
     * @param {DefaultApiCreateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createNote(requestParameters: DefaultApiCreateNoteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createNote(requestParameters.entityType, requestParameters.entityId, requestParameters.createNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or link jira ticket
     * @param {DefaultApiCreateOrLinkJiraTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrLinkJiraTicket(requestParameters: DefaultApiCreateOrLinkJiraTicketRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOrLinkJiraTicket(requestParameters.entityType, requestParameters.entityId, requestParameters.jiraRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create jira config
     * @param {DefaultApiCreateOrUpdateJiraConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrUpdateJiraConfig(requestParameters: DefaultApiCreateOrUpdateJiraConfigRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOrUpdateJiraConfig(requestParameters.jiraConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a PII data storage config
     * @param {DefaultApiCreateOrUpdatePiiConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrUpdatePiiConfig(requestParameters: DefaultApiCreateOrUpdatePiiConfigRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOrUpdatePiiConfig(requestParameters.piiConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update settings
     * @param {DefaultApiCreateOrUpdateSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrUpdateSettings(requestParameters: DefaultApiCreateOrUpdateSettingsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOrUpdateSettings(requestParameters.entityType, requestParameters.entityId, requestParameters.settingsObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a SMTP data storage config
     * @param {DefaultApiCreateOrUpdateSmtpConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrUpdateSmtpConfig(requestParameters: DefaultApiCreateOrUpdateSmtpConfigRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOrUpdateSmtpConfig(requestParameters.emailConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create policy based on onboarding user request
     * @param {DefaultApiCreatePolicyForUserRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPolicyForUserRequest(requestParameters: DefaultApiCreatePolicyForUserRequestRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPolicyForUserRequest(requestParameters.userRequestPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create preference for provided entity
     * @param {DefaultApiCreatePreferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPreference(requestParameters: DefaultApiCreatePreferenceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPreference(requestParameters.entityId, requestParameters.preference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a sensitive data config
     * @param {DefaultApiCreateSensitiveDataConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSensitiveDataConfig(requestParameters: DefaultApiCreateSensitiveDataConfigRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSensitiveDataConfig(requestParameters.sensitiveDataConfigCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new tag / Update existing tag with new values if tag already exists;
     * @param {DefaultApiCreateTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTag(requestParameters: DefaultApiCreateTagRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTag(requestParameters.newTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new tenant
     * @param {DefaultApiCreateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTenant(requestParameters: DefaultApiCreateTenantRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTenant(requestParameters.createTenantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User Request for approval for a use case involving a AI entity
     * @param {DefaultApiCreateUserRequestForEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUserRequestForEntity(requestParameters: DefaultApiCreateUserRequestForEntityRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUserRequestForEntity(requestParameters.entityUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a datasource
     * @param {DefaultApiDatasourcesDatasourceIdStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public datasourcesDatasourceIdStartPost(requestParameters: DefaultApiDatasourcesDatasourceIdStartPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).datasourcesDatasourceIdStartPost(requestParameters.datasourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop a datasource
     * @param {DefaultApiDatasourcesDatasourceIdStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public datasourcesDatasourceIdStopPost(requestParameters: DefaultApiDatasourcesDatasourceIdStopPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).datasourcesDatasourceIdStopPost(requestParameters.datasourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new data source
     * @param {DefaultApiDatasourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public datasourcesPost(requestParameters: DefaultApiDatasourcesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).datasourcesPost(requestParameters.datasourceConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete collector
     * @param {DefaultApiDeleteCollectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCollector(requestParameters: DefaultApiDeleteCollectorRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteCollector(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a data source
     * @param {DefaultApiDeleteDataSourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDataSource(requestParameters: DefaultApiDeleteDataSourceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteDataSource(requestParameters.datasourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete jira config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJiraConfig(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteJiraConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete jira link for the entity.
     * @param {DefaultApiDeleteJiraLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJiraLink(requestParameters: DefaultApiDeleteJiraLinkRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteJiraLink(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete note for ID.
     * @param {DefaultApiDeleteNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteNote(requestParameters: DefaultApiDeleteNoteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteNote(requestParameters.entityType, requestParameters.entityId, requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete preference for provided id
     * @param {DefaultApiDeletePreferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePreference(requestParameters: DefaultApiDeletePreferenceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePreference(requestParameters.entityId, requestParameters.preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User request
     * @param {DefaultApiDeleteUserRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserRequest(requestParameters: DefaultApiDeleteUserRequestRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteUserRequest(requestParameters.deleteUserRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detach a tag from a resource
     * @param {DefaultApiDetachTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detachTag(requestParameters: DefaultApiDetachTagRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).detachTag(requestParameters.detachTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable a policy
     * @param {DefaultApiDisablePolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public disablePolicy(requestParameters: DefaultApiDisablePolicyRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).disablePolicy(requestParameters.policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download a CSV file for a data source
     * @param {DefaultApiDownloadCsvDataSourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadCsvDataSource(requestParameters: DefaultApiDownloadCsvDataSourceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadCsvDataSource(requestParameters.csvConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable Browser Agent
     * @summary Enable Browser Agent
     * @param {DefaultApiEnableBrowserAgentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public enableBrowserAgent(requestParameters: DefaultApiEnableBrowserAgentRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).enableBrowserAgent(requestParameters.enableBrowserAgentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable a policy
     * @param {DefaultApiEnablePolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public enablePolicy(requestParameters: DefaultApiEnablePolicyRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).enablePolicy(requestParameters.policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List events
     * @param {DefaultApiEventsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public eventsGet(requestParameters: DefaultApiEventsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).eventsGet(requestParameters.eventType, requestParameters.entityType, requestParameters.anchorEntityTypes, requestParameters.anchorEntityIds, requestParameters.eventStatus, requestParameters.eventSeverities, requestParameters.categories, requestParameters.complianceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export data into CSV
     * @param {DefaultApiExportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportCsv(requestParameters: DefaultApiExportCsvRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).exportCsv(requestParameters.csvExportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Action Metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getActionMetadata(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getActionMetadata(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Meta Info for AI Service
     * @param {DefaultApiGetAiServiceMetaInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAiServiceMetaInfo(requestParameters: DefaultApiGetAiServiceMetaInfoRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAiServiceMetaInfo(requestParameters.aiServiceDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ai Service Recommendation
     * @param {DefaultApiGetAiServiceRecommendationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAiServiceRecommendation(requestParameters: DefaultApiGetAiServiceRecommendationRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAiServiceRecommendation(requestParameters.aiServiceDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all compute instance rate records
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllComputeInstanceRateRecords(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllComputeInstanceRateRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all uploaded CSV files config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllCsvUploads(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllCsvUploads(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get feature flags
     * @param {DefaultApiGetAllFeatureFlagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllFeatureFlags(requestParameters: DefaultApiGetAllFeatureFlagsRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllFeatureFlags(requestParameters.enabledOnly, requestParameters.computedOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Managed LLM Inference rate cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllManagedLLMInferenceRecords(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllManagedLLMInferenceRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all raw events
     * @param {DefaultApiGetAllRawEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllRawEvents(requestParameters: DefaultApiGetAllRawEventsRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllRawEvents(requestParameters.startTime, requestParameters.endTime, requestParameters.aiServiceId, requestParameters.userId, requestParameters.machineId, requestParameters.sourceNames, requestParameters.eventTypes, requestParameters.pageCursor, requestParameters.limit, requestParameters.aggregationBucketSecs, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all roles available to users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllRoles(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUsers(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Vector DB Rate cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllVectorDBRecords(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllVectorDBRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provide annotation aggregations for list of content ids
     * @param {DefaultApiGetAnnotationAggregationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnnotationAggregation(requestParameters: DefaultApiGetAnnotationAggregationRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAnnotationAggregation(requestParameters.aggregateType, requestParameters.sourceIds, requestParameters.destinationIds, requestParameters.linkedContentIds, requestParameters.namedEntityTypes, requestParameters.namedEntityValues, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filtered annotation list
     * @param {DefaultApiGetAnnotationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnnotationList(requestParameters: DefaultApiGetAnnotationListRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAnnotationList(requestParameters.namedEntityTypes, requestParameters.offset, requestParameters.limit, requestParameters.namedEntityValues, requestParameters.contentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch annotation details using content id
     * @param {DefaultApiGetAnnotationsOfContentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnnotationsOfContent(requestParameters: DefaultApiGetAnnotationsOfContentRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAnnotationsOfContent(requestParameters.contentId, requestParameters.contentEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the policies applied to an entity type + policy type
     * @param {DefaultApiGetApplicationStatusForEntityTypeAndPolicyTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getApplicationStatusForEntityTypeAndPolicyType(requestParameters: DefaultApiGetApplicationStatusForEntityTypeAndPolicyTypeRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getApplicationStatusForEntityTypeAndPolicyType(requestParameters.entityType, requestParameters.policyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get audit events
     * @param {DefaultApiGetAuditEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuditEvents(requestParameters: DefaultApiGetAuditEventsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuditEvents(requestParameters.category, requestParameters.entityType, requestParameters.startTime, requestParameters.endTime, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all collectors
     * @param {DefaultApiGetCollectorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCollectors(requestParameters: DefaultApiGetCollectorsRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCollectors(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get infra configurations
     * @summary Get infra configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConfigDetail(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConfigDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get infra configurations
     * @summary Get infra configurations
     * @param {DefaultApiGetConfigDetailsByDeploymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConfigDetailsByDeployment(requestParameters: DefaultApiGetConfigDetailsByDeploymentRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConfigDetailsByDeployment(requestParameters.deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get content facets
     * @param {DefaultApiGetContentFacetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContentFacets(requestParameters: DefaultApiGetContentFacetsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getContentFacets(requestParameters.facetProperty, requestParameters.contentEntityType, requestParameters.sourceIds, requestParameters.destinationIds, requestParameters.linkedContentIds, requestParameters.namedEntityTypes, requestParameters.namedEntityValues, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch content detail using id
     * @param {DefaultApiGetContentFromIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContentFromId(requestParameters: DefaultApiGetContentFromIdRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getContentFromId(requestParameters.contentId, requestParameters.contentEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of content
     * @param {DefaultApiGetContentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContentList(requestParameters: DefaultApiGetContentListRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getContentList(requestParameters.contentEntityType, requestParameters.offset, requestParameters.limit, requestParameters.sourceIds, requestParameters.destinationIds, requestParameters.linkedContentIds, requestParameters.namedEntityTypes, requestParameters.namedEntityValues, requestParameters.startTime, requestParameters.endTime, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get schema for core entity type.
     * @param {DefaultApiGetCoreEntityTypeSchemaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCoreEntityTypeSchema(requestParameters: DefaultApiGetCoreEntityTypeSchemaRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCoreEntityTypeSchema(requestParameters.entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get rate card records by filter and group
     * @param {DefaultApiGetCostRecordsByFilterAndGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCostRecordsByFilterAndGroup(requestParameters: DefaultApiGetCostRecordsByFilterAndGroupRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCostRecordsByFilterAndGroup(requestParameters.filterAndGroupRateCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a data source
     * @param {DefaultApiGetDataSourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDataSource(requestParameters: DefaultApiGetDataSourceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDataSource(requestParameters.datasourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all data sources
     * @param {DefaultApiGetDataSourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDataSources(requestParameters: DefaultApiGetDataSourcesRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDataSources(requestParameters.collectorId, requestParameters.datasourceType, requestParameters.vendor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity facets
     * @param {DefaultApiGetEntityFacetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityFacets(requestParameters: DefaultApiGetEntityFacetsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityFacets(requestParameters.entityType, requestParameters.facetProperty, requestParameters.includeCount, requestParameters.queryId, requestParameters.getFacetDataParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * supported mappings ip-> app ; ip->workload; app->ip; workload->ip
     * @summary Get entity mappings
     * @param {DefaultApiGetEntityMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityMappings(requestParameters: DefaultApiGetEntityMappingsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityMappings(requestParameters.entityType, requestParameters.mapToEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity mappings by reference object
     * @param {DefaultApiGetEntityMappingsByReferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityMappingsByReference(requestParameters: DefaultApiGetEntityMappingsByReferenceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityMappingsByReference(requestParameters.destEntityType, requestParameters.entityReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity data
     * @param {DefaultApiGetEntityPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityPage(requestParameters: DefaultApiGetEntityPageRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityPage(requestParameters.entityType, requestParameters.queryId, requestParameters.getEntityDataParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity trend metrics according to provided filters
     * @param {DefaultApiGetEntityTrendRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityTrend(requestParameters: DefaultApiGetEntityTrendRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityTrend(requestParameters.entityTrendRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get schema for UI entity type.
     * @param {DefaultApiGetEntityTypeSchemaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityTypeSchema(requestParameters: DefaultApiGetEntityTypeSchemaRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityTypeSchema(requestParameters.entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get schema for UI enum types.
     * @param {DefaultApiGetEnumTypeSchemaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEnumTypeSchema(requestParameters: DefaultApiGetEnumTypeSchemaRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEnumTypeSchema(requestParameters.enumType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Event Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEventCategories(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEventCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get events facets
     * @param {DefaultApiGetEventFacetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEventFacets(requestParameters: DefaultApiGetEventFacetsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEventFacets(requestParameters.facetProperty, requestParameters.eventType, requestParameters.entityType, requestParameters.anchorEntityTypes, requestParameters.anchorEntityIds, requestParameters.eventStatus, requestParameters.eventSeverities, requestParameters.categories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Event Severity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEventSeverities(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEventSeverities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Event to Content Navigation Metadata
     * @param {DefaultApiGetEventToContentNavigationMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEventToContentNavigationMetadata(requestParameters: DefaultApiGetEventToContentNavigationMetadataRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEventToContentNavigationMetadata(requestParameters.navigationRequests, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get general settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGeneralSettings(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGeneralSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get jira config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJiraConfig(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getJiraConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get lifecycle events
     * @param {DefaultApiGetLifecycleEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLifecycleEvents(requestParameters: DefaultApiGetLifecycleEventsRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLifecycleEvents(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a jira linked to this entity
     * @param {DefaultApiGetLinkedJiraTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLinkedJiraTicket(requestParameters: DefaultApiGetLinkedJiraTicketRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLinkedJiraTicket(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of Machine Events
     * @param {DefaultApiGetMachineEventsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMachineEventsList(requestParameters: DefaultApiGetMachineEventsListRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMachineEventsList(requestParameters.machineEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of Machine Events
     * @param {DefaultApiGetMachineEventsListTimeSeriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMachineEventsListTimeSeries(requestParameters: DefaultApiGetMachineEventsListTimeSeriesRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMachineEventsListTimeSeries(requestParameters.machineEventTimeSeriesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metric schema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMetricSchema(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMetricSchema(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metric series
     * @param {DefaultApiGetMetricSeriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMetricSeries(requestParameters: DefaultApiGetMetricSeriesRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMetricSeries(requestParameters.metricSeriesQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metric series for AI service (Automatically chooses the fq metric)
     * @param {DefaultApiGetMetricSeriesForAIServiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMetricSeriesForAIService(requestParameters: DefaultApiGetMetricSeriesForAIServiceRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMetricSeriesForAIService(requestParameters.metricSeriesQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metric tags
     * @param {DefaultApiGetMetricTagValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMetricTagValues(requestParameters: DefaultApiGetMetricTagValuesRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMetricTagValues(requestParameters.metricTagListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a note
     * @param {DefaultApiGetNoteByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNoteById(requestParameters: DefaultApiGetNoteByIdRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getNoteById(requestParameters.entityType, requestParameters.entityId, requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all items which are currently onboarded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOnboardedItems(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOnboardedItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get activity related info for items which are currently onboarded
     * @param {DefaultApiGetOnboardedItemsActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOnboardedItemsActivity(requestParameters: DefaultApiGetOnboardedItemsActivityRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOnboardedItemsActivity(requestParameters.entityRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get header info items which are currently onboarded like risk
     * @param {DefaultApiGetOnboardedItemsHeaderInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOnboardedItemsHeaderInfo(requestParameters: DefaultApiGetOnboardedItemsHeaderInfoRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOnboardedItemsHeaderInfo(requestParameters.entityRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current user\'s details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOrCreateUser(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOrCreateUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get PII data storage config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPiiConfig(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPiiConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Policy
     * @summary Get Policy
     * @param {DefaultApiGetPolicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPolices(requestParameters: DefaultApiGetPolicesRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPolices(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get possible values of attributes of the entity type used in policy
     * @param {DefaultApiGetPolicyAttributeValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPolicyAttributeValues(requestParameters: DefaultApiGetPolicyAttributeValuesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPolicyAttributeValues(requestParameters.applicableEntityType, requestParameters.attributeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all policy types and their supported entity types and properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPolicyEntityTypeProperties(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPolicyEntityTypeProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get preference for provided id
     * @param {DefaultApiGetPreferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPreference(requestParameters: DefaultApiGetPreferenceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPreference(requestParameters.entityId, requestParameters.preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all preferences for provided entity
     * @param {DefaultApiGetPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPreferences(requestParameters: DefaultApiGetPreferencesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPreferences(requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recommendation for Entity ID.
     * @param {DefaultApiGetRecommendationCostForEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRecommendationCostForEntity(requestParameters: DefaultApiGetRecommendationCostForEntityRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRecommendationCostForEntity(requestParameters.entityId, requestParameters.entityType, requestParameters.deploymentEntityType, requestParameters.startTime, requestParameters.endTime, requestParameters.entityName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all regions
     * @param {DefaultApiGetRegionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRegions(requestParameters: DefaultApiGetRegionsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRegions(requestParameters.vendorName, requestParameters.regionSubtype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Runtime Config
     * @summary Get Runtime Config
     * @param {DefaultApiGetRuntimeConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRuntimeConfig(requestParameters: DefaultApiGetRuntimeConfigRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRuntimeConfig(requestParameters.agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get savings cost for Entity Group.
     * @param {DefaultApiGetSavingsCostForEntityGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSavingsCostForEntityGroup(requestParameters: DefaultApiGetSavingsCostForEntityGroupRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSavingsCostForEntityGroup(requestParameters.entityGroupType, requestParameters.startTime, requestParameters.endTime, requestParameters.entityGroupNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sensitive data settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSensitiveSettings(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSensitiveSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get settings
     * @param {DefaultApiGetSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSettings(requestParameters: DefaultApiGetSettingsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSettings(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get settings
     * @param {DefaultApiGetSettingsConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSettingsConfig(requestParameters: DefaultApiGetSettingsConfigRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSettingsConfig(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Email SMTP data storage config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSmtpConfig(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSmtpConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get app, workload, or library linked vulnerabilities
     * @param {DefaultApiGetSoftwareVulnerabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSoftwareVulnerabilities(requestParameters: DefaultApiGetSoftwareVulnerabilitiesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSoftwareVulnerabilities(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSupportedDatasources(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSupportedDatasources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supported preference types for provided id
     * @param {DefaultApiGetSupportedPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSupportedPreferences(requestParameters: DefaultApiGetSupportedPreferencesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSupportedPreferences(requestParameters.entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTenants(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTenants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Is a topic approved, unapproved or unclear based on closest, already-existing policy topics
     * @summary approval status of topics - approved/unapproved/unclear
     * @param {DefaultApiGetTopicsApprovalStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTopicsApprovalStatus(requestParameters: DefaultApiGetTopicsApprovalStatusRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTopicsApprovalStatus(requestParameters.topics, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unverified AI Service
     * @param {DefaultApiGetUnverifiedAiServiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUnverifiedAiService(requestParameters: DefaultApiGetUnverifiedAiServiceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUnverifiedAiService(requestParameters.getEntityDataParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get usage cost for Entity ID.
     * @param {DefaultApiGetUsageCostForEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsageCostForEntity(requestParameters: DefaultApiGetUsageCostForEntityRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUsageCostForEntity(requestParameters.entityId, requestParameters.entityType, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get usage cost for Entity Group.
     * @param {DefaultApiGetUsageCostForEntityGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsageCostForEntityGroup(requestParameters: DefaultApiGetUsageCostForEntityGroupRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUsageCostForEntityGroup(requestParameters.groupUsageCostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Requests for an AI entity
     * @param {DefaultApiGetUserRequestsForEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserRequestsForEntity(requestParameters: DefaultApiGetUserRequestsForEntityRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserRequestsForEntity(requestParameters.entityRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new graph
     * @param {DefaultApiGraphEntityTypeEntityIdGraphTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public graphEntityTypeEntityIdGraphTypeGet(requestParameters: DefaultApiGraphEntityTypeEntityIdGraphTypeGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).graphEntityTypeEntityIdGraphTypeGet(requestParameters.entityType, requestParameters.entityId, requestParameters.graphType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ingest data
     * @summary Ingest data
     * @param {DefaultApiIngestDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ingestData(requestParameters: DefaultApiIngestDataRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ingestData(requestParameters.authorization, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List different builds for Browser Extension
     * @summary List different builds for Browser Extension
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBrowserAgentBuilds(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listBrowserAgentBuilds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List different builds for Browser Extension
     * @summary List different builds for Browser Extension
     * @param {DefaultApiListBrowserAgentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBrowserAgents(requestParameters: DefaultApiListBrowserAgentsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listBrowserAgents(requestParameters.start, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List schemas for concrete policy types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listConcretePolicySchemas(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listConcretePolicySchemas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List schemas for core entity types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCoreEntityTypeSchemas(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCoreEntityTypeSchemas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List schemas for UI entity types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEntityTypeSchemas(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listEntityTypeSchemas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List schema for UI enum types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEnumTypeSchemas(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listEnumTypeSchemas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List notes
     * @param {DefaultApiListNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNotes(requestParameters: DefaultApiListNotesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNotes(requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get risk categories for risk
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRiskCategory(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listRiskCategory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all tags
     * @param {DefaultApiListTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTags(requestParameters: DefaultApiListTagsRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listTags(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all localized messages for the current language. The language can be specified using the `Accept-Language` header.
     * @summary Get all localized messages
     * @param {DefaultApiMessagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public messagesGet(requestParameters: DefaultApiMessagesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).messagesGet(requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a localized message by providing the message key. The language can be specified using the `Accept-Language` header.
     * @summary Get a localized message by key
     * @param {DefaultApiMessagesKeyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public messagesKeyGet(requestParameters: DefaultApiMessagesKeyGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).messagesKeyGet(requestParameters.key, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Offboard a set of supported entities
     * @param {DefaultApiOffboardEntitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public offboardEntities(requestParameters: DefaultApiOffboardEntitiesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).offboardEntities(requestParameters.entityRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Onboard a set of supported entities
     * @param {DefaultApiOnboardEntitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public onboardEntities(requestParameters: DefaultApiOnboardEntitiesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).onboardEntities(requestParameters.entityRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the policies applied to an entity and its surrounding context
     * @param {DefaultApiPoliciesApplicationStatusPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesApplicationStatusPost(requestParameters: DefaultApiPoliciesApplicationStatusPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesApplicationStatusPost(requestParameters.entityRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List State Transitions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesApprovalStatusStateTransitionsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesApprovalStatusStateTransitionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve a set of supported entities
     * @param {DefaultApiPoliciesApprovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesApprovePost(requestParameters: DefaultApiPoliciesApprovePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesApprovePost(requestParameters.entityRefs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List policies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete policy for ID.
     * @param {DefaultApiPoliciesPolicyIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesPolicyIdDelete(requestParameters: DefaultApiPoliciesPolicyIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesPolicyIdDelete(requestParameters.policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get policy for ID.
     * @param {DefaultApiPoliciesPolicyIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesPolicyIdGet(requestParameters: DefaultApiPoliciesPolicyIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesPolicyIdGet(requestParameters.policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a policy
     * @param {DefaultApiPoliciesPolicyIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesPolicyIdPut(requestParameters: DefaultApiPoliciesPolicyIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesPolicyIdPut(requestParameters.policyId, requestParameters.policyData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Policy
     * @param {DefaultApiPoliciesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesPost(requestParameters: DefaultApiPoliciesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesPost(requestParameters.policyData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the approval status of a set of supported entities by property values
     * @param {DefaultApiPoliciesPropertyApprovalStatusChangePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesPropertyApprovalStatusChangePost(requestParameters: DefaultApiPoliciesPropertyApprovalStatusChangePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesPropertyApprovalStatusChangePost(requestParameters.approvalStatusChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve a set of supported entities by property values
     * @param {DefaultApiPoliciesPropertyApprovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesPropertyApprovePost(requestParameters: DefaultApiPoliciesPropertyApprovePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesPropertyApprovePost(requestParameters.propertyValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Un-approve a set of supported entities by property values
     * @param {DefaultApiPoliciesPropertyUnapprovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesPropertyUnapprovePost(requestParameters: DefaultApiPoliciesPropertyUnapprovePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesPropertyUnapprovePost(requestParameters.propertyValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Un-approve a set of supported entities
     * @param {DefaultApiPoliciesUnapprovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policiesUnapprovePost(requestParameters: DefaultApiPoliciesUnapprovePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policiesUnapprovePost(requestParameters.entityRefs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List policy contexts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policyContextsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policyContextsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get policy context for ID.
     * @param {DefaultApiPolicyContextsPolicyCtxIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policyContextsPolicyCtxIdGet(requestParameters: DefaultApiPolicyContextsPolicyCtxIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policyContextsPolicyCtxIdGet(requestParameters.policyCtxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get policy suggestions translated from a policy context using ID.
     * @param {DefaultApiPolicyContextsPolicyCtxIdPolicySuggestionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policyContextsPolicyCtxIdPolicySuggestionsGet(requestParameters: DefaultApiPolicyContextsPolicyCtxIdPolicySuggestionsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policyContextsPolicyCtxIdPolicySuggestionsGet(requestParameters.policyCtxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a policy context
     * @param {DefaultApiPolicyContextsPolicyCtxIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policyContextsPolicyCtxIdPut(requestParameters: DefaultApiPolicyContextsPolicyCtxIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policyContextsPolicyCtxIdPut(requestParameters.policyCtxId, requestParameters.policyContextData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Policy Context
     * @param {DefaultApiPolicyContextsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policyContextsPost(requestParameters: DefaultApiPolicyContextsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policyContextsPost(requestParameters.policyContextData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get preview of provided summary
     * @param {DefaultApiPolicyPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policyPreview(requestParameters: DefaultApiPolicyPreviewRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policyPreview(requestParameters.conditionMetFlag, requestParameters.policyData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get summary of defined policies
     * @param {DefaultApiPolicySummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policySummary(requestParameters: DefaultApiPolicySummaryRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).policySummary(requestParameters.policySummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register User using browser agent
     * @summary Register User using browser agent
     * @param {DefaultApiRegisterUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registerUser(requestParameters: DefaultApiRegisterUserRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).registerUser(requestParameters.registerUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds/updates the provided secrets to the secrets manager
     * @summary Set Update Secrets
     * @param {DefaultApiSetUpdateSecretsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setUpdateSecrets(requestParameters: DefaultApiSetUpdateSecretsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setUpdateSecrets(requestParameters.customerSecretInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change infra configurations
     * @summary Change infra configurations
     * @param {DefaultApiUpdateConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateConfig(requestParameters: DefaultApiUpdateConfigRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateConfig(requestParameters.updateConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change infra configurations
     * @summary Change infra configurations
     * @param {DefaultApiUpdateConfigByDeploymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateConfigByDeployment(requestParameters: DefaultApiUpdateConfigByDeploymentRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateConfigByDeployment(requestParameters.deploymentId, requestParameters.updateConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a data source
     * @param {DefaultApiUpdateDataSourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDataSource(requestParameters: DefaultApiUpdateDataSourceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateDataSource(requestParameters.datasourceId, requestParameters.datasourceConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update general settings
     * @param {DefaultApiUpdateGeneralSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateGeneralSettings(requestParameters: DefaultApiUpdateGeneralSettingsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateGeneralSettings(requestParameters.tenantGeneralSettingsUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update note
     * @param {DefaultApiUpdateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateNote(requestParameters: DefaultApiUpdateNoteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateNote(requestParameters.entityType, requestParameters.entityId, requestParameters.noteId, requestParameters.updateNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update preference for provided id
     * @param {DefaultApiUpdatePreferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePreference(requestParameters: DefaultApiUpdatePreferenceRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updatePreference(requestParameters.entityId, requestParameters.preferenceId, requestParameters.preference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a sensitive data config
     * @param {DefaultApiUpdateSensitiveDataConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSensitiveDataConfig(requestParameters: DefaultApiUpdateSensitiveDataConfigRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSensitiveDataConfig(requestParameters.configId, requestParameters.sensitiveDataConfigUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Request for approval for a use case involving a AI entity
     * @param {DefaultApiUpdateUserRequestForEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserRequestForEntity(requestParameters: DefaultApiUpdateUserRequestForEntityRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserRequestForEntity(requestParameters.entityUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the user\'s role
     * @param {DefaultApiUpdateUserRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserRole(requestParameters: DefaultApiUpdateUserRoleRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserRole(requestParameters.userId, requestParameters.userUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a CSV file for data sources
     * @param {DefaultApiUploadCsvDataSourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadCsvDataSource(requestParameters: DefaultApiUploadCsvDataSourceRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadCsvDataSource(requestParameters.file, requestParameters.csvType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns True if the customer key is present in the secrets manager, else False
     * @summary Check if customer key is present
     * @param {DefaultApiUsingCustomerKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usingCustomerKey(requestParameters: DefaultApiUsingCustomerKeyRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usingCustomerKey(requestParameters.secretName, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAllRawEventsSourceNamesEnum = {
    Code42: 'CODE42',
    SingulrBrowserExtension: 'SINGULR_BROWSER_EXTENSION',
    Xdr: 'XDR'
} as const;
export type GetAllRawEventsSourceNamesEnum = typeof GetAllRawEventsSourceNamesEnum[keyof typeof GetAllRawEventsSourceNamesEnum];
/**
 * @export
 */
export const GetAllRawEventsEventTypesEnum = {
    AiServiceAccessEvent: 'AI_SERVICE_ACCESS_EVENT',
    FileUploadEvent: 'FILE_UPLOAD_EVENT',
    CopyPasteEvent: 'COPY_PASTE_EVENT',
    PolicyEnforcementEvent: 'POLICY_ENFORCEMENT_EVENT'
} as const;
export type GetAllRawEventsEventTypesEnum = typeof GetAllRawEventsEventTypesEnum[keyof typeof GetAllRawEventsEventTypesEnum];
/**
 * @export
 */
export const GetAllRawEventsSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetAllRawEventsSortEnum = typeof GetAllRawEventsSortEnum[keyof typeof GetAllRawEventsSortEnum];
/**
 * @export
 */
export const GetAuditEventsCategoryEnum = {
    Env: 'env',
    User: 'user',
    TrustFeed: 'trust_feed'
} as const;
export type GetAuditEventsCategoryEnum = typeof GetAuditEventsCategoryEnum[keyof typeof GetAuditEventsCategoryEnum];
/**
 * @export
 */
export const GetAuditEventsOperationEnum = {
    Create: 'CREATE',
    Update: 'UPDATE',
    Delete: 'DELETE'
} as const;
export type GetAuditEventsOperationEnum = typeof GetAuditEventsOperationEnum[keyof typeof GetAuditEventsOperationEnum];
/**
 * @export
 */
export const GetContentListSortOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetContentListSortOrderEnum = typeof GetContentListSortOrderEnum[keyof typeof GetContentListSortOrderEnum];
/**
 * @export
 */
export const UploadCsvDataSourceCsvTypeEnum = {
    UsersCsv: 'USERS_CSV',
    ApplicationsCsv: 'APPLICATIONS_CSV'
} as const;
export type UploadCsvDataSourceCsvTypeEnum = typeof UploadCsvDataSourceCsvTypeEnum[keyof typeof UploadCsvDataSourceCsvTypeEnum];

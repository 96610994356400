import { configureStore } from '@reduxjs/toolkit';
import policySlice from 'src/pages/Policies/newPolicy/policySlice';
import trustFeedSlice from 'src/pages/TrustFeed/trustFeedSlice';
import onboardingSlice from 'src/pages/onboarding/onboardingSlice';
import { emptySplitApi } from './emptyApi';

const reduxStore = configureStore({
  reducer: {
    trustFeed: trustFeedSlice,
    onboarding: onboardingSlice,
    policy: policySlice,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([emptySplitApi.middleware]),
});

export default reduxStore;
